import { LOCALSTORAGE_KEY } from "./constants"

export const setItem = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value))
}

export const getItem = (key) => {
	let item = localStorage.getItem(key)
	if (item != null && item[key] != null) {
		item = JSON.parse(item[key])
	}
	return item
}

export const removeItem = (key) => {
	localStorage.removeItem(key)
}

export const saveLoginData = (loginData, hash) => {
	setItem(LOCALSTORAGE_KEY, {
		...loginData,
		hash
	})
}

export const getData = (key) => {
	let data = getItem(LOCALSTORAGE_KEY)

	if (data != null) {
		data = JSON.parse(data)
		data = data[key]
	}
	return data
}
