import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { LoadingSpinner } from 'components/LoadingSpinner'
import ListaTareas from './ListaTareas'


export default function TareasHistorialTabContent(props) {
	const { active, loading, filtros, handleVer } = props

	return (
		<div className="tab-content-wrapper">
			{loading ? (
				<Row className="mt-2">
					<Col>
						<LoadingSpinner />
					</Col>
				</Row>
			) : (
				<ListaTareas
					active={active}
					filtros={filtros}
					handleVer={handleVer}
					conDropdown={false}
				/>
			)}
		</div >
	)
}