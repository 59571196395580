import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Datetime from 'react-datetime'
import { handleChangeFecha } from 'utils/date'

function NuevoRegistro(props) {
	const { guardar, setQueMostrar, establecimientos } = props

	const datosIniciales = {
		establecimientoId: establecimientos[0].id,
		establecimientoParteId: establecimientos[0].partes.length ? establecimientos[0].partes[0].id : '',
		tipo: 'lluvia',
		fecha: new Date().toISOString().split('T')[0],
		ml: 0
	}

	const [datos, setDatos] = useState(datosIniciales)
	const [erroresValidacion, setErroresValidacion] = useState({})

	const handleGuardar = (e) => {
		e.preventDefault()

		const data = {
			...datos,
		}

		if (data.establecimientoParteId === '') {
			data.establecimientoParteId = null
		}

		if (validarDatos()) {
			guardar(data)
			setQueMostrar('')
		}
	}

	const validarDatos = () => {
		const nuevosErrores = {}

		if (datos['fecha'].trim() === '') {
			nuevosErrores['fecha'] = 'Este es un campo requerido'
		} else {
			nuevosErrores['fecha'] = null
		}

		if (datos['tipo'] === 'lluvia' && (isNaN(datos['ml']) || datos['ml'] === '' || datos['ml'] < 1)) {
			nuevosErrores['ml'] = 'Ingresar el número de milímetros llovidos'
		} else {
			nuevosErrores['ml'] = null
		}

		const errores = { ...erroresValidacion, ...nuevosErrores }

		setErroresValidacion(errores)

		return Object.keys(errores).filter(elem => errores[elem] !== null).length === 0
	}

	const handleChangeEstablecimientoId = (establecimientoId) => {
		const nuevosDatos = { ...datos, establecimientoId: parseInt(establecimientoId) }
		const partes = establecimientos.filter((e) => e.id === parseInt(establecimientoId))[0].partes
		if (partes.length > 0) {
			nuevosDatos.establecimientoParteId = partes[0].id
		} else {
			nuevosDatos.establecimientoParteId = ''
		}

		setDatos(nuevosDatos)
	}

	const handleChange = (valor, cual) => {
		const nuevosDatos = { ...datos, [cual]: valor }

		setDatos(nuevosDatos)
	}

	return (
		<Modal show={true} onHide={() => setQueMostrar('')}>
			<Modal.Header closeButton>
				<Modal.Title>Nuevo registro</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="registro-establecimientoId">
								<Form.Label>Establecimiento</Form.Label>
								<Form.Select
									value={datos.establecimientoId}
									onChange={(e) => handleChangeEstablecimientoId(e.target.value)}
								>
									{establecimientos.map((e) => (
										<option key={`establecimiento-${e.id}`} value={e.id}>
											{e.nombre}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="registro-sector">
								<Form.Label>Sector</Form.Label>
								<Form.Select
									value={datos.establecimientoParteId}
									onChange={(e) => handleChange(e.target.value, 'establecimientoParteId')}
								>
									{establecimientos.filter((e) => e.id === datos.establecimientoId)[0].partes.map(
										(p) => (
											<option key={`parteEstablecimiento-${p.id}`} value={p.id}>
												{p.nombre}
											</option>
										)
									)}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="registro-tipo">
								<Form.Label>Tipo</Form.Label>
								<Form.Select
									value={datos.tipo}
									onChange={(e) => handleChange(e.target.value, 'tipo')}
								>
									<option value="lluvia">Lluvia</option>
									<option value="helada">Helada</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					{datos.tipo === 'lluvia' ? (
						<Row>
							<Col>
								<Form.Group controlId="registro-milimetros">
									<Form.Label>Milímetros</Form.Label>
									<Form.Control
										type="number"
										value={datos.ml}
										onChange={(e) => handleChange(e.target.value, 'ml')}
										isInvalid={erroresValidacion.ml}
									/>
									<Form.Control.Feedback type="invalid">{erroresValidacion.ml}</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					) : null}
					<Row>
						<Col>
							<Form.Group controlId="registro-fecha">
								<Form.Label className="mt-3">Fecha</Form.Label>
								<Datetime
									inputProps={{ id: "registro-fecha" }}
									dateFormat="YYYY-MM-DD"
									timeFormat={false}
									value={datos.fecha}
									closeOnSelect={true}
									onChange={(e) => handleChangeFecha(e, handleChange, 'fecha')}
								/>
								<Form.Control.Feedback type="invalid" style={{ display: erroresValidacion.fecha ? "block" : "none" }}>{erroresValidacion.fecha}</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => setQueMostrar('')}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleGuardar}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default NuevoRegistro