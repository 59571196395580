import Table from 'react-bootstrap/Table'
import { CATEGORIAS_CON_SEXO_FIJO } from 'config/stock'



export default function DetallesAnimal(props) {
	const { animal } = props

	return (

		<Table striped bordered hover size="sm">
			<thead></thead>
			<tbody>
				<tr>
					<th># Caravana</th><td>{animal.numCaravana}</td>
				</tr>
				<tr>
					<th># Chip</th><td>{animal.numChip}</td>
				</tr>
				<tr>
					<th># Tatuaje</th><td>{animal.numTatuaje}</td>
				</tr>
				<tr>
					<th>Categoría</th><td>{animal.categoria.nombre}</td>
				</tr>
				<tr>
					<th>Cbo</th><td>{animal.carimbo}</td>
				</tr>
			</tbody>
		</Table>
	)
}