import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DetallesAnimal from './DetallesAnimal'
import Datetime from "react-datetime"
import { handleChangeFecha } from 'utils/date'
import { ButtonWithTooltip } from 'components/ButtonWithTooltip'

export default function DefinirPariciones(props) {
	const { animales, pariciones, changeParicionData, changeParicionHijoData, verSiCompletadoDatosParicion, verSiCompletadoDatosParicionHijo, onAtras } = props

	const [numAct, setNumAct] = useState(0)

	const siguienteDisabledText = verSiCompletadoDatosParicion(pariciones[numAct]) ? '' : 'Se debe completar para pasar al siguiente animal'

	const handleAtras = () => {
		onAtras()
	}

	return (
		<>
			<Row>
				<Col className="text-start">
					<Button
						variant="primary"
						className="ms-2"
						onClick={handleAtras}
					>
						<i className="fa fa-caret-left"></i> Atrás
					</Button>
				</Col>
			</Row>
			<Row>
				<Col sm="6" lg="4">
					<Row>
						<Col>
							<Card.Title className="mt-3">Animal seleccionado <strong>{numAct + 1}</strong>/{pariciones.length}</Card.Title>
							<DetallesAnimal animal={animales[numAct]} />
							<Form.Group controlId="txtTareaFechaParaCuando" className="mb-2 input-320">
								<Form.Label className="mb-1">Fecha de parición</Form.Label>
								<Datetime
									key={`datetime-${numAct}`}
									dateFormat="YYYY-MM-DD"
									timeFormat={false}
									closeOnSelect={true}
									strictParsing={false}
									inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtFecha', className: 'form-control form-control-sm' }}
									value={pariciones[numAct].fechaParaCuando}
									onChange={(date) => handleChangeFecha(date, changeParicionData, 'fechaParaCuando', numAct)}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="num-hijos">
								<Form.Label className="mb-1">Núm hijos</Form.Label>
								<Form.Control
									type="number"
									size="sm"
									min="0"
									className="input-60"
									value={pariciones[numAct].numHijos}
									onChange={(e) => changeParicionData(e.target.value, 'numHijos', numAct)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col className="d-flex justify-content-around">
							<Button
								variant="success"
								onClick={() => setNumAct(prev => prev - 1)}
								disabled={numAct === 0}
							>
								<i className="fa fa-caret-left"></i> ANTERIOR
							</Button>
							<ButtonWithTooltip
								tooltipText={siguienteDisabledText}
								variant="success"
								onClick={() => setNumAct(prev => prev + 1)}
								disabled={numAct === pariciones.length - 1 || siguienteDisabledText !== ''}
							>
								SIGUIENTE <i className="fa fa-caret-right"></i>
							</ButtonWithTooltip>
						</Col>
					</Row>
				</Col>
				<Col sm="6" lg="8">
					<div className="border p-3">
						{pariciones[numAct].numHijos > 1 && (
							<>
								<Row className="mb-3">
									<Col className="text-center">
										Hijo <strong>{pariciones[numAct].indexHijos + 1}</strong>/{pariciones[numAct].numHijos}
									</Col>
								</Row>
								<Row className="mb-3">
									<Col className="d-flex justify-content-around">
										<Button
											variant="success"
											onClick={() => changeParicionData(pariciones[numAct].indexHijos - 1, 'indexHijos', numAct)}
											disabled={pariciones[numAct].indexHijos === 0}
										>
											<i className="fa fa-caret-left"></i> ANTERIOR
										</Button>
										<Button
											variant="success"
											onClick={() => changeParicionData(pariciones[numAct].indexHijos + 1, 'indexHijos', numAct)}
											disabled={pariciones[numAct].indexHijos === pariciones[numAct].numHijos - 1 || !verSiCompletadoDatosParicionHijo(pariciones[numAct]['hijos'][pariciones[numAct].indexHijos])}
										>
											SIGUIENTE <i className="fa fa-caret-right"></i>
										</Button>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col>
								<div>
									<Form.Label className="mb-1">¿Nacido vivo?</Form.Label>
									<div>
										<Form.Check
											id="check-vivo-si"
											type='radio'
											inline
											label='Sí'
											checked={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].vivo === true}
											onChange={e => changeParicionHijoData(true, 'vivo', numAct)}
											className="me-4"
										/>
										<Form.Check
											id="check-vivo-no"
											type='radio'
											inline
											label="No"
											checked={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].vivo === false}
											onChange={e => changeParicionHijoData(false, 'vivo', numAct)}
										/>
									</div>
								</div>
								{pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].vivo && (
									<>
										<div className="">
											<Form.Label className="mb-1">Sexo</Form.Label>
											<div>
												<Form.Check
													id="check-sexo-macho"
													type='radio'
													inline
													label='Macho'
													checked={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].sexo === 'macho'}
													onChange={e => changeParicionHijoData('macho', 'sexo', numAct)}
													className="me-4"
												/>
												<Form.Check
													id="check-sexo-hembra"
													type='radio'
													inline
													label="Hembra"
													checked={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].sexo === 'hembra'}
													onChange={e => changeParicionHijoData('hembra', 'sexo', numAct)}
												/>
											</div>
										</div>
										<Form.Group className="mb-3" controlId="raza">
											<Form.Label className="mb-1">Raza</Form.Label>
											<Form.Control
												type="text"
												size="sm"
												value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].raza}
												onChange={(e) => changeParicionHijoData(e.target.value, 'raza', numAct)}
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="color">
											<Form.Label className="mb-1">Color</Form.Label>
											<Form.Control
												type="text"
												size="sm"
												value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].color}
												onChange={(e) => changeParicionHijoData(e.target.value, 'color', numAct)}
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="peso">
											<Form.Label className="mb-1">Peso</Form.Label>
											<Form.Control
												type="number"
												size="sm"
												className="input-100"
												value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].peso}
												onChange={(e) => changeParicionHijoData(e.target.value, 'peso', numAct)}
											/>
										</Form.Group>
										<fieldset className="border p-3 mt-4">
											<legend className="w-auto px-2" style={{ marginTop: -35, backgroundColor: 'white' }}>Identificación</legend>
											<Form.Group className="mb-3" controlId="numCaravana">
												<Form.Label className="mb-1">Núm. Caravana</Form.Label>
												<Form.Control
													type="text"
													size="sm"
													value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].numCaravana}
													onChange={(e) => changeParicionHijoData(e.target.value, 'numCaravana', numAct)}
													disabled={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].identificarMasAdelante}
												/>
											</Form.Group>
											<Form.Group className="mb-3" controlId="numChip">
												<Form.Label className="mb-1">Núm. Chip</Form.Label>
												<Form.Control
													type="text"
													size="sm"
													value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].numChip}
													onChange={(e) => changeParicionHijoData(e.target.value, 'numChip', numAct)}
													disabled={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].identificarMasAdelante}
												/>
											</Form.Group>
											<Form.Group className="mb-3" controlId="numTatuaje">
												<Form.Label className="mb-1">Núm. Tatuaje</Form.Label>
												<Form.Control
													type="text"
													size="sm"
													value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].numTatuaje}
													onChange={(e) => changeParicionHijoData(e.target.value, 'numTatuaje', numAct)}
													disabled={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].identificarMasAdelante}
												/>
											</Form.Group>
											<Form.Group className="mb-3" controlId="identificarMasAdelante">
												<Form.Check
													id="check-identificarMasAdelante"
													inline
													label="Identificar más adelante"
													checked={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].identificarMasAdelante}
													onChange={e => changeParicionHijoData(e.target.checked, 'identificarMasAdelante', numAct)}
												/>
											</Form.Group>
											{pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].identificarMasAdelante && (
												<Form.Group controlId="txtFechaIdentificacion">
													<Form.Label className="mb-1">Fecha identificación</Form.Label>
													<Datetime
														key={`datetime-fechaIdentificacion-${numAct}`}
														dateFormat="YYYY-MM-DD"
														timeFormat={false}
														closeOnSelect={true}
														strictParsing={false}
														inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtFechaIdentificacion', className: 'form-control form-control-sm' }}
														value={pariciones[numAct]['hijos'][pariciones[numAct].indexHijos].fechaIdentificacion}
														onChange={(date) => handleChangeFecha(date, changeParicionHijoData, 'fechaIdentificacion', numAct)}
													/>
												</Form.Group>
											)}
										</fieldset>
									</>
								)}
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</>
	)
}