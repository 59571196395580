import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function EliminarRegistro(props) {
	const { registro, setQueMostrar, eliminar } = props

	const handleClose = () => {
		setQueMostrar('')
	}

	const handleEliminar = (id) => {
		eliminar(id)
		setQueMostrar('')
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar registro</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de eliminar el registro de tipo <strong>{registro.tipo}</strong> de fecha <strong>{`${registro.fecha.slice(0, 10)}`}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={() => handleEliminar(registro.id)}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EliminarRegistro