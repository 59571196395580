export function calculateAge(birthdate) {
	const birth = new Date(birthdate)

	const today = new Date()
	let years = today.getFullYear() - birth.getFullYear()

	if (today.getMonth() < birth.getMonth() || (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate())) {
		years--;
	}

	let months = today.getMonth() - birth.getMonth();

	if (today.getDate() < birth.getDate()) {
		months--;
	}

	if (months < 0) {
		months += 12;
	}

	return `${years} años, ${months} meses`;
}

export function calculateAgeFromCarimbo(carimbo) {
	const currentYear = new Date().getFullYear();
	const startOfDecade = Math.floor(currentYear / 10) * 10;

	let year = startOfDecade + carimbo;

	// If the year is greater than the current year, subtract 10 to get the previous decade
	if (year > currentYear) {
		year -= 10;
	}

	return `${currentYear - year} años`;
}