import Table from 'react-bootstrap/Table'


export default function DetallesAnimalTable(props) {
	const defaultCols = [
		{
			label: '# Caravana',
			key: 'numCaravana'
		},
		{
			label: '# Chip',
			key: 'numChip'
		},
		{
			label: '# Tatuaje',
			key: 'numTatuaje'
		},
		{
			label: 'Categoría',
			key: 'categoria.nombre'
		},
		
		{
			label: 'Cbo',
			key: 'carimbo'
		},
		{
			label: 'Peso',
			key: 'peso'
		},
	]
	const { animal, cols = defaultCols } = props

	return (

		<Table striped bordered hover size="sm">
			<thead></thead>
			<tbody>
				{cols.map((col, i) => {
					const [objProp, nestedProp] = col.key.split('.')
					const valor = nestedProp ? animal[objProp][nestedProp] : animal[objProp]

					return (
						<tr key={i}>
							<th>{col.label}</th>
							<td>{valor || '-'}</td>
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}