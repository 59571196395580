import React, { useState } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { getData } from 'utils/localstorage'

export default function DownloadButton(props) {
	const { url, whileDownloading = <>Descargando <i className="fa fa-spinner fa-spin"></i></>, children, ...otherProps } = props
	const [disabled, setDisabled] = useState(false)

	const downloadFile = async () => {
		setDisabled(true)

		try {
			const hash = getData('hash')
			const cuentaId = getData('cuentaId')

			const separator = url.includes('?') ? '&' : '?'

			const response = await axios({
				url: url + separator + 'cuentaId=' + cuentaId,
				method: 'GET',
				responseType: 'blob', // Importante para archivos grandes
				headers: {
					"Content-Type": "text/html; charset=UTF-8",
					"Authorization": `Basic ${hash}`
				}
			})

			const encodedFilename =  response.headers['content-disposition'].match(/filename\*=UTF-8''(.+)$/)[1]
			const decodedFilename = decodeURIComponent(encodedFilename.replace(/\+/g, ' '));

			const href = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = href
			link.setAttribute('download', decodedFilename)
			document.body.appendChild(link)
			link.click()
			link.parentNode.removeChild(link)
		} catch (error) {
			console.error('Hubo un error al descargar el archivo:', error)
		}

		setDisabled(false)
	}

	return (
		<Button onClick={downloadFile} disabled={disabled} {...otherProps}>
			{disabled ? whileDownloading : children}
		</Button>
	)
}

