import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

// Use a functional component instead of a class component
export default function EstadosExtra(props) {
	const { reset, categoriaId, onSave } = props

	const valoresDefault = {
		prenada: false,
		enServicio: false,
		enPosparto: false,
		alPie: false,
		destetePrecoz: false,
	}

	const [seleccionado, setSeleccionado] = useState(valoresDefault)

	useEffect(() => {
		if (reset) {
			setSeleccionado(valoresDefault)
		}
	}, [reset])

	useEffect(() => {
		onSave(seleccionado)
	}, [seleccionado])


	const getElements = () => {
		let jsx = null

		switch (parseInt(categoriaId)) {
			case 1:
				jsx = (
					<>
						<Row>
							<Col>
								<Form.Label className="mb-0">Preñada</Form.Label>
								<Form.Check
									className="ms-2"
									inline
									label="Sí"
									name="estado-prenada"
									type="radio"
									id="check-vacas-prenadas-si"
									value="prenadas-si"
									checked={seleccionado?.prenada === true}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											prenada: true,
										})
									}
								/>
								<Form.Check
									inline
									label="No"
									name="estado-prenada"
									type="radio"
									id="check-vacas-prenadas-no"
									value="prenadas-no"
									checked={seleccionado?.prenada === false}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											prenada: false,
										})
									}
								/>
							</Col>
						</Row>

						{/*parseInt(subcategoriaId) === 2 && seleccionado?.prenada === true && (
							<Row>
								<Col>
									<Form.Check
										inline
										label="Con cría al pie"
										name="estado-alPie"
										type="radio"
										id="check-vacas-adultas-alPie-si"
										value="alPie-si"
										checked={seleccionado?.prenada === true && seleccionado?.alPie === true}
										onChange={() =>
											setSeleccionado({
												...valoresDefault,
												prenada: true,
												alPie: true,
											})
										}
									/>
									<Form.Check
										inline
										label="Destetada"
										name="estado-alPie"
										type="radio"
										id="check-vacas-adultas-alPie-no"
										value="alPie-no"
										checked={seleccionado?.prenada === true && !seleccionado?.alPie === true}
										onChange={() =>
											setSeleccionado({
												...valoresDefault,
												prenada: true,
												alPie: false,
											})
										}
									/>
								</Col>
							</Row>
						)*/}
						<Row>
							<Col>
								<Form.Label className="mb-0">En servicio</Form.Label>
								<Form.Check
									className="ms-2"
									inline
									label="Sí"
									name="estado-enServicio"
									type="radio"
									id="check-vacas-enServicio-si"
									value="enServicio-si"
									checked={seleccionado?.enServicio === true}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											enServicio: true,
										})
									}
								/>
								<Form.Check
									inline
									label="No"
									name="estado-enServicio"
									type="radio"
									id="check-vacas-enServicio-no"
									value="enServicio-no"
									checked={seleccionado?.enServicio === false}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											enServicio: false,
										})
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Label className="mb-0">En posparto</Form.Label>
								<Form.Check
									className="ms-2"
									inline
									label="Sí"
									name="estado-enPosparto"
									type="radio"
									id="check-vacas-enPosparto-si"
									value="enPosparto-si"
									checked={seleccionado?.enPosparto === true}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											enPosparto: true,
										})
									}
								/>
								<Form.Check
									inline
									label="No"
									name="estado-enPosparto"
									type="radio"
									id="check-vacas-enPosparto-no"
									value="enPosparto-no"
									checked={seleccionado?.enPosparto === false}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											enPosparto: false,
										})
									}
								/>
							</Col>
						</Row>
					</>
				)
				break
			case 2:
				jsx = (
					<Row>
						<Col>
							<Form.Label className="mb-0">Preñada</Form.Label>
							<Form.Check
								className="ms-2"
								inline
								label="Sí"
								name="estado-prenada"
								type="radio"
								id="check-vaquillas-prenadas-si"
								value="prenadas-si"
								checked={seleccionado?.prenada === true}
								onChange={() =>
									setSeleccionado({
										...valoresDefault,
										prenada: true,
									})
								}
							/>
							<Form.Check
								inline
								label="No"
								name="estado-prenada"
								type="radio"
								id="check-vaquillas-prenadas-no"
								value="prenadas-no"
								checked={seleccionado?.prenada === false}
								onChange={() =>
									setSeleccionado({
										...valoresDefault,
										prenada: false,
									})
								}
							/>
						</Col>
					</Row>
				)
				break
			case 4:
				jsx = (
					<>
						<Row>
							<Col>
								<Form.Label className="mb-0">En servicio</Form.Label>
								<Form.Check
									className="ms-2"
									inline
									label="Sí"
									name="estado-enServicio"
									type="radio"
									id="check-toros-enServicio-si"
									value="enServicio-si"
									checked={seleccionado?.enServicio === true}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											enServicio: true,
										})
									}
								/>
								<Form.Check
									inline
									label="No"
									name="estado-enServicio"
									type="radio"
									id="check-toros-enServicio-no"
									value="enServicio-no"
									checked={seleccionado?.enServicio === false}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											enServicio: false,
										})
									}
								/>
							</Col>
						</Row>
					</>
				)
				break
			case 7:
				jsx = (
					<>
						<Row>
							<Col>
								<Form.Check
									inline
									label="Al pie"
									name="estado-terneros"
									type="radio"
									id="check-terneros-alpie-si"
									value="terneros-alpie-si"
									checked={seleccionado?.alPie === true}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											alPie: true,
										})
									}
								/>
								<Form.Check
									inline
									label="Destetado"
									name="estado-terneros"
									type="radio"
									id="check-terneros-alpie-no"
									value="terneros-alpie-si"
									checked={seleccionado?.alPie === false}
									onChange={() =>
										setSeleccionado({
											...valoresDefault,
											alPie: false,
										})
									}
								/>
							</Col>
						</Row>
						{seleccionado?.alPie === false && (
							<Row>
								<Col>
									<Form.Check
										inline
										label="Normal"
										name="estado-terneros-destetados"
										type="radio"
										id="check-terneros-destetados-normal"
										value="terneros-destetados-normal"
										checked={
											seleccionado?.alPie === false &&
											seleccionado?.destetePrecoz === false
										}
										onChange={() =>
											setSeleccionado({
												...valoresDefault,
												alPie: false,
												destetePrecoz: false,
											})
										}
									/>
									<Form.Check
										inline
										label="Precoz"
										name="estado-terneros-destetados"
										type="radio"
										id="check-terneros-destetados-precoz"
										value="terneros-destetados-precoz"
										checked={
											seleccionado?.alPie === false &&
											seleccionado?.destetePrecoz === true
										}
										onChange={() =>
											setSeleccionado({
												...valoresDefault,
												alPie: false,
												destetePrecoz: true,
											})
										}
									/>
								</Col>
							</Row>
						)}
					</>
				)
				break
		}

		return jsx
	}

	return (
		<div id="estados-extra">
			{getElements()}
		</div>
	)
}
