import React, { useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import { TIPOS_TAREAS, TIPOS_EVENTOS } from 'config/tareas'
import { IngresoPreexistente } from './IngresoPreexistente'
import { Pesaje } from './Pesaje'
import { Salida } from './Salida'
import { Servicio } from './Servicio'
import { DiagnosticoPrenez } from './DiagnosticoPrenez'
import { Adquisicion } from './Adquisicion'
import { Identificacion } from './Identificacion'
import { Paricion } from './Paricion'
import { Mortandad } from './Mortandad'
import { Consumo } from './Consumo'
import { CambioCategoria } from './CambioCategoria'
import { Sanitacion } from './Sanitacion'

export default function MostrarAccionCrear(props) {
	const { config, tipo, onClose, onGuardar } = props

	const ref = useRef(null)

	const handleClose = () => {
		onClose()
	}

	const handleCrearTarea = (data) => {
		onGuardar(data)
	}

	let contenido = ''
	switch (tipo) {
		case 'carga-preexistente':
			contenido = <IngresoPreexistente config={config} onClose={handleClose} onCrearTarea={handleCrearTarea} />
			break
		case 'pesaje':
			contenido = <Pesaje config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		case 'salida':
			contenido = <Salida config={config} onClose={handleClose} onGuardar={handleCrearTarea} />
			break
		case 'servicio':
			contenido = <Servicio config={config} onClose={handleClose} onGuardar={handleCrearTarea} />
			break
		case 'diagnostico-prenez':
			contenido = <DiagnosticoPrenez config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		case 'adquisicion':
			contenido = <Adquisicion config={config} onClose={handleClose} onGuardar={handleCrearTarea} />
			break
		case 'identificacion':
			contenido = <Identificacion config={config} onClose={handleClose} onGuardar={handleCrearTarea} />
			break
		case 'sanitacion':
			contenido = <Sanitacion config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		// Eventos
		case 'paricion':
			contenido = <Paricion config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		case 'mortandad':
			contenido = <Mortandad config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		case 'consumo':
			contenido = <Consumo config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		case 'cambio-categoria':
			contenido = <CambioCategoria config={config} onClose={handleClose} onGuardar={handleCrearTarea} containerRef={ref} />
			break
		default:
			contenido = <></>
	}


	return (
		<Modal show={true} fullscreen onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					<Button
						className="rounded-pill me-2"
						variant="secondary"
						size="sm"
						onClick={handleClose}
					>
						<i className={`fa me-1 fa-arrow-left`}></i>
						Atrás
					</Button>
					Crear {tipo}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="bg-light rounded" ref={ref}>
				{contenido}
			</Modal.Body>
			{/*
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={handleSave}>
						Guardar
					</Button>
				</Modal.Footer>
				*/}
		</Modal>
	)
}