import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { toast } from 'react-toastify'
import '../reportes.css'

import api from 'api/api'

export default function ReportePariciones() {

	useEffect(() => {
		toast('Tareas')
	}, [])

	// render
	return (
		<Container fluid id="reporte-pariciones" className="vh-100">
			{/* Header */}
			<Row>
				<Col className="mt-2">
					<h1>Reporte de pariciones</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					Contenido del reporte
				</Col>
			</Row>
		</Container>
	)
}
