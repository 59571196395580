import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { getShortDisplayDate } from 'utils/date'

export default function FichaParicionesTabContent(props) {
	const { pariciones } = props

	const totalHijos = pariciones.reduce((acc, curr) => acc + curr.numHijos, 0)
	const totalMuertos = pariciones.reduce((acc, paricion) => {
		const muertosEnParicion = paricion.hijos.filter(hijo => !hijo.vivo).length
		return acc + muertosEnParicion;
	}, 0)

	return (
		<div className="tab-content-wrapper">
			<h2>Historial pariciones</h2>
			<div className="col-12 col-sm-6 mb-4">
				<Table size="sm">
					<tbody>
						<tr>
							<th>Cantidad de hijos</th>
							<td>{totalHijos}</td>
						</tr>
						<tr>
							<th>Nacidos muertos</th>
							<td>{totalMuertos}</td>
						</tr>
					</tbody>
				</Table>
			</div>
			{pariciones.length ? (
				<div className="col-12 col-sm-6">
					<Table size="sm">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Núm hijos</th>
								<th>Nacidos muertos</th>
							</tr>
						</thead>
						<tbody>
							{
								pariciones.map(paricion => {
									const nacidosMuertos = paricion.hijos.filter(hijo => !hijo.vivo).length

									return <tr key={paricion.id}>
										<th>{getShortDisplayDate(paricion.fecha)}</th>
										<td>{paricion.hijos.length}</td>
										<td>{nacidosMuertos}</td>
									</tr>
								})
							}
						</tbody>
					</Table>
				</div>
			) : (
				<Alert variant="info" className="mt-3">No hay pariciones del animal</Alert>
			)}
		</div>
	)
}