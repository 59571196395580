import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

export default function FinalizarTareaModal(props) {
	const { tarea, onCancel, onConfirm, numComentarioRows } = props
	const [comentario, setComentario] = useState('')

	const handleCancel = () => {
		onCancel()
	}

	const handleConfirm = () => {
		onConfirm(tarea.id, comentario)
	}

	return (
		<Modal show={true} onHide={handleCancel}>
			<Modal.Header closeButton>
				<Modal.Title>Finalizar tarea</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Form.Group controlId="txtTareaObservacion" className="col-12 mb-2">
						<Form.Label className="mb-1"><strong>Motivo de finalización</strong></Form.Label>
						<Form.Control as="textarea" rows={numComentarioRows || 3}
							value={comentario}
							onChange={(e) => setComentario(e.target.value)}
							autoFocus
						/>
					</Form.Group>
				</Row>
				<Alert variant="danger">
					Está a punto de finalizar la tarea <strong>{`${tarea.nombre}`}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleCancel}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={handleConfirm}>
					Finalizar
				</Button>
			</Modal.Footer>
		</Modal>
	)

}