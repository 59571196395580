import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

export default function Propietario(props) {
	const { propietario, onSave, onCancel } = props

	const [nombre, setNombre] = useState(propietario?.nombre || '')
	const [apellido, setApellido] = useState(propietario?.apellido || '')
	const [codigo, setCodigo] = useState(propietario?.codigo || '')

	const handleChangeNombre = (event) => {
		setNombre(event.target.value)
	}

	const handleChangeApellido = (event) => {
		setApellido(event.target.value)
	}

	const handleChangeCodigo = (event) => {
		setCodigo(event.target.value)
	}

	const isValid = () => {
		let valid = false

		if (nombre !== '' && codigo !== '') {
			valid = true
		}

		if (!valid) {
			toast.warn(`Completar todos los campos antes de guardar.`)
		}
		
		return valid
	}

	const handleClose = () => {
		onCancel()
	}

	const handleSave = () => {
		if (isValid()) {
			const nuevoPropietario = {
				nombre,
				apellido,
				codigo,
			}

			if (propietario?.id) {
				nuevoPropietario.id = propietario.id
			}

			onSave(nuevoPropietario)
		}
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{propietario.id ? 'Editar' : 'Nuevo'} Propietario</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col>
							<Form.Group id="nombre">
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									placeholder="Nombre"
									type="text"
									value={nombre}
									onChange={handleChangeNombre}
									required
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group id="apellido">
								<Form.Label>Apellido</Form.Label>
								<Form.Control
									placeholder="Apellido"
									type="text"
									value={apellido}
									onChange={handleChangeApellido}
									required
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group id="codigo">
								<Form.Label>Código</Form.Label>
								<Form.Control
									placeholder="Código"
									type="text"
									value={codigo}
									onChange={handleChangeCodigo}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}