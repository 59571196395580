import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Datetime from "react-datetime"
import { handleChangeFecha } from 'utils/date'
import { ButtonWithTooltip } from 'components/ButtonWithTooltip'
import FileUpload from 'components/FileUpload/FileUpload'
import { DetallesAnimalTable } from 'components/DetallesAnimalTable'

export default function DefinirMortandades(props) {
	const { animales, mortandades, changeMortandadData, verSiCompletadoDatosMortandad, onAtras } = props

	const [numAct, setNumAct] = useState(0)

	useEffect(() => {
		if (numAct > 0 && !mortandades[numAct].fecha) {
			changeMortandadData(mortandades[numAct - 1].fecha, 'fecha', numAct)
		}
	}, [numAct])

	const siguienteDisabledText = verSiCompletadoDatosMortandad(mortandades[numAct]) ? '' : 'Se debe completar para pasar al siguiente animal'

	const handleAtras = () => {
		onAtras()
	}

	const updateArchivo = (files) => {
		console.log('files', files)
		changeMortandadData(files[0], 'foto', numAct)
	}

	return (
		<>
			<Row>
				<Col className="text-start">
					<Button
						variant="primary"
						className="ms-2"
						onClick={handleAtras}
					>
						<i className="fa fa-caret-left"></i> Atrás
					</Button>
				</Col>
			</Row>
			<Row>
				<Col sm="6" lg="4">
					<Row>
						<Col>
							<Card.Title className="mt-3">Animal seleccionado <strong>{numAct + 1}</strong>/{mortandades.length}</Card.Title>
							<DetallesAnimalTable animal={animales[numAct]} />
						</Col>
					</Row>
					{animales.length > 1 && (
						<Row className="mb-3">
							<Col className="d-flex justify-content-around">
								<Button
									variant="success"
									onClick={() => setNumAct(prev => prev - 1)}
									disabled={numAct === 0}
								>
									<i className="fa fa-caret-left"></i> ANTERIOR
								</Button>
								<ButtonWithTooltip
									tooltipText={siguienteDisabledText}
									variant="success"
									onClick={() => setNumAct(prev => prev + 1)}
									disabled={numAct === mortandades.length - 1 || siguienteDisabledText !== ''}
								>
									SIGUIENTE <i className="fa fa-caret-right"></i>
								</ButtonWithTooltip>
							</Col>
						</Row>
					)}
				</Col>
				<Col sm="6" lg="8">
					<div className="border p-3">
						<Row>
							<Col>
								<Form.Group controlId="txtFecha">
									<Form.Label className="mb-1">Fecha mortandad</Form.Label>
									<Datetime
										key={`datetime-fecha-${numAct}`}
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										closeOnSelect={true}
										strictParsing={false}
										inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtFecha', className: 'form-control form-control-sm' }}
										value={mortandades[numAct].fecha}
										onChange={(date) => handleChangeFecha(date, changeMortandadData, 'fecha', numAct)}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="txtLugar">
									<Form.Label className="mb-1">Lugar</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={mortandades[numAct].lugar}
										onChange={(e) => changeMortandadData(e.target.value, 'lugar', numAct)}
									/>
								</Form.Group>
								<Form.Group controlId="txtCausa" className="mb-0">
									<Form.Label>Causa</Form.Label>
									<Form.Control as="textarea" rows={3}
										value={mortandades[numAct].causa}
										onChange={(e) => changeMortandadData(e.target.value, 'causa', numAct)}
									/>
								</Form.Group>
								<FileUpload
									key={`foto-${numAct}`}
									alreadySelectedFiles={mortandades[numAct].foto ? { [mortandades[numAct].foto.name]: mortandades[numAct].foto } : {}}
									updateFiles={(files) => updateArchivo(files)}
									title={'Adjuntar foto'}
									accept=".png,.jpg,.jpeg"
								/>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</>
	)
}