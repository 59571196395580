import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Alert } from 'react-bootstrap'
import DefinirAnimales from './DefinirAnimales'
import DefinirMortandades from './DefinirMortandades'
import { ScrollTop } from 'components/ScrollTop'


export default function Mortandad(props) {
	const { config, onClose, onGuardar, containerRef } = props

	const [seleccionados, setSeleccionados] = useState([])

	const [mostrarMortandades, setMostrarMortandades] = useState(false)
	/*
	 * Array de elementos con los siguientes campos:
	 * id: animalId,
	 * fecha,
	 * lugar,
	 * causa,
	 * foto
	 */
	const [mortandades, setMortandades] = useState([])

	const datosInicialesAnimal = {
		fecha: '',
		causa: '',
		lugar: '',
		foto: null
	}

	const verSiCompleto = () => {
		let completo = true

		mortandades.forEach((mortandad, i) => {
			if (!verSiCompletadoDatosMortandad(mortandad)) {
				completo = false
			}
		})

		return completo
	}

	const verSiCompletadoDatosMortandad = (mortandad) => {
		let completado = false
		if (mortandad.fecha !== '' && mortandad.causa !== '' && mortandad.lugar !== '') {
			completado = true
		}

		return completado
	}

	const completo = mostrarMortandades && verSiCompleto()

	const handleDefinirMortandades = (animales) => {
		const animalesConCategoria = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})
		setSeleccionados(animalesConCategoria)

		setMortandades(animalesConCategoria.map(animal => ({
			...datosInicialesAnimal,
			id: animal.id
		})))

		setMostrarMortandades(true)
	}

	const changeMortandadData = (valor, cual, indice) => {
		const mortandadesCopy = [...mortandades]
		mortandadesCopy[indice][cual] = valor

		setMortandades(mortandadesCopy)
	}

	const handleDefinirMortandadesAtras = () => {
		setMostrarMortandades(false)
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'mortandad',
			sinPlanificacion: true,
			listas: [
				{
					nombre: 'animales',
					tipo: 'mortandad',
					cantidadAnimales: mortandades.length,
					animalesIds: mortandades.map(mortandad => mortandad.id),
					marcarProcesados: mortandades
				}
			]
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="card-bg-gray">
				<Card.Header as='h5'>Evento de Mortandad</Card.Header>
			</Card>
			{!mostrarMortandades && (
				<Row>
					<Col>
						<Alert variant="info">Primero seleccionar los animales que hayan muerto, luego proceder a la carga del evento de mortandad de cada uno.</Alert>
					</Col>
				</Row>
			)}
			<Card className="mt-3">
				<Card.Body>
					{mostrarMortandades ? (
						<DefinirMortandades
							animales={seleccionados}
							mortandades={mortandades}
							changeMortandadData={changeMortandadData}
							verSiCompletadoDatosMortandad={verSiCompletadoDatosMortandad}
							onAtras={handleDefinirMortandadesAtras}
						/>
					) : (
						<DefinirAnimales
							config={config}
							animalesSeleccionados={seleccionados}
							onGuardar={handleDefinirMortandades}
						/>
					)}
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={!completo}
							>
								Guardar
							</Button>
						</Col>
					</Row>
					<ScrollTop containerRef={containerRef} showFrom={1000} top={210} />
				</Card.Body>
			</Card>

		</Container>
	)
}