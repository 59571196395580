import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import { LISTA_MESES } from 'utils/constants'
import NuevoRegistro from './NuevoRegistro'
import EditarRegistro from './EditarRegistro'
import EliminarRegistro from './EliminarRegistro'

function Registros(props) {
	const { registros, establecimientos, establecimientoActId, establecimientoParteActId, anho, crear, editar, eliminar } = props

	const establecimientosMap = establecimientos ? establecimientos.reduce((map, establecimiento) => {
		map[establecimiento.id] = establecimiento;
		return map;
	}, {}) : {}

	const [filtros, setFiltros] = useState({
		tipo: 'lluvia',
		mes: '0'
	})

	const [registroActual, setRegistroActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const handleChangeFiltros = (valor, cual) => {
		const nuevosFiltros = { ...filtros, [cual]: valor }
		setFiltros(nuevosFiltros)
	}

	const handleAction = (registro, cual) => {
		setRegistroActual(registro, setQueMostrar(cual))
	}

	const [registrosFiltrados, setRegistrosFiltrados] = useState(registros)

	useEffect(() => {
		setRegistrosFiltrados(registros.filter(registro => {
			const fecha = new Date(registro.fecha)

			return registro.tipo === filtros.tipo
				&& fecha.getFullYear() === parseInt(anho)
				&& (parseInt(filtros.mes) === 0 || fecha.getMonth() + 1 === parseInt(filtros.mes))
				&& registro.establecimientoId === parseInt(establecimientoActId)
				&& (establecimientoParteActId === -1 || registro.establecimientoParteId === parseInt(establecimientoParteActId))
		}))
	}, [registros, filtros])

	return (
		<>
			<h5>Registros del año {anho}</h5>
			<Row>
				<Col md={3}>
					<Form.Group className="mb-3" controlId="filtro-tipo">
						<Form.Label>Tipo</Form.Label>
						<Form.Select onChange={(e) => handleChangeFiltros(e.target.value, 'tipo')}>
							<option value="lluvia">Lluvia</option>
							<option value="helada">Helada</option>
						</Form.Select>
					</Form.Group>
				</Col>
				<Col md={3}>
					<Form.Group className="mb-3" controlId="filtro-mes">
						<Form.Label>Mes</Form.Label>
						<Form.Select onChange={(e) => handleChangeFiltros(e.target.value, 'mes')}>
							{LISTA_MESES.map((m, i) => (
								<option key={`mes-${i}`} value={i}>
									{m}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col md={3} className="d-flex align-items-center mt-3">
					<Button
						className="btn-block"
						disabled={!establecimientos.length}
						onClick={() => setQueMostrar('crear')}
					>
						Agregar
					</Button>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					{!registrosFiltrados.length ? (
						<Alert variant="info">No hay registros que mostrar.</Alert>
					) : (
						<Table className="mt-2" striped bordered hover>
							<thead>
								<tr className="text-secondary">
									<th>Id</th>
									<th>Tipo</th>
									<th>Fecha</th>
									<th>Cantidad ml</th>
									<th>Establecimiento</th>
									<th>Sector</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{registrosFiltrados
									.map((registro) => (
										<tr key={registro.id}>
											<td>{registro.id}</td>
											<td>{registro.tipo}</td>
											<td>{registro.fecha.substring(0, 10)}</td>
											<td>{registro.tipo === 'helada' ? '-' : registro.ml}</td>
											<td>{establecimientosMap[registro.establecimientoId].nombre}</td>
											<td>{establecimientosMap[registro.establecimientoId].partes.filter((p) => p.id === registro.establecimientoParteId)[0].nombre}</td>
											<td>
												<Button
													onClick={() => handleAction(registro, 'editar')}
													className="me-2"
													variant="warning"
												>
													<i className="fa-solid fa-pen-to-square"></i>
												</Button>
												<Button
													onClick={() => handleAction(registro, 'eliminar')}
													variant="danger"
												>
													<i className="fa-solid fa-trash-can"></i>
												</Button>
											</td>
										</tr>
									)
									)}
							</tbody>
						</Table>
					)}
				</Col>
			</Row>
			{queMostrar === 'crear' && (
				<NuevoRegistro
					guardar={crear}
					setQueMostrar={setQueMostrar}
					establecimientos={establecimientos}
				/>
			)}
			{registroActual && queMostrar === 'editar' && (
				<EditarRegistro
					editar={editar}
					setQueMostrar={setQueMostrar}
					establecimientos={establecimientos}
					registroOriginal={registroActual}
				/>
			)}
			{registroActual && queMostrar === 'eliminar' &&
				<EliminarRegistro
					registro={registroActual}
					setQueMostrar={setQueMostrar}
					eliminar={eliminar}
				/>
			}
		</>
	)
}

export default Registros