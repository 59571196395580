import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { calculateAge, calculateAgeFromCarimbo } from 'utils/calcularEdad'
import { FichaAnimal, FichaDocumentosTabContent, FichaMovimientosTabContent, FichaParicionesTabContent, FichaPesajesTabContent } from '.'

export default function ModalFichaAnimal(props) {
	const { animal, onSave, onCancel, propietarios, establecimientos } = props

	const [ficha, setFicha] = useState({
		numCaravana: animal.numCaravana ?? '-',
		numChip: animal.numChip ?? '-',
		numTatuaje: animal.numTatuaje ?? '-',
		fechaNacimiento: animal.fechaNacimiento ?? '-',
		carimbo: animal.carimbo,
		raza: animal.raza,
		color: animal.color,
		sexo: animal.sexo,
		lote: animal.lote || '-'
	})

	const [propietario, setPropietario] = useState(propietarios.filter(propietario => propietario.id === animal.propietarioId)[0])
	const [establecimiento, setEstablecimiento] = useState(establecimientos.filter(establecimiento => establecimiento.id === animal.establecimientoId)[0])
	const [tab, setTab] = useState('historial-pesajes');

	const edad = animal.fechaNacimiento ? calculateAge(animal.fechaNacimiento) : calculateAgeFromCarimbo(ficha.carimbo)

	const isValid = () => {
		let valid = false

		// validación

		if (!valid) {
			toast.warn(`Completar todos los campos antes de guardar.`)
		}

		return valid
	}

	const handleClose = () => {
		onCancel()
	}

	const handleChange = (valor, cual) => {
		setFicha(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleChangeSelectPropietario = (propietarioId) => {
		const propietario = propietarios.filter(propietario => propietario.id === parseInt(propietarioId))[0]
		setPropietario(prev => ({ ...propietario }))
	}

	const handleChangeSelectEstablecimiento = (establecimientoId) => {
		const establecimiento = establecimientos.filter(establecimiento => establecimiento.id === parseInt(establecimientoId))[0]
		setEstablecimiento(prev => ({ ...establecimiento }))
	}

	const handleSave = () => {
		if (isValid()) {
			const datos = {}

			onSave(datos)
		}
	}

	return (
		<Modal show={true} fullscreen onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					<Button
						className="rounded-pill me-2"
						variant="secondary"
						size="sm"
						onClick={handleClose}
					>
						<i className={`fa me-1 fa-arrow-left`}></i>
						Atrás
					</Button>
					Ficha de animal
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='align-items-center'>
				<FichaAnimal
					animal={animal}
					onSave={handleSave}
					onCancel={handleClose}
					propietarios={propietarios}
					establecimientos={establecimientos}
				/>
			</Modal.Body>
		</Modal>
	)
}