import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import BaseDataMostrar from '../BaseDataMostrar'
import { capitalizeWord, convertBytesToKB, getUpladsUrl } from 'utils/general'
import { getShortDisplayDate } from 'utils/date'

export default function AdquisicionVer(props) {
	const { config, tarea } = props

	const [tab, setTab] = useState('origen-destino');

	let totalAdquiridos = 0
	let totalMuertos = 0
	tarea.adquisicion.listas.forEach(lista => {
		totalAdquiridos += lista.cantidad
		totalMuertos += lista.numMuertos
	})

	const propietarioDestino = config.propietarios.find(propietario => propietario.id === tarea.adquisicion.propietarioDestinoId)
	const establecimientoDestino = config.establecimientos.find(establecimiento => establecimiento.id === tarea.adquisicion.establecimientoDestinoId)

	const documentacion = [
		{
			texto: 'Guía',
			key: 'guia',
			archivo: tarea.adquisicion['guia']
		},
		{
			texto: 'Cota',
			key: 'cota',
			archivo: tarea.adquisicion['cota']
		},
		{
			texto: 'Remisión',
			key: 'remision',
			archivo: tarea.adquisicion['remision']
		},
	]

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			{tarea.comentarioEncargado && <><h5 className="mt-3 mb-1">Comentario encargado</h5><Alert variant="info" className="">{tarea.comentarioEncargado}</Alert></>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Tipo</th>
										<td>{capitalizeWord(tarea.adquisicion.tipo)}</td>
									</tr>
									<tr>
										<th>Estado</th>
										<td>{tarea.adquisicion.estado}</td>
									</tr>
									<tr>
										<th>Total listas</th>
										<td>{tarea.adquisicion.listas.length}</td>
									</tr>
									<tr>
										<th>Total adquiridos</th>
										<td>{totalAdquiridos}</td>
									</tr>
									<tr>
										<th>Mortandad total</th>
										<td>{totalMuertos}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Row className="mt-3">
				<Col>
					<Table>
						<thead>
							<tr>
								<th>Tipo animales</th>
								<th>Cantidad esperada</th>
								<th>Mortandad</th>
								<th>Identificación</th>
							</tr>
						</thead>
						<tbody>
							{tarea.adquisicion.listas.map((lista, i) => {
								const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(lista.categoriaId))

								let tipoAnimalesText = categoria.nombre

								if (categoria.id === 7 || categoria.id === 9) {
									tipoAnimalesText += ', ' + lista.sexo
								}

								const listaClass = lista.numMuertos > 0 ? 'lista-con-mortandad' : ''

								return (
									<tr key={`lista-${i}`} className={listaClass}>
										<td>{tipoAnimalesText}</td>
										<td>{lista.cantidad}</td>
										<td>{lista.numMuertos ? lista.numMuertos : '-'}</td>
										<td>{lista.conTareaIdentificacion ? getShortDisplayDate(lista.fechaTareaIdentificacion) : '-'}</td>
									</tr>
								)
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs
						activeKey={tab}
						onSelect={(t) => setTab(t)}
						className="mb-3"
					>
						<Tab eventKey="origen-destino" title="Origen y destino">
							<Row>
								<Col>
									<h4 className="mb-1">Destino</h4>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body>
											<Card.Title>Propietario</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{`${propietarioDestino.nombre} ${propietarioDestino.apellido}`}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>{propietarioDestino.codigo}</div>
										</Card.Body>
									</Card>
								</Col>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body  >
											<Card.Title>Establecimiento</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{`${establecimientoDestino.nombre}`}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>{establecimientoDestino.codigo}</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<h4 className="mb-1">Origen</h4>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body>
											<Card.Title>Propietario</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{`${tarea.adquisicion.propietarioOrigenNombre}`}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>
												{`${tarea.adquisicion.propietarioOrigenTipoDocumento} - ${tarea.adquisicion.propietarioOrigenDocumento}`}
											</div>
											<div style={{ color: '#444' }}>
												{`Nacionalidad ${tarea.adquisicion.propietarioOrigenNacionalidad}`}
											</div>
										</Card.Body>
									</Card>
								</Col>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body>
											<Card.Title>Establecimiento</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{`${tarea.adquisicion.establecimientoOrigenNombre}`}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>{tarea.adquisicion.establecimientoOrigenCodigo}</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="documentacion" title="Documentación">
							<Row>
								<Col xs="12" sm="6" md="4">
									<Table>
										<tbody>
											<tr>
												<th >Finalidad</th>
												<td>{tarea.adquisicion.finalidadCota}</td>
											</tr>
											<tr>
												<th>Núm. Guía</th>
												<td>{tarea.adquisicion.numeroGuia}</td>
											</tr>
											<tr>
												<th>Núm. Cota</th>
												<td>{tarea.adquisicion.numeroCota}</td>
											</tr>
										</tbody>
									</Table>
								</Col>
							</Row>
							<Row className="gx-2">
								{documentacion.map((documento, i) => {

									let jsx = <div className="sin-archivo">Sin archivo</div>
									if (documento.archivo) {
										const nombre = documento.archivo.url.split('/').pop()

										jsx = <>
											<div className="movimiento-documento-nombre">
												<a href={getUpladsUrl() + documento.archivo.url} target='_blank'>
												<i
													className="fas fa-file ms-2 me-2"
													title={`Descargar archivo de ${documento.texto}`}
												/>{nombre}
												</a>
											</div>
											<div className="movimiento-documento-tamano">
												{convertBytesToKB(documento.archivo.tamano)} kb
											</div>
										</>
									}

									return (
										<Col key={`documento-${i}`} xs={12} sm={6} md={4}>
											<div className="movimiento-documento">
												{jsx}
												<div className="movimiento-documento-titulo">{documento.texto}</div>
											</div>
										</Col>
									)
								})}
							</Row>
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container >
	)
}