import React, { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import { getUpladsUrl } from 'utils/general'

export default function FichaDocumentosTabContent(props) {
	const { documentos } = props

	return (
		<div className="tab-content-wrapper">
			<h2>Entrada</h2>
			{documentos.entrada ? (
				<div className="col-12 col-sm-6">
					<Table size="sm">
						<tbody>
							<tr>
								<th>Guía</th>
								<td>{documentos.entrada.guia ? documentos.entrada.guia.numero : ''}</td>
								<td>{documentos.entrada.guia ? <a href={getUpladsUrl() + documentos.entrada.guia.url} target="_blank"><i className="fa fa-file-arrow-down"></i> Descargar</a> : <><i className="fa fa-file-circle-exclamation"></i> Sin archivo</>}</td>
							</tr>
							<tr>
								<th>Cota</th>
								<td>{documentos.entrada.cota ? (`${documentos.entrada.cota.numero} [${documentos.entrada.cota.finalidad}]`) : ''}</td>
								<td>{documentos.entrada.cota ? <a href={getUpladsUrl() + documentos.entrada.cota.url} target="_blank"><i className="fa fa-file-arrow-down"></i> Descargar</a> : <><i className="fa fa-file-circle-exclamation"></i> Sin archivo</>}</td>
							</tr>
							<tr>
								<th>Remisión</th>
								<td colSpan={2}>{documentos.entrada.remision ? <a href={getUpladsUrl() + documentos.entrada.remision.url} target="_blank"><i className="fa fa-file-arrow-down"></i> Descargar</a> : <><i className="fa fa-file-circle-exclamation"></i> Sin archivo</>}</td>
							</tr>
						</tbody>
					</Table>
				</div>
			) : (
				<Alert variant="info" className="mt-3">Este animal no fue ingresado con documentos.</Alert>
			)}
			<h2>Salida</h2>
			{documentos.salida ? (
				<div className="col-12 col-sm-6">
					<Table size="sm">
						<tbody>
							<tr>
								<th>Guía</th>
								<td>{documentos.salida.guia ? documentos.salida.guia.numero : ''}</td>
								<td>{documentos.salida.guia ? <a href={getUpladsUrl() + documentos.salida.guia.url} target="_blank"><i className="fa fa-file-arrow-down"></i> Descargar</a> : <><i className="fa fa-file-circle-exclamation"></i> Sin archivo</>}</td>
							</tr>
							<tr>
								<th>Cota</th>
								<td>{documentos.salida.cota ? (`${documentos.salida.cota.numero} [${documentos.salida.cota.finalidad}]`) : ''}</td>
								<td>{documentos.salida.cota ? <a href={getUpladsUrl() + documentos.salida.cota.url} target="_blank"><i className="fa fa-file-arrow-down"></i> Descargar</a> : <><i className="fa fa-file-circle-exclamation"></i> Sin archivo</>}</td>
							</tr>
							<tr>
								<th>Remisión</th>
								<td colSpan={2}>{documentos.salida.remision ? <a href={getUpladsUrl() + documentos.salida.remision.url} target="_blank"><i className="fa fa-file-arrow-down"></i> Descargar</a> : <><i className="fa fa-file-circle-exclamation"></i> Sin archivo</>}</td>
							</tr>
						</tbody>
					</Table>
				</div>
			) : (
				<Alert variant="info" className="mt-3">Este animal todavía no cuenta con una salida.</Alert>
			)}
		</div>
	)
}