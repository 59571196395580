import React, { useEffect, useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import './EditableList.css'

/*
 * Para manejo de listas del tipo {id: int, value: string}
 */
export default function(props) {
	const { id=`editableList-${Math.random()}`, titulo, lista, setLista, colSizes = { sm: 12, md: 6, lg: 4 }, btnAgregarSize = '', unSoloAgregadoPorVez = true } = props
	const [numCreados, setNumCreados] = useState(0)

	const updateItem = (id, value) => {
		setLista(prev =>
			lista.map(elem => {
				if (elem.id === id) {
					return { id, value: value }
				} else {
					return elem
				}
			})
		)
	}

	const eliminarItem = (id) => {
		setLista(prev =>
			lista.filter(elem => elem.id !== id)
		)
	}

	const agregarItem = () => {
		setLista([...lista, {id: `temp-${numCreados}`, value: ''}])
		setNumCreados(numCreados + 1)
	}

	const getAgregable = () => {
		return unSoloAgregadoPorVez && lista.filter(elem => isNaN(elem.id) && elem.id.startsWith('temp') && elem.value.trim() === '').length > 0
	}

	useEffect(() => {
		if (lista.length > 0 && isNaN(lista[lista.length - 1].id) && lista[lista.length -1].id.startsWith('temp')) {
			const last = Array.from(
				document.getElementById(id).getElementsByClassName('form-control')
			).pop().focus()
		}
	}, [lista])

	return (
		<Row id={id} className="editableList">
			<h3>{titulo}</h3>
			{lista.map((elem) =>
				<Row key={`elemento-${elem.id}`}>
					<Col {...colSizes}>
						<InputGroup className={`elemento mb-3`}>
							<Form.Control
								value={elem.value}
								onChange={(e) => updateItem(elem.id, e.target.value)}
							/>
							<Button variant="outline-secondary" className={'editableListEliminar'} onClick={() => eliminarItem(elem.id)}>
								<i className="fa fa-trash"></i>
							</Button>
						</InputGroup>
					</Col>
				</Row>
			)}
			<Col>
				<Button variant="primary" size={btnAgregarSize} onClick={agregarItem} disabled={getAgregable()}>Agregar <i className="fa fa-plus"></i></Button>
			</Col>
		</Row>
	)
}
