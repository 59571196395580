import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { isLoggedIn } from './utils/general'
import SideMenu from 'components/SideMenu/SideMenu'

import NoMatch from './pages/NoMatch'
import Login from './pages/login/Login'
import Home from './pages/home/Home'
import { Establecimientos, Lotes, Propietarios } from './pages/configuraciones'
import { TestComponent } from 'pages/test'
import Stock from 'pages/stock/Stock'
import { Clima } from 'pages/clima'
import { Tareas } from 'pages/tareas'
import { ReporteEntradas, ReportePariciones, ReportePesajes } from 'pages/reportes'
import { ConfigProvider } from 'contexts/config/ConfigProvider'
import ReporteSalidas from 'pages/reportes/salidas/ReporteSalidas'
import Busqueda from 'pages/busqueda/Busqueda'

function App() {
	// login state
	const [loggedIn, setLoggedIn] = useState(
		isLoggedIn()
	);

	const [menuExpanded, setMenuExpanded] = useState(true)

	if (menuExpanded) {
		document.body.classList.add('menu-expanded')
	} else {
		document.body.classList.remove('menu-expanded')
	}

	useEffect(() => {
		setLoggedIn(isLoggedIn())
	}, []);

	// render
	return (
		<>
			<ToastContainer />
			<Router basename={process.env.PUBLIC_URL}>
				<Route render={({ location, history }) => (
					<>
						<Route exact path="/login" component={props => <Login setLoggedIn={(setLoggedIn)} history={history} />} />
						{loggedIn ? (
							<>
								<SideMenu location={location} history={history} setLoggedIn={setLoggedIn} menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded} />
								<main className={menuExpanded ? 'section expanded' : 'section'}>
									<Switch>
										<Route exact path="/" render={() => {
											return (
												<Redirect to="/inicio" />
											)
										}} />
										<Route exact path="/inicio">
											<Home />
										</Route>
										<Route exact path="/configuraciones/establecimientos">
											<Establecimientos />
										</Route>
										<Route exact path="/configuraciones/propietarios">
											<Propietarios />
										</Route>
										<Route exact path="/configuraciones/lotes">
											<ConfigProvider>
												<Lotes />
											</ConfigProvider>
										</Route>
										<Route exact path="/stock">
											<Stock />
										</Route>
										<Route exact path="/busqueda">
											<Busqueda />
										</Route>
										<Route exact path="/tareas">
											<ConfigProvider>
												<Tareas />
											</ConfigProvider>
										</Route>
										<Route exact path="/clima">
											<Clima />
										</Route>
										<ConfigProvider>
											<Route exact path="/reportes/entradas">
												<ReporteEntradas />
											</Route>
											<Route exact path="/reportes/salidas">
												<ReporteSalidas />
											</Route>
											<Route exact path="/reportes/pesajes">
												<ReportePesajes />
											</Route>
											<Route exact path="/reportes/pariciones">
												<ReportePariciones />
											</Route>
											<Route exact path="/test">
												<TestComponent />
											</Route>
										</ConfigProvider>
										{loggedIn && <Route path="*" component={props => <NoMatch />} />}
									</Switch>
								</main>
							</>
						) : null}
					</>
				)}
				/>
				{!loggedIn && <Redirect to="/login" />}
			</Router>
		</>
	);
}

export default App
