import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { ConfigContext } from 'contexts/config'
import { debounce } from 'utils/debounce'
import '../reportes.css'

import axios from 'axios'
import api from 'api/api'
import { FETCH_DE_A_CUANTOS, OPCIONES_DE_A_CUANTOS } from 'config/reportes'
import { getBaseUrl, getUpladsUrl } from 'utils/general'
import { SortableTable } from 'components/SortableTable'
import Paginacion from 'components/Paginacion'
import { getInicioAnho, getShortDisplayDate } from 'utils/date'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import { MultiSelectCustom } from 'components/MultiSelectCustom'
import { SelectDateRange } from 'components/SelectRange'
import { DownloadButton } from 'components/DownloadButton'

export default function ReporteEntradas() {
	const { data: config, loading: loadingConfig, error } = useContext(ConfigContext)

	const [cancelToken, setCancelToken] = useState(null)
	const [fetchDeACuantos, setFetchDeACuantos] = useState(FETCH_DE_A_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [datos, setDatos] = useState([])
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(true)
	const [filtros, setFiltros] = useState({
		propietarioId: [],
		establecimientoId: [],
		categoriaId: [],
		fecha: {
			inicio: getInicioAnho(),
			fin: new Date()
		},
		categoria: '',
		orden: {
			campo: '',
			direccion: 'desc'
		}
	})
	const [cambioFiltros, setCambioFiltros] = useState(true)
	const [totalIngresados, setTotalIngresados] = useState(0)
	const [configLoaded, setConfigLoaded] = useState(false)
	const [establecimientoOrigenNombre, setEstablecimientoOrigenNombre] = useState('')

	const opcionesDeACuantos = OPCIONES_DE_A_CUANTOS
	const propietariosOptions = config.propietarios.map((e) => ({
		value: e.id,
		label: `${e.nombre} ${e.apellido}`,
	}))

	const establecimientosOptions = config.establecimientos.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))

	const categoriasOptions = config.categorias.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))

	const resultadoDesde = datos.length ? (paginaAct - 1) * fetchDeACuantos + 1 : 0
	const resultadoHasta = datos.length < fetchDeACuantos ? (resultadoDesde - 1 + datos.length) : resultadoDesde + fetchDeACuantos

	if (!cancelToken) {
		const source = axios.CancelToken.source()
		setCancelToken(source)
	}

	useEffect(() => {
		return () => {

			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	useEffect(() => {
		if (!loadingConfig) {
			setConfigLoaded(true)
			setFiltros(prev => ({
				...prev,
				propietarioId: propietariosOptions,
				establecimientoId: establecimientosOptions,
				categoriaId: categoriasOptions
			}))
		}
	}, [loadingConfig])

	const setFiltroEstablecimientoOrigenNombre = (str) => {
		setFiltros(prev => ({
			...prev,
			establecimientoOrigenNombre: str
		}))
	}

	const debouceFiltroOrigen = React.useCallback(
		debounce(setFiltroEstablecimientoOrigenNombre, 400),
		[]
	)

	useEffect(() => {
		if (!loading && establecimientoOrigenNombre !== filtros.establecimientoOrigenNombre) {
			debouceFiltroOrigen(establecimientoOrigenNombre)
		}
	}, [establecimientoOrigenNombre])


	useEffect(() => {
		if (configLoaded) {
			setCambioFiltros(true)
			fetchDatos(1, cancelToken)
		}
	}, [filtros])

	const handleChangeFiltrosPropietario = (value) => {
		setFiltros(prev => ({
			...prev,
			propietarioId: value
		}))
	}

	const handleChangeFiltrosEstablecimiento = (value) => {
		setFiltros(prev => ({
			...prev,
			establecimientoId: value
		}))
	}

	const handleChangeFiltrosCategoria = (value) => {
		setFiltros(prev => ({
			...prev,
			categoriaId: value
		}))
	}

	const handleChangeFiltros = (valor, cual) => {
		setFiltros(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleCambioOrden = (orden) => {
		handleChangeFiltros({ ...orden }, 'orden')
	}

	useEffect(() => {
		if (!loading) {
			fetchDatos(paginaAct, cancelToken)
		}
	}, [paginaAct, fetchDeACuantos])

	const onChangeFechas = (fechaInicio, fechaFin) => {
		setFiltros(prev => ({
			...prev,
			fecha: {
				inicio: fechaInicio,
				fin: fechaFin
			}
		}))
	}

	const prepareFiltros = () => {
		const copy = {
			...filtros
		}

		if (copy.propietarioId.length) {
			copy.propietarioId = copy.propietarioId.map(elem => elem.value)
		}

		if (copy.establecimientoId.length) {
			copy.establecimientoId = copy.establecimientoId.map(elem => elem.value)
		}

		if (copy.categoriaId.length) {
			copy.categoriaId = copy.categoriaId.map(elem => elem.value)
		}

		return copy
	}

	const fetchDatos = async (pagina, cancelToken) => {
		setLoading(true)
		try {
			const filtrosServer = prepareFiltros(filtros)

			const { datos: respuesta, error } = await api.post('u/reportes/entradas', {
				cuantos: fetchDeACuantos,
				desde: pagina === 1 ? 0 : (pagina - 1) * fetchDeACuantos,
				filtros: filtrosServer
			}, { cancelToken: cancelToken.token })
			if (!error) {
				setPaginaAct(pagina)
				setDatos(respuesta.entradas.map(entrada => {
					const e = { ...entrada }
					e.guia = {
						id: entrada.guiaId,
						numero: entrada.numeroGuia,
						url: entrada.guiaUrl,
						fecha: entrada.guiaFecha
					}
					e.cota = {
						id: entrada.cotaId,
						numero: entrada.numeroCota,
						url: entrada.cotaUrl,
						fecha: entrada.cotaFecha
					}
					e.remision = {
						id: entrada.remisionId,
						numero: entrada.numeroRemision,
						url: entrada.remisionUrl,
						fecha: entrada.remisionFecha
					}

					return e
				}))
				setNumResultados(respuesta.numResultados)
				setTotalIngresados(respuesta.totalIngresados)
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.log('get datos reporte error', error)
			}
		} finally {
			setLoading(false)
			setCambioFiltros(false)
		}
	}

	const linkFormatter = (data) => {
		let content = data.numero

		if (data.url !== null) {
			const href = getUpladsUrl() + data.url
			content = <a href={href} target="_blank">{data.numero}</a>
		}

		return content
	}

	const downloadData = JSON.stringify(prepareFiltros())


	// render
	return (
		<Container fluid id="reporte-entradas" className="vh-100">
			{/* Header */}
			<Row>
				<Col className="mt-2">
					<h1>Reporte de entradas</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="vw-100">
							<Row>
								<Col>
									<SelectDateRange
										desde={filtros.fecha.inicio}
										hasta={filtros.fecha.fin}
										onChange={onChangeFechas}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-propietarioId">
										<Form.Label>Propietarios</Form.Label>
										<MultiSelectCustom
											options={propietariosOptions}
											value={filtros.propietarioId}
											onChange={handleChangeFiltrosPropietario}
											size="sm"
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-establecimientoId">
										<Form.Label>Establecimientos</Form.Label>
										<MultiSelectCustom
											options={establecimientosOptions}
											value={filtros.establecimientoId}
											onChange={handleChangeFiltrosEstablecimiento}
											size="sm"
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-categoriaId">
										<Form.Label>Categoría</Form.Label>
										<MultiSelectCustom
											options={categoriasOptions}
											value={filtros.categoriaId}
											onChange={handleChangeFiltrosCategoria}
											size="sm"
											overrideStrings={{
												allItemsAreSelected: 'Todas',
												selectAll: 'Todas'
											}}
										/>
									</Form.Group>
								</Col>
								<Col md={4} lg={3}>
									<Form.Group className="mb-3" controlId="filtro-origen">
										<Form.Label>Origen</Form.Label>
										<Form.Control
											type="text"
											size="sm"
											value={establecimientoOrigenNombre}
											onChange={(e) => setEstablecimientoOrigenNombre(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<div className="border d-inline-block p-3"><big>Total ingresados: {cambioFiltros ? (<span className="ms-2"><LoadingSpinner size='sm' /></span>) : numberWithSeparator('' + totalIngresados)}</big></div>
				</Col>
			</Row>
			<Row className="py-2">
				<Col>
					<DownloadButton
						url={getBaseUrl() + `u/reportes/entradas/descargar-xlsx?filtros=${downloadData}`}
						whileDownloading={<i className="fa fa-spinner fa-spin"></i>}
						variant="success"
						title="Descargar Excel"
						disabled={loading}
					>
						Descargar <i className="fa fa-file-excel"></i>
					</DownloadButton>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					{cambioFiltros ? (
						<span className="ms-2"><LoadingSpinner size='sm' /></span>
					) : (
						<small>Mostrando {resultadoDesde} al {resultadoHasta} de un total de <strong>{numResultados}</strong></small>
					)}
				</Col>
			</Row>
			{!datos.length && !loading ? (
				<Alert variant="info">No hay resultados</Alert>
			) : (
				<>
					<Row>
						<Col>
							<SortableTable
								datos={datos}
								cambioOrden={handleCambioOrden}
								columns={
									[
										{
											label: 'Fecha',
											key: 'fechaParaCuando',
											dataFormatter: getShortDisplayDate
										},
										{
											label: 'Cantidad',
											key: 'cantidad'
										},
										{
											label: 'Categoría',
											key: 'categoriaNombre'
										},
										{
											label: 'Origen',
											key: 'establecimientoOrigenNombre'
										},
										{
											label: 'Guía',
											key: 'guia',
											dataFormatter: linkFormatter
										},
										{
											label: 'Cota',
											key: 'cota',
											dataFormatter: linkFormatter
										},
									]
								}
								ordenInicial={filtros.orden}
								loading={loading}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Paginacion
								pagina={paginaAct}
								numPaginas={Math.ceil(numResultados / fetchDeACuantos)}
								setPagina={setPaginaAct}
							/>
						</Col>
						<Col className="text-end">
							<small>
								Mostrar de a
								<Form.Group className="mb-3 d-inline-block mx-2" controlId="de-a-cuantos">
									<Form.Select
										size='sm'
										value={fetchDeACuantos}
										onChange={(e) => setFetchDeACuantos(e.target.value)}
									>
										{opcionesDeACuantos.map((num) => {
											return (
												<option value={num} key={`cuantos-${num}`}>
													{num}
												</option>
											)
										})}
									</Form.Select>
								</Form.Group>
								resultados.
							</small>
						</Col>
					</Row>
				</>
			)}
		</Container>
	)
}
