import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { MostrarListaAnimales, SeleccionAnimales } from 'components/SeleccionAnimales'
import useAnimales from 'hooks/useAnimales'

export default function Lote(props) {
	const { config, onSave, onCancel, cols } = props

	const { loading, error, cambiarCategoria, cambiarFiltro, animales, seleccionados, seleccionarAnimal,
		seleccionarMultiplesAnimales, deseleccionarAnimal, pagina, cambiarPagina, deACuantos, cambiarDeACuantos, numResultados } = useAnimales({seleccionados: []})

	const [lote, setLote] = useState({
		nombre: ''
	})

	const isValid = lote.nombre.trim() !== ''

	const handleChangeNombre = (nombre) => {
		setLote(prev => ({
			...prev,
			nombre
		}))
	}

	const handleClose = () => {
		onCancel()
	}

	const handleSave = () => {
		const data = {
			...lote,
			animalesIds: seleccionados.todos.map(animal => animal.id)
		}

		onSave(data)
		handleClose()
	}

	return (
		<Modal show={true} onHide={handleClose} fullscreen>
			<Modal.Header closeButton>
				<Modal.Title>Nuevo Lote</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col>
							<Form.Group id="nombre">
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									placeholder="Nombre"
									type="text"
									value={lote.nombre}
									onChange={(e) => handleChangeNombre(e.target.value)}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md="7">
							<SeleccionAnimales
								config={config}
								loading={loading}
								animales={animales}
								cambiarFiltro={cambiarFiltro}
								cambiarCategoria={cambiarCategoria}
								seleccionados={seleccionados}
								seleccionarAnimal={seleccionarAnimal}
								seleccionarMultiplesAnimales={seleccionarMultiplesAnimales}
								pagina={pagina}
								deACuantos={deACuantos}
								cambiarDeACuantos={cambiarDeACuantos}
								cambiarPagina={cambiarPagina}
								numResultados={numResultados}
							/>
						</Col>
						<Col md="5">
							<MostrarListaAnimales seleccionados={seleccionados} deseleccionarAnimal={deseleccionarAnimal} cols={cols}/>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={!isValid}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}