// hooks/useAnimales.js
import React, { useState, useEffect } from 'react'
import api from 'api/api'
import axios from 'axios'
import { DEFAULT_CATEGORIA_ID, DEFAULT_ORDEN, DEFAULT_SEXO, FETCH_DE_A_CUANTOS } from 'config/seleccionAnimales'
import { capitalizeWord } from 'utils/general'

export default function useAnimales(datosIniciales) {
	const defaultCategoriaId = DEFAULT_CATEGORIA_ID
	const defaultSexo = DEFAULT_SEXO
	const defaultOrden = DEFAULT_ORDEN

	// Viene con un formato diferente del server
	let seleccionadosInicialesAdaptados = []
	if (datosIniciales.seleccionados) {
		seleccionadosInicialesAdaptados = datosIniciales.seleccionados.map(animal => ({ ...animal, categoria: animal.categoria.nombre }))
	}

	const [animales, setAnimales] = useState([])
	const [seleccionados, setSeleccionados] = useState({
		todos: seleccionadosInicialesAdaptados,  // todos los animales seleccionados
		agregadosIds: datosIniciales.agregadosIds || [], // los agregados que estén entre los inicialmente seleccionados
		eliminadosIds: datosIniciales.eliminadosIds || [], // los eliminados de entre los inicialmente seleccionados
		noSeleccionablesIds: datosIniciales.noSeleccionablesIds || []
	})
	const [filtros, setFiltros] = useState(() => {
		const datos = {
			tipoIdentificacion: 'caravana',
			numIdentificador: '',
			categoriaId: datosIniciales?.filtrosIniciales?.categoriaId ? datosIniciales.filtrosIniciales.categoriaId : defaultCategoriaId,
			sexo: datosIniciales?.filtrosIniciales?.sexo ? datosIniciales.filtrosIniciales.sexo : defaultSexo,
			orden: datosIniciales?.filtrosIniciales?.orden ? datosIniciales.filtrosIniciales.orden : defaultOrden
		}

		return datos
	})

	const [pagina, setPagina] = useState(1)
	const [numResultados, setNumResultados] = useState(0)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [fetchDeACuantos, setFetchDeACuantos] = useState(FETCH_DE_A_CUANTOS)

	const animalesSeleccionados = seleccionados.todos

	const filtrosServer = {}
	for (const key in filtros) {
		if (('' + filtros[key]).trim() !== '') {
			switch (key) {
				case 'propietarioId':
				case 'establecimientoId':
				case 'loteId':
					filtrosServer[key] = ['in', [filtros[key]]]
					break
				case 'raza':
				case 'lote':
				case 'carimbo':
				case 'color':
					filtrosServer[key] = ['like', filtros[key]]
					break
				case 'tipoIdentificacion':
				case 'numIdentificador':
					if (filtros['numIdentificador'].trim() !== '') {
						filtrosServer[`num${capitalizeWord(filtros['tipoIdentificacion'])}`] = ['like', filtros['numIdentificador']]
					}
					break
				default:
					filtrosServer[key] = filtros[key]
			}
		}
	}

	const fetchAnimales = async (source) => {
		setLoading(true)

		const body = {
			desde: pagina === 1 ? 0 : (pagina - 1) * fetchDeACuantos,
			cuantos: fetchDeACuantos,
			filtros: filtrosServer,
		}

		try {
			const { datos: respuesta, msg, error } = await api.post('u/ver-animales', body, {
				cancelToken: source.token,
			})

			if (!error) {
				setAnimales(respuesta.animales)
				setNumResultados(respuesta.numResultados)
				setError(null)
			} else {
				setError(msg)
			}
		} catch (err) {
			// Handle any errors and set the error state
			// Check if the error is caused by canceling or timing out the request
			if (axios.isCancel(err)) {
				setError("Request canceled")
			} else if (err.code === "ECONNABORTED") {
				setError("Request timed out")
			} else {
				setError(err.message)
			}
		}

		setLoading(false)
	}

	useEffect(() => {
		const source = cancelToken || axios.CancelToken.source()
		if (!cancelToken) {
			setCancelToken(source)
		}

		fetchAnimales(source)
	}, [filtros, pagina])

	const seleccionarMultiplesAnimales = (animales) => {
		const seleccionadosInicialesIds = datosIniciales.seleccionados.map(a => a.id)
		const seleccionadosIds = seleccionados.todos.map((elem) => elem.id)

		const nuevosAgregadosIds = []
		const nuevosEliminadosIds = [...seleccionados.eliminadosIds]
		const agregables = animales.reduce((agregar, elem) => {
			// Si no está ya agregado, sumar a los que hay que agregar
			if (!seleccionadosIds.includes(elem.id)) {
				agregar.push(elem)

				if (!seleccionadosInicialesIds.includes(elem.id)) {
					nuevosAgregadosIds.push(elem.id)
				}

				if (nuevosEliminadosIds.includes(elem.id)) {
					nuevosEliminadosIds = nuevosEliminadosIds.filter(id => id !== elem.id)
				}
			}

			return agregar
		}, [])

		setSeleccionados({
			todos: [...seleccionados.todos, ...agregables],
			agregadosIds: [...seleccionados.agregadosIds, ...nuevosAgregadosIds],
			eliminadosIds: nuevosEliminadosIds
		})
	}

	const seleccionarAnimal = (animal) => {
		const seleccionadosInicialesIds = datosIniciales.seleccionados.map(a => a.id)

		// Si ya está en la lista, no hacer nada
		if (seleccionados.todos.some((a) => a.id === animal.id)) {
			return
		}

		const data = {
			...seleccionados,
			todos: [...seleccionados.todos, animal],
		}

		if (!seleccionadosInicialesIds.includes(animal.id)) {
			data.agregadosIds = [...seleccionados.agregadosIds, animal.id]
		}

		if (seleccionados.eliminadosIds.includes(animal.id)) {
			data.eliminadosIds = seleccionados.eliminadosIds.filter(id => id !== animal.id)
		}

		setSeleccionados(data)
	}

	const deseleccionarAnimal = (animal) => {
		const seleccionadosInicialesIds = datosIniciales.seleccionados.map(a => a.id)

		const data = {
			...seleccionados,
			todos: seleccionados.todos.filter((a) => a.id !== animal.id),
		}

		if (seleccionadosInicialesIds.includes(animal.id)) {
			data.eliminadosIds = [...seleccionados.eliminadosIds, animal.id]
		}

		if (seleccionados.agregadosIds.includes(animal.id)) {
			data.agregadosIds = seleccionados.agregadosIds.filter(id => id !== animal.id)
		}

		setSeleccionados(data)
	}

	const cambiarCategoria = (categoriaData) => {
		setFiltros(prev => {
			//delete prev['categoriaId']
			delete prev['sexo']

			return {
				...prev,
				...categoriaData
			}
		})

		setPagina(1)
	}

	const cambiarFiltro = (valor, cual) => {
		setFiltros(prev => ({ ...prev, [cual]: valor }))

		// Resetea la página a 1 cuando cambian los filtros
		setPagina(1)
	}

	const cambiarPagina = (num) => {
		setPagina(num)
	}

	const cambiarDeACuantos = (num) => {
		setFetchDeACuantos(num)
		setPagina(1)
		setFiltros(prev => ({ ...prev }))
	}

	// Define a cleanup function that resets the state variables to their initial values
	const resetData = () => {
		setAnimales([])
		setSeleccionados([])
		setFiltros({})
		setPagina(1)
		setNumResultados(0)
		setFetchDeACuantos(FETCH_DE_A_CUANTOS)
		setLoading(false)
		setError(null)
	}


	// Use the useEffect hook to run the cleanup function when the hook is unmounted
	useEffect(() => {
		return () => {
			return () => {
				if (cancelToken) {
					cancelToken.cancel('Petición cancelada')
				}
			}
		}
	}, [])

	// Return an object with all the state variables and helper functions
	return {
		animales,
		animalesSeleccionados,
		seleccionados,
		filtros,
		pagina,
		deACuantos: fetchDeACuantos,
		numResultados,
		loading,
		error,
		seleccionarAnimal,
		seleccionarMultiplesAnimales,
		deseleccionarAnimal,
		cambiarCategoria,
		cambiarFiltro,
		cambiarPagina,
		cambiarDeACuantos
	}
}
