// Wizard.js
import React, { useEffect } from 'react'
import useWizard from './useWizard'
import './wizard.css'

export default function Wizard(props) {
	const { initialData = [], enableSave = false, disableFinish = false, onFinish, alreadyCompleted = [], children } = props

	const {
		data,
		step,
		updateData,
		nextStep,
		prevStep,
		goToStep,
		isCompleted,
		setCompleted,
		getFirstNotCompleted,
	} = useWizard(initialData)

	useEffect(() => {
		// This will only run once when the component mounts
		if (alreadyCompleted.length) {
			setCompleted(alreadyCompleted)
		}
	}, [])

	return (
		<div className="wizard">
			<ul className="wizard-header mb-0">
				{React.Children.map(children, (child, index) => {
					const active = index === step
					const completed = isCompleted(index)
					const allowed = index === getFirstNotCompleted()
					const liClass = active ? 'active' : completed ? 'completed' : ''
					const iconClass = active ? "fa fa-pencil me-2" : (completed ? "fa-regular fa-circle-check" : "fa-solid fa-lock")

					return (
						<li className={`wizard-step ${liClass}`} key={index}>
							<a
								className={!active && !(completed || allowed) ? 'disabled' : ''}
								onClick={() => (completed || allowed) && goToStep(index)}
							>
								<i className={`me-2 ${iconClass}`}></i>
								{child.props.title}
							</a>
						</li>
					)
				})}
			</ul>
			<div className="wizard-content">
				{React.cloneElement(children[step], {
					data: data[step],
					updateData: (newData, isCompleted, goToNext) =>
						updateData(step, newData, isCompleted, goToNext, onFinish),
					nextStep,
					prevStep,
					step,
					numSteps: data.length,
					isCompleted: isCompleted(step),
					enableSave,
					disableFinish
				})}
			</div>
		</div>
	)
}