import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import BaseDataInputs from '../BaseDataInputs'
import Wizard from 'components/Wizard/Wizard'
import AdquisicionDestinoVendedor from './AdquisicionDestinoVendedor'
import { TIPOS_ADQUISICIONES, TIPOS_DOCUMENTOS, FINALIDADES_COTA, CATEGORIAS_MAP, REVERSE_CATEGORIAS_MAP } from 'config/movimientoAnimales'
import Documentacion from './Documentacion'
import DefinicionAnimales from './DefinicionAnimales'
import { QRScan } from 'components/QRScan'
import { CATEGORIAS_CON_SEXO_FIJO } from 'config/stock'


export default function Adquisicion(props) {
	const { config, tarea, onClose, onGuardar, cerrarAlGuardar = true } = props

	const tiposAdquisiciones = TIPOS_ADQUISICIONES
	const tiposDocumentos = TIPOS_DOCUMENTOS
	const finalidadesCota = FINALIDADES_COTA
	const categoriasConSexoFijo = CATEGORIAS_CON_SEXO_FIJO

	const archivosOriginales = {}

	if (tarea && tarea.adquisicion) {
		['guia', 'cota', 'remision'].forEach(key => {
			if (tarea.adquisicion[key]) {
				archivosOriginales[key] = tarea.adquisicion[key]
			}
		})
	}

	const [showQR, setShowQR] = useState(false)
	const [listasQRWarningText, setListasQRWarningText] = useState('')
	const [wizardData, setWizardData] = useState([
		// paso 1
		{
			timestamp: Date.now(),
			tipo: tarea?.adquisicion.tipo || tiposAdquisiciones[0].value,
			propietarioDestinoId: tarea?.adquisicion.propietarioDestinoId || config.propietarios[0].id,
			establecimientoDestinoId: tarea?.adquisicion.establecimientoDestinoId || config.establecimientos[0].id,
			propietarioOrigenNombre: tarea?.adquisicion.propietarioOrigenNombre || '',
			propietarioOrigenTipoDocumento: tarea?.adquisicion.propietarioOrigenTipoDocumento || tiposDocumentos[0].value,
			propietarioOrigenDocumento: tarea?.adquisicion.propietarioOrigenDocumento || '',
			propietarioOrigenNacionalidad: tarea?.adquisicion.propietarioOrigenNacionalidad || '',
			establecimientoOrigenNombre: tarea?.adquisicion.establecimientoOrigenNombre || '',
			establecimientoOrigenCodigo: tarea?.adquisicion.establecimientoOrigenCodigo || ''
		},
		// paso 2
		{
			numeroGuia: tarea?.adquisicion.numeroGuia || '',
			numeroCota: tarea?.adquisicion.numeroCota || '',
			finalidadCota: tarea?.adquisicion.finalidadCota || finalidadesCota[0].value,
			archivos: [],
			archivosOriginales,
			guiaId: null,
			cotaId: null,
			remisionId: null
		},
		// paso 3
		{
			listas: [...tarea?.adquisicion?.listas ?? []]
		}
	])

	const [data, setData] = useState({
		id: tarea?.id,
		nombre: tarea?.nombre ?? '',
		fechaParaCuando: tarea?.fechaParaCuando.substring(0, 10) ?? '',
		asignadoId: tarea?.asignadoId ?? '',
		observacion: tarea?.observacion ?? '',
		adquisicion: {
			tipo: tarea?.adquisicion?.tipo ?? 'compra',
		}
	})

	const initialBaseInputData = tarea ? { nombre: tarea.nombre, fechaParaCuando: tarea.fechaParaCuando.substring(0, 10), asignadoId: tarea.asignadoId, observacion: tarea.observacion } : null
	const disableFinish = data.nombre.trim() === '' || data.fechaParaCuando === ''
	const enableSave = tarea?.nombre !== data.nombre || tarea?.fechaParaCuando.substring(0, 10) !== data.fechaParaCuando || tarea?.asignadoId !== data.asignadoId || tarea?.observacion !== data.observacion

	const saveBaseInputsData = (baseData) => {
		setData(prev => ({
			...prev,
			...baseData
		}))
	}

	const onWizardFinish = (completedWizardData) => {
		const adquisicion = {
			propietarioDestinoId: completedWizardData[0].propietarioDestinoId,
			establecimientoDestinoId: completedWizardData[0].establecimientoDestinoId,
			tipo: completedWizardData[0].tipo,
			finalidadCota: completedWizardData[1].finalidadCota,
			numeroCota: completedWizardData[1].numeroCota,
			numeroGuia: completedWizardData[1].numeroGuia,
			propietarioOrigenNombre: completedWizardData[0].propietarioOrigenNombre,
			propietarioOrigenTipoDocumento: completedWizardData[0].propietarioOrigenTipoDocumento,
			propietarioOrigenDocumento: completedWizardData[0].propietarioOrigenDocumento,
			propietarioOrigenNacionalidad: completedWizardData[0].propietarioOrigenNacionalidad,
			establecimientoOrigenNombre: completedWizardData[0].establecimientoOrigenNombre,
			establecimientoOrigenCodigo: completedWizardData[0].establecimientoOrigenCodigo,
			listas: []
		}
		
		const serverData = {
			tipo: 'adquisicion',
			nombre: data.nombre,
			fechaParaCuando: data.fechaParaCuando,
			asignadoId: data.asignadoId,
			observacion: data.observacion,
			adquisicion
		}

		if (tarea?.id) {
			serverData.id = tarea.id
		}

		if (Object.keys(completedWizardData[1].archivos).length) {
			serverData['archivos'] = completedWizardData[1].archivos
		}

		// Las listas
		const listasIds = []
		completedWizardData[2].listas.forEach((lista, i) => {
			listasIds.push(lista.id)
			serverData.adquisicion.listas.push(lista)
		})
		// Ve si hay que eliminar alguna
		const eliminarListasIds = tarea?.adquisicion?.listas.map(l => l.id).filter(id => !listasIds.includes(id))
		if (eliminarListasIds?.length) {
			serverData.adquisicion['eliminarListasIds'] = eliminarListasIds
		}

		onGuardar(serverData)
		if (cerrarAlGuardar) {
			onClose()
		}
	}

	/* QR stuff */
	const categoriasMap = CATEGORIAS_MAP
	const reverseCategoriasMap = REVERSE_CATEGORIAS_MAP

	const hideQRModal = () => {
		setShowQR(false)
	}

	const saveQRData = (data) => {
		const result = data.parsedResult

		const propietarioOrigen = config.propietarios.find(propietario => propietario.codigo.split('-')[0] === result.propietarioOrigenDocumento)
		const establecimientoOrigen = config.establecimientos.find(establecimiento => establecimiento.codigo === result.establecimientoOrigenCodigo)
		const propietarioDestino = config.propietarios.find(propietario => propietario.codigo.split('-')[0] === result.propietarioDestinoDocumento)
		const establecimientoDestino = config.propietarios.find(establecimiento => establecimiento.codigo.split('-')[0] === result.establecimientoDestinoCodigo)

		const copy = [...wizardData]
		copy[0] = {
			...copy[0],
			timestamp: Date.now(),
			propietarioOrigenId: propietarioOrigen?.id ?? '',
			establecimientoOrigenId: establecimientoOrigen?.id ?? ''
		}

		if (propietarioDestino && establecimientoDestino) {
			copy[0].propietarioDestinoId = propietarioDestino.id
			copy[0].establecimientoDestinoId = establecimientoDestino.id
		} else {
			copy[0].propietarioOrigenTipoDocumento = result?.propietarioOrigenTipoDocumento || 'ci'
			copy[0].propietarioOrigenDocumento = result.propietarioOrigenDocumento
			copy[0].propietarioOrigenNacionalidad = result?.propietarioOrigenNacionalidad || 'Paraguay'
			copy[0].establecimientoOrigenCodigo = result.establecimientoOrigenCodigo
		}

		copy[1] = {
			...copy[1],
			finalidadCota: result.finalidad.toLowerCase(),
			numeroGuia: result.numGuia,
			numeroCota: result.numCota
		}

		const listas = []
		let warnings = []
		result.categorias.forEach(categoria => {
			// Si son terneros o bubalinos, no podemos agregar la lista directamente ya que no sabemos a qué sexo corresponde
			if (categoria.id === 7 || categoria.id === 9) {
				const cat = config.categorias.find(c => c.id === categoria.id)
				warnings.push([cat.nombre, categoria.cantidad])
			} else {
				listas.push({
					categoriaId: categoria.id,
					sexo: categoriasConSexoFijo[categoria.id],
					cantidad: categoria.cantidad,
					adquisicionId: '',
					conTareaIdentificacion: false,
					fechaTareaIdentificacion: null,
					animales: []
				})
			}
		})

		let warningText = ''
		if (warnings.length) {
			warningText = 'La cota incluye una o más categorías que no especifican el sexo de los animales. No se pudieron(n) agregar la(s) siguiente(s) lista(s): '
			warnings.forEach((warning, i) => {
				warningText += i ? ', ' : `[${warning[0]}, ${warning[1]} animales]`
			})
		}
		setListasQRWarningText(warningText)

		copy[2].listas = listas

		setWizardData(copy)
	}

	const parseQRDataFunction = (data) => {
		const dataParts = data.text.split('. ')

		const categorias = []
		for (let i = 8; i < dataParts.length - 1; i++) {
			let categoria = dataParts[i].split(' ')[0]
			if (categoria.includes(':')) {
				categoria = categoria.split(':')[1]
			} else {
				categoria = categoria.split(' ')[0]
			}

			let cantidad = dataParts[i].split(' ')[1]
			categorias.push({
				id: categoriasMap[categoria],
				cantidad: cantidad
			})
		}

		// El vendedor
		const propietarioOrigen = dataParts[3].substring(3)
		let propietarioOrigenTipoDocumento = 'ci'
		let propietarioOrigenDocumento = propietarioOrigen
		let propietarioOrigenNacionalidad = 'Paraguay'
		if (propietarioOrigen.includes(',')) {
			const propietarioOrigenParts = propietarioOrigen.split(',')
			propietarioOrigenDocumento = propietarioOrigenParts[0]
			propietarioOrigenTipoDocumento = propietarioOrigenParts[1].toLowerCase()
			propietarioOrigenNacionalidad = propietarioOrigenParts[2].charAt(0) + propietarioOrigenParts[2].slice(1).toLowerCase()
		}

		// El propietario que ya debería existir en el sistema
		const propietarioDestino = dataParts[5].substring(3)
		let propietarioDestinoTipoDocumento = 'ci'
		let propietarioDestinoNacionalidad = 'Paraguay'
		let propietarioDestinoDocumento = propietarioDestino
		if (propietarioDestinoDocumento.includes(',')) {
			const propietarioDestinoParts = propietarioOrigen.split(',')
			propietarioDestinoDocumento = propietarioDestinoParts[0]
			propietarioDestinoTipoDocumento = propietarioDestinoParts[1].toLowerCase()
			propietarioDestinoNacionalidad = propietarioDestinoParts[2].charAt(0) + propietarioDestinoParts[2].slice(1).toLowerCase()

			//propietarioDestinoDocumento = propietarioDestino.split(',')[0]
		}

		let fechaCota = ''
		if (dataParts[0].includes('/')) {
			fechaCota = dataParts[0].split('/').reverse().join('-')
		} else {
			const fechaCotaDate = new Date(dataParts[0])
			fechaCota = fechaCotaDate.toISOString().substring(0, 10)
		}

		data.parsedResult = {
			fechaCota: fechaCota,
			numCota: dataParts[1].substring(5),
			numGuia: dataParts[2].substring(5),
			propietarioOrigenTipoDocumento: propietarioOrigenTipoDocumento,
			propietarioOrigenDocumento: propietarioOrigenDocumento,
			propietarioOrigenNacionalidad: propietarioOrigenNacionalidad,
			establecimientoOrigenCodigo: dataParts[4].substring(3),
			propietarioDestinoTipoDocumento: propietarioDestinoTipoDocumento,
			propietarioDestinoDocumento: propietarioDestinoDocumento,
			propietarioDestinoNacionalidad: propietarioDestinoNacionalidad,
			establecimientoDestinoCodigo: dataParts[6].substring(3),
			finalidad: dataParts[7].substring(2),
			categorias: categorias
		}

		return data
	}

	const showQRResultJSX = ({ parsedResult }) => {

		return (
			<>
				<h4 style={{ borderBottom: '1px dotted black' }}>Datos leídos</h4>
				<Table size="sm">
					<tbody>
						<tr>
							<th>Fecha Cota</th>
							<td>{parsedResult.fechaCota}</td>
						</tr>
						<tr>
							<th>Número Cota</th>
							<td>{parsedResult.numCota}</td>
						</tr>
						<tr>
							<th>Número Guía</th>
							<td>{parsedResult.numGuia}</td>
						</tr>
						<tr>
							<th>Propietario origen código</th>
							<td>{parsedResult.propietarioOrigenDocumento}</td>
						</tr>
						<tr>
							<th>Establecimiento origen código</th>
							<td>{parsedResult.establecimientoOrigenCodigo}</td>
						</tr>
						<tr>
							<th>Propietario destino código</th>
							<td>{parsedResult.propietarioDestinoDocumento}</td>
						</tr>
						<tr>
							<th>Establecimiento destino código</th>
							<td>{parsedResult.establecimientoDestinoCodigo}</td>
						</tr>
						<tr>
							<th>Finalidad</th>
							<td>{parsedResult.finalidad}</td>
						</tr>
						<tr>
							<th>Categorías</th>
							<td>{parsedResult.categorias.map(categoria => <div key={`qr-${categoria.id}`}><strong>{reverseCategoriasMap[categoria.id]}:</strong> {categoria.cantidad}</div>)}</td>
						</tr>
					</tbody>
				</Table>
			</>
		)
	}
	/* FIN QR Stuff */

	return (
		<Container>
			<BaseDataInputs
				initialData={initialBaseInputData}
				usuarios={config.usuarios}
				saveData={saveBaseInputsData}
			/>
			<div className="py-2 ps-2" style={{ backgroundColor: '#ebeaea' }}>
				<Button onClick={(e) => setShowQR(true)} variant="primary">
					Usar Código QR <i className="fa-solid fa-qrcode"></i>
				</Button>
			</div>
			<Wizard
				key={`wizard-${wizardData[0].timestamp}`}
				initialData={[...wizardData]}
				enableSave={enableSave}
				disableFinish={disableFinish}
				onFinish={onWizardFinish}
				alreadyCompleted={tarea ? [0, 1, 2] : []}
			>
				<AdquisicionDestinoVendedor title={'Destino y vendedor'} config={config} />
				<Documentacion title={'Documentación'} config={config} />
				<DefinicionAnimales title={'Definición de animales'} config={config} warningText={listasQRWarningText} />
			</Wizard>
			<QRScan
				show={showQR}
				hide={hideQRModal}
				save={saveQRData}
				parseFn={parseQRDataFunction}
				showResultsJSX={showQRResultJSX}
			/>
		</Container>
	)
}