import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { TIPOS_IDENTIFICACION } from 'config/generales'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'

export default function IngresoIndividual(props) {
	const { saveDisabled, onClose, onSave, cantidadAnimales, categoriasDisponibles } = props

	const [tipoIdentificacion, setTipoIdentificacion] = useState('caravana')
	const tiposIdentificacion = TIPOS_IDENTIFICACION

	const [numAct, setNumAct] = useState(0)
	const animalesInitialState = [{
		numIdentificador: 0,
		carimbo: '',
		color: '',
		raza: '',
		categoriaId: '',
		sexo: '',
		pesoInicial: ''
	}]
	const [animales, setAnimales] = useState(animalesInitialState)

	useEffect(() => {
		if (cantidadAnimales > 0) {
			if (cantidadAnimales - 1 > numAct) {
				const arr = []
				for (let i = 0; i < cantidadAnimales; i++) {
					let item = {}
					if (i < animales.length) {
						item = animales[i]
					} else {
						item = {
							numIdentificador: 0,
							carimbo: '',
							color: '',
							raza: '',
							categoriaId: '',
							sexo: '',
							pesoInicial: ''
						}
					}
					arr.push(item)
				}

				setAnimales(arr)
			} else {
				const animalesCopy = []
				for (let i = 0; i < cantidadAnimales; i++) {
					animalesCopy.push(animales[i])
				}
				setNumAct(cantidadAnimales - 1, setAnimales(animalesCopy))
			}
		} else {
			setNumAct(0)
		}
	}, [cantidadAnimales])

	const handleChangeAnimal = (valor, cual, index = numAct) => {
		const animalesCopy = [...animales]
		animalesCopy[index][cual] = valor
		setAnimales(prev => animalesCopy)
	}

	const onChangeCategoria = (categoriaData) => {
		const animalesCopy = [...animales]
		animalesCopy[numAct]['categoriaId'] = categoriaData.categoriaId
		animalesCopy[numAct]['sexo'] = categoriaData.sexo
		setAnimales(prev => animalesCopy)
	}

	const avanzarNumAct = () => {
		// Si no se definió todavía un número identificador del siguiente animal, entonces inicializa con uno más que el actual
		if (!animales[numAct + 1].numIdentificador) {
			const siguienteNum = isNaN(parseInt(animales[numAct].numIdentificador)) ? '' : Number(animales[numAct].numIdentificador) + 1
			handleChangeAnimal(siguienteNum, 'numIdentificador', numAct + 1)
			handleChangeAnimal(animales[numAct].carimbo, 'carimbo', numAct + 1)
			handleChangeAnimal(animales[numAct].color, 'color', numAct + 1)
			handleChangeAnimal(animales[numAct].raza, 'raza', numAct + 1)
			handleChangeAnimal(animales[numAct].categoriaId, 'categoriaId', numAct + 1)
			handleChangeAnimal(animales[numAct].sexo, 'sexo', numAct + 1)
		}

		setNumAct(prev => prev + 1)
	}

	const handleClose = () => {
		onClose()
	}

	const handleSave = () => {
		const animalesData = {
			conIdentificacion: true,
			tipoIdentificacion,
			animales
		}

		onSave(animalesData, 'individual')
	}

	return (
		!isNaN(cantidadAnimales) && cantidadAnimales > 0 ? (
			<>
				<Row className="pt-3">
					<Col>
						<Form.Group className="mb-3" controlId="preexistente-crear-individual-identificador">
							<Form.Check
								type="checkbox"
								label="Con identificador"
								checked={true}
								disabled
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm="6" lg="4">
						<Form.Group className="mb-3" controlId="preexistente-crear-individual-tipoIdentificador">
							<Form.Label className="mb-1">Tipo de identificador</Form.Label>
							<Form.Select
								size="sm"
								onChange={(e) => setTipoIdentificacion(e.target.value)}
								value={tipoIdentificacion}
							>
								{tiposIdentificacion.map((item, index) => {
									return (
										<option value={item.toLowerCase()} key={`tipo-identificacion-${index}`}>
											{item}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						Animal {numAct + 1}/{cantidadAnimales}
					</Col>
				</Row>
				<Row>
					<Col sm="6" lg="4">
						<div className="border p-3">
							<Form.Group className="mb-3" controlId="preexistente-crear-individual-numIdentificador">
								<Form.Label className="mb-1">Núm. Identificador</Form.Label>
								<Form.Control
									type="text"
									size="sm"
									value={animales[numAct].numIdentificador}
									onChange={(e) => handleChangeAnimal(e.target.value, 'numIdentificador')}
								/>
							</Form.Group>
							<CategoriaAnimalesSelects
								opciones={categoriasDisponibles}
								initialCategoriaId={'1'}
								seleccionados={animales[numAct]}
								setSeleccionados={(param) => true}
								onSave={onChangeCategoria}
							/>
							<Form.Group className="mb-2" controlId="preexistente-crear-individual-carimbo">
								<Form.Label className="mb-1">Carimbo</Form.Label>
								<Form.Control
									size="sm"
									type="number"
									value={animales[numAct].carimbo}
									onChange={(e) => handleChangeAnimal(e.target.value, 'carimbo')}
								/>
							</Form.Group>
							<Form.Group className="mb-2" controlId="preexistente-crear-individual-color">
								<Form.Label className="mb-1">Color</Form.Label>
								<Form.Control
									size="sm"
									value={animales[numAct].color}
									onChange={(e) => handleChangeAnimal(e.target.value, 'color')}
								/>
							</Form.Group>
							<Form.Group className="mb-2" controlId="preexistente-crear-individual-raza">
								<Form.Label className="mb-1">Raza</Form.Label>
								<Form.Control
									size="sm"
									value={animales[numAct].raza}
									onChange={(e) => handleChangeAnimal(e.target.value, 'raza')}
								/>
							</Form.Group>
							<Form.Group className="mb-2 col-12 col-sm-4 mb-3" controlId="preexistente-crear-individual-pesoInicial">
								<Form.Label className="mb-1">Peso inicial</Form.Label>
								<Form.Control
									size="sm"
									value={animales[numAct].pesoInicial}
									type="number"
									onChange={(e) => handleChangeAnimal(e.target.value, 'pesoInicial')}
								/>
							</Form.Group>
						</div>
					</Col>
				</Row>
				<Row className="mt-4 mb-3">
					<Col sm="6" lg="4" className="d-flex justify-content-around">
						<Button
							variant="success"
							onClick={() => setNumAct(prev => prev - 1)}
							disabled={numAct === 0}
						>
							<i className="fa fa-caret-left"></i> ANTERIOR
						</Button>
						<Button
							variant="success"
							onClick={avanzarNumAct}
							disabled={numAct === cantidadAnimales - 1}
						>
							SIGUIENTE <i className="fa fa-caret-right"></i>
						</Button>
					</Col>
				</Row>
				<Row className="mt-4 mb-3">
					<Col className="d-flex justify-content-end">
						<Button
							variant="secondary"
							className="me-2"
							onClick={handleClose}
						>
							Cancelar
						</Button>
						<Button
							variant="primary"
							onClick={handleSave}
							disabled={saveDisabled || numAct !== cantidadAnimales - 1}
						>
							Ingresar animales
						</Button>
					</Col>
				</Row>
			</>
		) : (
			<Alert variant="warning">Se debe especificar el número de animales</Alert>
		)

	)
}