import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import BaseDataInputs from '../BaseDataInputs'
import { ModalSeleccionAnimales } from 'components/SeleccionAnimales'
import { CATEGORIA_TOROS } from 'config/generales'
import { ModalSeleccionEditableList } from 'components/EditableList'


export default function Servicio(props) {
	const { config, tarea, onClose, onGuardar } = props

	const tiposServicio = [
		{
			label: 'Monta natural',
			value: 'monta-natural'
		},
		{
			label: 'IATF',
			value: 'iatf'
		},
		{
			label: 'IA',
			value: 'ia'
		}
	]

	const defaultListaHembras = {
		nombre: 'Hembras',
		tipo: 'servicio',
		animales: []
	}
	const defaultListaToros = {
		nombre: 'Toros',
		tipo: 'servicio',
		animales: []
	}

	let listasInicializadas = []
	if (tarea) {
		if (tarea?.servicio.tipo === 'monta-natural') {
			listasInicializadas = [...tarea.listas]
		} else {
			listasInicializadas = [...tarea.listas, { ...defaultListaToros }]
		}
	} else {
		listasInicializadas = [{ ...defaultListaHembras }, { ...defaultListaToros }]
	}

	const [data, setData] = useState({
		id: tarea?.id,
		nombre: tarea?.nombre ?? '',
		fechaParaCuando: tarea?.fechaParaCuando ?? '',
		asignadoId: tarea?.asignadoId ?? '',
		observacion: tarea?.observacion ?? '',
		listas: listasInicializadas,
	})
	const [tipo, setTipo] = useState(tarea?.servicio.tipo || tiposServicio[0].value)
	const [seleccionPopupData, setSeleccionPopupData] = useState({
		mostrar: false, // si mostrar o no el popup
		lista: null, // la lista activa
		index: -1 // el índice de la lista, utilizado para guardar eventuales cambios
	})
	const [listaConfigToros, setListaConfigToros] = useState(tarea ? (tarea.servicio.tipo !== 'monta-natural' ? tarea.servicio.configuracionToros.map(e => ({ id: e.id, value: e.nombre })) : []) : [])
	const [showSeleccionConfigToros, setShowSeleccionConfigToros] = useState(false)

	const listasUtilizables = tipo === 'monta-natural' ? data.listas : [data.listas[0]]
	let listasCompletadas = true
	listasUtilizables.forEach(lista => {
		if (lista.animales.length === 0) {
			listasCompletadas = false
		}
	})

	const yaSeleccionadosIds = data.listas?.flatMap((lista) => lista.animales.map((animal) => animal.id)) || []

	const initialBaseInputData = tarea ? { nombre: tarea.nombre, fechaParaCuando: tarea.fechaParaCuando, asignadoId: tarea.asignadoId, observacion: tarea.observacion } : null
	const saveDisabled = data.nombre.trim() === '' || data.fechaParaCuando === '' || !listasCompletadas || (tipo !== 'monta-natural' && listaConfigToros.filter((s) => s.value !== '').length === 0)

	const saveBaseInputsData = (baseData) => {
		setData(prev => ({
			...prev,
			...baseData
		}))
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'servicio',
			nombre: data.nombre,
			fechaParaCuando: data.fechaParaCuando,
			asignadoId: data.asignadoId,
			observacion: data.observacion,
			listas: [
				{
					nombre: data.listas[0].nombre,
					tipo: 'servicio',
					cantidadAnimales: data.listas[0].animales.length,
					animalesIds: data.listas[0].animales.map(a => parseInt(a.id))
				}
			],
			servicio: {
				tipo
			}
		}

		// Si es monta natural, se debe haber elegido otra lista de toros
		if (tipo === 'monta-natural') {
			serverData.listas.push({
				nombre: 'Toros',
				tipo: 'servicio',
				cantidadAnimales: data.listas[1].animales.length,
				animalesIds: data.listas[1].animales.map(a => parseInt(a.id))
			})
		} else { // caso contrario, una array de nombres de toros
			serverData.servicio.configuracionToros = listaConfigToros.filter((s) => s.value !== '').map(elem => isNaN(elem.id) && elem.id.startsWith('temp') ? { nombre: elem.value.trim() } : { id: elem.id, nombre: elem.value })
		}

		if (tarea) {
			serverData.id = tarea.id

			listasUtilizables.forEach((lista, i) => {
				if (lista.id) {
					serverData.listas[i].id = lista.id
				}

				const yaExistentesIds = tarea.listas[i]?.animales.map((animal) => parseInt(animal.id)) || []
				const seleccionadosIds = lista.animales.map((animal) => parseInt(animal.id))

				const agregarIds = seleccionadosIds.filter((id) => !yaExistentesIds.includes(id))
				const eliminarIds = yaExistentesIds.filter((id) => !seleccionadosIds.includes(id))

				if (agregarIds.length > 0) {
					serverData.listas[i].agregarAnimalesIds = agregarIds
				}

				if (eliminarIds.length > 0) {
					serverData.listas[i].eliminarAnimalesIds = eliminarIds
				}

				// Al editar, el server espera agregarIds y/o eliminarIds, ya no se necesita animalesIds
				if (lista.id) {
					delete serverData.listas[i].animalesIds
				}
			})

			if (tarea.servicio.tipo === 'iatf' || tarea.servicio.tipo === 'ia') {
				const configuracionTorosIds = listaConfigToros.map(e => e.id)
				const eliminarConfiguracionTorosIds = tarea.servicio.configuracionToros.filter(e => !configuracionTorosIds.includes(e.id)).map(e => e.id)
				if (eliminarConfiguracionTorosIds.length > 0) {
					serverData.servicio.eliminarConfiguracionTorosIds = eliminarConfiguracionTorosIds
				}
			}
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	/* Inicio manejo de popup de selección de animales */
	const handleShowSeleccionAnimales = (lista, i) => {
		const listaCopy = { ...lista }

		// si es la lista de toros, le agrega los filtros iniciales
		if (i === 1) {
			listaCopy['filtrosIniciales'] = {
				categoriaId: CATEGORIA_TOROS,
				sexo: 'macho'
			}
		}
		const popupData = {
			mostrar: true,
			lista: listaCopy,
			index: i
		}

		setSeleccionPopupData(popupData)
	}

	const handleClosePopup = () => {
		setSeleccionPopupData({
			mostrar: false,
			lista: null,
			index: -1
		})
	}

	const confirmarSeleccion = (seleccionados) => {
		const animales = seleccionados.todos
		const lista = { ...seleccionPopupData.lista }
		lista.animales = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})

		const listas = [...data.listas]
		listas[seleccionPopupData.index] = lista

		setData(prev => ({ ...prev, listas }))

		handleClosePopup()
	}
	/* Fin manejo de popup de selección de animales */

	const handleShowSeleccionConfigToros = () => {
		setShowSeleccionConfigToros(true)
	}

	const handleCloseSeleccionConfigToros = () => {
		setShowSeleccionConfigToros(false)
	}

	const handleConfirmarSeleccionConfigToros = (lista) => {
		setListaConfigToros(lista)
		setShowSeleccionConfigToros(false)
	}

	return (
		<Container>
			<BaseDataInputs
				initialData={initialBaseInputData}
				usuarios={config.usuarios}
				saveData={saveBaseInputsData}
			/>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col>
							<h4 className="mt-2 mb-0"><strong>Tipo</strong></h4>
							<div className="d-flex mb-3" style={{ gap: 20 }}>
								<Form.Group>
									<Form.Check
										id="check-tipo-monta"
										type='radio'
										inline
										checked={tipo === 'monta-natural'}
										onChange={e => setTipo('monta-natural')}
										className="me-2"
									/>
									<Form.Label htmlFor="check-tipo-monta" className="mb-0">
										Monta natural
									</Form.Label>
								</Form.Group>
								<Form.Group>
									<Form.Check
										id="check-tipo-iatf"
										type='radio'
										inline
										checked={tipo === 'iatf'}
										onChange={e => setTipo('iatf')}
										className="me-2"
									/>
									<Form.Label htmlFor="check-tipo-iatf" className="mb-0">
										IATF
									</Form.Label>
								</Form.Group>
								<Form.Group>
									<Form.Check
										id="check-tipo-ia"
										type='radio'
										inline
										checked={tipo === 'ia'}
										onChange={e => setTipo('ia')}
										className="me-2"
									/>
									<Form.Label htmlFor="check-tipo-ia" className="mb-0">
										IA
									</Form.Label>
								</Form.Group>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table className="lista-seleccion-animales table-hover pt-0">
								<thead>
									<tr>
										<th>Cantidad</th>
										<th>Nombre</th>
										<th>Acciones</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="servicio-lista-cantidad">
											{data.listas[0].animales.length}
										</td>
										<td className="servicio-lista-categoria">
											{data.listas[0].nombre}
										</td>
										<td className="acciones">
											<i className="fas fa-rectangle-list salida-lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionAnimales(data.listas[0], 0)} />
										</td>
										<td className="servicio-lista-completa">
											{data.listas[0].animales.length > 0 ? <i className="fas fa-check-circle text-success" title="Lista completa" /> : ''}
										</td>
									</tr>
									{tipo === 'monta-natural' ? (
										<tr>
											<td className="servicio-lista-cantidad">
												{data.listas[1].animales.length}
											</td>
											<td className="servicio-lista-categoria">
												{data.listas[1].nombre}
											</td>
											<td className="acciones">
												<i className="fas fa-rectangle-list salida-lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionAnimales(data.listas[1], 1)} />
											</td>
											<td className="servicio-lista-completa">
												{data.listas[1].animales.length > 0 ? <i className="fas fa-check-circle text-success" title="Lista completa" /> : ''}
											</td>
										</tr>
									) : (
										<tr>
											<td className="servicio-lista-cantidad">
												{listaConfigToros.length}
											</td>
											<td className="servicio-lista-categoria">
												Toros
											</td>
											<td className="acciones">
												<i className="fas fa-rectangle-list salida-lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionConfigToros()} />
											</td>
											<td className="servicio-lista-completa">
												{listaConfigToros.length > 0 ? <i className="fas fa-check-circle text-success" title="Lista completa" /> : ''}
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={saveDisabled}
							>
								{tarea ? 'Editar' : 'Crear'} tarea
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			{seleccionPopupData.mostrar && (
				<ModalSeleccionAnimales
					config={config}
					titulo={`Animales para la lista de ${seleccionPopupData.lista.nombre}`}
					datosIniciales={{
						seleccionados: seleccionPopupData.lista.animales,
						filtrosIniciales: seleccionPopupData.lista.filtrosIniciales,
						noSeleccionablesIds: yaSeleccionadosIds
					}}
					onClose={handleClosePopup}
					onConfirmar={confirmarSeleccion}
				/>
			)}
			{showSeleccionConfigToros && (
				<ModalSeleccionEditableList
					listaOriginal={listaConfigToros}
					tituloModal={'Selección de toros'}
					titulo={'Lista de toros'}
					onClose={handleCloseSeleccionConfigToros}
					onConfirmar={handleConfirmarSeleccionConfigToros}
				/>
			)}
		</Container>
	)
}