import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import useAnimales from 'hooks/useAnimales'
import { SeleccionAnimales, MostrarListaAnimales } from 'components/SeleccionAnimales'

export default function DefinirAnimales(props) {
	const { config, animalesSeleccionados, onGuardar } = props

	const { loading, error, cambiarCategoria, cambiarFiltro, animales, seleccionados, seleccionarAnimal,
		seleccionarMultiplesAnimales, deseleccionarAnimal, pagina, cambiarPagina, deACuantos, cambiarDeACuantos, numResultados } = useAnimales({ seleccionados: animalesSeleccionados })

	const siguienteDisabled = seleccionados.todos.length === 0

	const handleGuardar = () => {
		onGuardar(seleccionados.todos)
	}

	return (
		<>
			<Row>
				<Col className="text-end">
					<Button
						variant="primary"
						className="me-2"
						onClick={handleGuardar}
						disabled={siguienteDisabled}
					>
						Cargar pariciones <i className="fa fa-caret-right"></i>
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md="6">
					<SeleccionAnimales
						config={config}
						loading={loading}
						animales={animales}
						cambiarFiltro={cambiarFiltro}
						cambiarCategoria={cambiarCategoria}
						seleccionados={seleccionados}
						seleccionarAnimal={seleccionarAnimal}
						seleccionarMultiplesAnimales={seleccionarMultiplesAnimales}
						pagina={pagina}
						deACuantos={deACuantos}
						cambiarDeACuantos={cambiarDeACuantos}
						cambiarPagina={cambiarPagina}
						numResultados={numResultados}
					/>
				</Col>
				<Col md="6">
					<MostrarListaAnimales seleccionados={seleccionados} deseleccionarAnimal={deseleccionarAnimal} orderField={false} />
				</Col>
			</Row>
		</>
	)
}