import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Alert } from 'react-bootstrap'
import DefinirAnimales from './DefinirAnimales'
import DefinirConsumos from './DefinirConsumos'
import { ScrollTop } from 'components/ScrollTop'


export default function Consumo(props) {
	const { config, onClose, onGuardar, containerRef } = props

	const [seleccionados, setSeleccionados] = useState([])

	const [mostrarConsumos, setMostrarConsumos] = useState(false)
	/*
	 * Array de elementos con los siguientes campos:
	 * id: animalId,
	 * fecha,
	 * kilos
	 */
	const [consumos, setConsumos] = useState([])

	const datosInicialesAnimal = {
		fecha: '',
		kilos: ''
	}

	const verSiCompleto = () => {
		let completo = true

		consumos.forEach((consumo, i) => {
			if (!verSiCompletadoDatosConsumo(consumo)) {
				completo = false
			}
		})

		return completo
	}

	const verSiCompletadoDatosConsumo = (consumo) => {
		let completado = false
		if (consumo.fecha !== '' && consumo.kilos !== '') {
			completado = true
		}

		return completado
	}

	const completo = mostrarConsumos && verSiCompleto()

	const handleDefinirConsumos = (animales) => {
		const animalesConCategoria = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})
		setSeleccionados(animalesConCategoria)

		setConsumos(animalesConCategoria.map(animal => ({
			...datosInicialesAnimal,
			id: animal.id
		})))

		setMostrarConsumos(true)
	}

	const changeConsumoData = (valor, cual, indice) => {
		const consumosCopy = [...consumos]
		consumosCopy[indice][cual] = valor

		setConsumos(consumosCopy)
	}

	const handleDefinirConsumosAtras = () => {
		setMostrarConsumos(false)
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'consumo',
			sinPlanificacion: true,
			listas: [
				{
					nombre: 'animales',
					tipo: 'consumo',
					cantidadAnimales: consumos.length,
					animalesIds: consumos.map(consumo => consumo.id),
					marcarProcesados: consumos
				}
			]
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="card-bg-gray">
				<Card.Header as='h5'>Evento de Consumo</Card.Header>
			</Card>
			{!mostrarConsumos && (
				<Row>
					<Col>
						<Alert variant="info">Primero seleccionar los animales que se hayan consumos, luego proceder a la carga del evento de consumo de cada uno.</Alert>
					</Col>
				</Row>
			)}
			<Card className="mt-3">
				<Card.Body>
					{mostrarConsumos ? (
						<DefinirConsumos
							animales={seleccionados}
							consumos={consumos}
							changeConsumoData={changeConsumoData}
							verSiCompletadoDatosConsumo={verSiCompletadoDatosConsumo}
							onAtras={handleDefinirConsumosAtras}
						/>
					) : (
						<DefinirAnimales
							config={config}
							animalesSeleccionados={seleccionados}
							onGuardar={handleDefinirConsumos}
						/>
					)}
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={!completo}
							>
								Guardar
							</Button>
						</Col>
					</Row>
					<ScrollTop containerRef={containerRef} showFrom={1000} top={210} />
				</Card.Body>
			</Card>

		</Container>
	)
}