import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import WizardStepFooter from 'components/Wizard/WizardStepFooter'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'
import { DEFAULT_CATEGORIA_ID, DEFAULT_SEXO } from 'config/seleccionAnimales'
import { toast } from 'react-toastify'
import { ModalSeleccionAnimales } from 'components/SeleccionAnimales'

export default function DefinicionAnimales(props) {
	const { config, data: originalData = {}, isCompleted: isOriginallyCompleted, updateData, step, numSteps, prevStep, enableSave, disableFinish, warningText } = props

	const [showWarning, setShowWarning] = useState(warningText !== '')

	const defaultCategoriaId = DEFAULT_CATEGORIA_ID
	const defaultSexo = DEFAULT_SEXO

	/*
	 * data.listas = [
		{
			categoriaId: int,
			sexo: str,
			animales: []
		}
	 ]
	*/
	const [data, setData] = useState({ ...originalData })
	const [seleccion, setSeleccion] = useState({
		categoriaId: defaultCategoriaId,
		sexo: defaultSexo
	})
	const [seleccionPopupData, setSeleccionPopupData] = useState({
		mostrar: false, // si mostrar o no el popup
		lista: null, // la lista activa
		index: -1 // el índice de la lista, utilizado para guardar eventuales cambios
	})

	const seleccionCategoria = config.categorias.find(categoria => Number(categoria.id) === Number(seleccionPopupData.lista?.categoriaId))

	const yaSeleccionadosIds = data.listas?.flatMap((lista) => lista.animales.map((animal) => animal.id)) || []

	const verSiHayCambios = () => {
		let cambios = false

		let verificarCambios = false
		const listasOriginalesIds = []
		originalData?.listas.forEach(l => {
			if (l.id) {
				verificarCambios = true
				listasOriginalesIds.push(l.id)
			}
		})


		if (verificarCambios) {
			const listasIds = []
			for (let l of data.listas) {
				if (l.id) {
					listasIds.push(l.id)
				} else {
					// si no hay id, entonces es una lista nueva
					cambios = true
					break
				}
			}

			if (listasIds.length !== listasOriginalesIds.length) {
				cambios = true
			} else {
				// queda verificar si todos los animales son los mismos
				if (!cambios) {
					for (let lista of data.listas) {
						const listaOriginal = originalData.listas.find((l) => l.id === lista.id)
						if (Number(listaOriginal.cantidadAnimales) !== Number(lista.cantidad)) {
							cambios = true
						} else {
							const listaOriginalAnimalesIds = listaOriginal.animales.map((a) => a.id) ?? []
							const seleccionadosIds = lista.animales.map((a) => a.id)

							seleccionadosIds.forEach((id) => {
								if (!listaOriginalAnimalesIds.includes(id)) {
									cambios = true
								}
							})

							if (cambios) {
								break
							}
						}
					}
				}
			}
		} else {
			cambios = true
		}

		return cambios
	}

	const verSiCompleto = () => {
		let completo = true

		if (data.listas.length > 0) {
			data.listas.forEach(lista => {
				if (Number(lista.cantidad) !== lista.animales.length) {
					completo = false
				}
			})
		} else {
			completo = false
		}

		return completo
	}

	const hayModificaciones = verSiHayCambios()
	const isCompleted = verSiCompleto()

	const onSaveSelectCategoria = (categoriaData) => {
		setSeleccion(categoriaData)
	}

	const handleFooterAction = (direction, goToNext = false) => {
		if (direction === 'back') {
			prevStep()
		} else {
			updateData(data, isCompleted, goToNext)
		}
	}

	const handleAgregarLista = () => {
		// Verifica si no hay ya una lista igual
		const existente = data.listas.find(lista => {
			return Number(lista.categoriaId) === Number(seleccion.categoriaId)
				&& lista.sexo === seleccion.sexo
		})

		if (!existente) {
			const listas = [...data.listas]
			const nueva = {
				categoriaId: seleccion.categoriaId,
				sexo: seleccion.sexo,
				cantidad: 1,
				animales: []
			}

			listas.push(nueva)

			setData(prev => ({ ...prev, listas }))
		} else {
			toast.warning('Ya existe una lista con estas características. Todas deben ser distintas.')
		}
	}

	const handleChangeCantidad = (cantidad, listaIndex) => {
		const listas = [...data.listas]
		listas[listaIndex].cantidad = cantidad

		setData(prev => ({ ...prev, listas }))
	}

	const handleRemoveLista = (listaIndex) => {
		const listas = data.listas.filter((lista, i) => i !== listaIndex)

		setData(prev => ({ ...prev, listas }))
	}

	/* Inicio manejo de popup de selección de animales */
	const handleShowSeleccionAnimales = (lista, i) => {
		const popupData = {
			mostrar: true,
			lista: { ...lista },
			index: i
		}

		setSeleccionPopupData(popupData)
	}

	const handleClosePopup = () => {
		setSeleccionPopupData({
			mostrar: false,
			lista: null,
			index: -1
		})
	}

	const confirmarSeleccion = (seleccionados) => {
		const animales = seleccionados.todos
		const lista = { ...seleccionPopupData.lista }
		lista.animales = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})

		const listas = [...data.listas]
		listas[seleccionPopupData.index] = lista

		setData(prev => ({ ...prev, listas }))

		handleClosePopup()
	}
	/* Fin manejo de popup de selección de animales */

	return (
		<>
			<Card>
				<Card.Body>
					<Row>
						<CategoriaAnimalesSelects
							opciones={config.categorias}
							initialCategoriaId={seleccion.categoriaId}
							initialSexo={seleccion.sexo}
							onSave={onSaveSelectCategoria}
							inline
						/>
					</Row>
					<Row>
						<Col>
							<Button
								className="my-1"
								onClick={() => handleAgregarLista()}
							>
								<i className="fa fa-plus-circle"></i> Agregar lista
							</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							{showWarning ? (
								<Alert
									variant="danger"
									className="my-2"
									dismissible
									onClose={() => setShowWarning(false)}
								>
									<i className="fa fa-exclamation-triangle me-2"></i> {warningText}
								</Alert>
							) : ''}
							<h4 className="mb-0 mt-3">Listas de animales</h4>
							{data.listas.length ? (
								<Table className="lista-seleccion-animales salida table-hover pt-0">
									<thead>
										<tr>
											<th>Cantidad</th>
											<th>Categoría</th>
											<th>Sexo</th>
											<th>Acciones</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{data.listas.map((lista, i) => {
											const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(lista.categoriaId))
											const cantidad = Number(lista.cantidad)

											let completaIcon = ''
											if (cantidad) {
												if (cantidad === lista.animales.length) {
													completaIcon = <i className="fas fa-check-circle text-success" title="Lista completa" />
												} else if (cantidad < lista.animales.length) {
													completaIcon = <i className="fas fa-triangle-exclamation text-warning" title="Hay más animales seleccionados de los especificados" />
												}
											}

											return (
												<tr key={`lista-${i}`} className="definicion-animales-lista">
													<td className="cantidad">
														<Form.Control
															value={lista.cantidad}
															onChange={(e) => handleChangeCantidad(e.target.value, i)}
															type="number"
															size="sm"
														/>
													</td>
													<td className="categoria">
														{categoria.nombre}
													</td>
													<td className="sexo">
														{lista.sexo}
													</td>
													<td className="acciones">
														<i className="fas fa-trash-alt lista-eliminar" title="Eliminar lista" onClick={() => handleRemoveLista(i)} />
														<i className="fas fa-rectangle-list lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionAnimales(lista, i)} />
													</td>
													<td className="lista-completa">
														<span className="me-1">{`${lista.animales.length} / ${lista.cantidad}`}</span>
														{completaIcon}
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							) : (
								<Alert variant="warning">Se debe tener al menos una lista de animales.</Alert>
							)}
						</Col>
					</Row>
				</Card.Body>
				<WizardStepFooter
					step={step}
					numSteps={numSteps}
					handleAction={handleFooterAction}
					alreadyCompleted={isOriginallyCompleted}
					completed={isCompleted}
					modified={hayModificaciones}
					enableSave={enableSave}
					disableFinish={disableFinish}
				/>
			</Card>
			{seleccionPopupData.mostrar && (
				<ModalSeleccionAnimales
					config={config}
					titulo={`Animales para la lista de ${seleccionCategoria.nombre}${seleccionPopupData.lista.sexo ? ', ' + seleccionPopupData.lista.sexo : ''}`}
					datosIniciales={{
						seleccionados: seleccionPopupData.lista.animales,
						noSeleccionablesIds: yaSeleccionadosIds,
						filtrosIniciales: {
							categoriaId: seleccionPopupData.lista.categoriaId,
							sexo: seleccionPopupData.lista.sexo
						}
					}}
					onClose={handleClosePopup}
					onConfirmar={confirmarSeleccion}
				/>
			)}
		</>
	)
}