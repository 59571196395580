import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'

export default function CategoriaAnimalesSelects(props) {
	const { opciones, loading, onSave, initialCategoriaId, initialSexo, inline = false, allowNoSelect = false } = props
	const [selected, setSelected] = useState({
		categoriaId: initialCategoriaId || opciones[0].id.toString(),
		sexo: initialSexo || opciones[0].sexo || 'macho',
	})

	const handleChange = (valor, cual) => {
		setSelected((prev) => ({
			...prev,
			[cual]: valor,
		}))
	}

	useEffect(() => {
		onSave(selected)
	}, [selected])

	const handleChangeCategoriaId = (selectedId = '', selectedSexo = '') => {
		const categoriaSeleccionada =
			opciones.find((opcion) => opcion.id === parseInt(selectedId)) || {
				id: '',
				nombre: 'Todas',
				sexo: null,
			}

		const newSelected = {
			categoriaId: selectedId
		}

		if (selectedId !== '') {
			newSelected.sexo = selectedSexo || categoriaSeleccionada.sexo || 'macho'
		}

		setSelected(newSelected)
	}

	const opcionesCategorias = allowNoSelect
		? [{ id: '', nombre: 'Todas' }, ...opciones]
		: opciones
		
	const categoriaActual = opciones.find((opcion) => opcion.id === parseInt(selected.categoriaId))
	const sexoHabilitado = categoriaActual?.sexo === null

	return (
		<>
			<Form.Group
				controlId="categoriasAnimalesSelect-categoriaId"
				className={inline ? 'col' : 'col-12 col-sm-4 mb-3'}
			>
				<Form.Label className="mb-1">Categoría</Form.Label>
				<Form.Select
					size="sm"
					value={selected.categoriaId}
					onChange={(e) => handleChangeCategoriaId(e.target.value)}
					disabled={loading}
				>
					{opcionesCategorias.map((opcion) => (
						<option
							value={opcion.id}
							key={`categoriasAnimalesSelect-categoria-${opcion.id}`}
						>
							{opcion.nombre}
						</option>
					))}
				</Form.Select>
			</Form.Group>
			<Form.Group
				controlId="categoriasAnimalesSelect-sexo"
				className={inline ? 'col' : 'col-12 col-sm-4 mb-3'}
			>
				<Form.Label className="mb-1">Sexo</Form.Label>
				<Form.Select
					size="sm"
					value={selected.sexo || ''}
					onChange={(e) => handleChange(e.target.value, 'sexo')}
					disabled={!sexoHabilitado || loading}
				>
					{allowNoSelect && <option value="">Todos</option>}
					<option value="macho">Macho</option>
					<option value="hembra">Hembra</option>
				</Form.Select>
			</Form.Group>
		</>
	)
}
