import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ProgressBar from 'react-bootstrap/ProgressBar'

function Acumulado(props) {
	const { clima } = props

	const listaMeses = clima ? Object.keys(clima.meses) : []
	const maxMargin = 20
	const maxMax = clima ? Math.max(...Object.keys(clima.meses).map((m) => clima.meses[m].max)) + maxMargin : maxMargin

	const MinMax = ({ id, children, title, paddingLeft, visibility, width }) => (
		<OverlayTrigger
			overlay={<Tooltip id={id}>{title}</Tooltip>}
		>
			<div
				className="minmax"
				style={{
					paddingLeft,
					visibility,
					left: width + "%",
				}}
			>
				{children}
			</div>
		</OverlayTrigger>
	)

	return (
		<>
			<h5>Lluvias</h5>
			{!clima ? (
				<div className="alert alert-info">No hay resultados de lluvia para mostrar</div>
			) : (
				listaMeses.map((mes) => {
					const actualWidth = (clima.meses[mes].actual * 100) / maxMax
					const pasadoWidth = (clima.meses[mes].anhoPasado * 100) / maxMax
					const minWidth = (clima.meses[mes].min * 100) / maxMax
					const promedioWidth = (clima.meses[mes].promedio * 100) / maxMax
					const maxWidth = (clima.meses[mes].max * 100) / maxMax

					return (
						<Row key={`mes-${mes}`} className="mt-2">
							<Col style={{ position: 'relative' }}>
								<h6 className="text-muted">{mes}</h6>
								<MinMax
									id={`${clima.meses[mes]}-min`}
									title={`${clima.meses[mes].min} ml`}
									visibility={maxWidth === minWidth ? "hidden" : "visible"}
									paddingLeft={'inherit'}
									width={minWidth}
								>
									.Min
								</MinMax>
								<MinMax
									id={`${clima.meses[mes]}-promedio`}
									title={`${clima.meses[mes].promedio} ml`}
									visibility={clima.meses[mes].promedio === 0 ? "hidden" : "visible"}
									paddingLeft={'inherit'}
									width={promedioWidth}
								>
									.Promedio
								</MinMax>
								<MinMax
									id={`${clima.meses[mes]}-max`}
									title={`${clima.meses[mes].max} ml`}
									visibility={maxWidth === 0 ? "hidden" : "visible"}
									paddingLeft={0}
									width={maxWidth}
								>
									.Max
								</MinMax>
								<ProgressBar
									variant="primary"
									style={{ height: "30px" }}
									now={actualWidth}
									label={`Actual ${clima.meses[mes].actual}`}
									key={2}
								/>
								<ProgressBar
									style={{ opacity: "30%" }}
									variant="primary"
									now={pasadoWidth}
									label={`Año pasado ${clima.meses[mes].anhoPasado}`}
								/>

							</Col>
						</Row>
					)
				})
			)}
		</>
	)
}

export default Acumulado