import TreeView from 'react-accessible-treeview'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import './TreeViewWithArrows.css'

const ArrowIcon = ({ isOpen }) => {
	const classes = `tree-branch-icon me-2 fa-solid ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`

	return <i className={classes} />
}

export default function TreeViewWithArrows(props) {
	const { ariaLabel = 'Tree', multiSelect = false, data, propagateSelect = false, propagateSelectUpwards = false, togglableSelect = false, onSelect } = props

	const handleCustomSelect = (element) => {
		onSelect(element)
	}
	return (
		<div className="tree-wrapper">
			<TreeView
				data={data}
				aria-label={ariaLabel}
				multiSelect={multiSelect}
				propagateSelect={propagateSelect}
				propagateSelectUpwards={propagateSelectUpwards}
				togglableSelect={togglableSelect}
				onSelect={handleCustomSelect}
				nodeRenderer={({
					element,
					isBranch,
					isExpanded,
					isSelected,
					isHalfSelected,
					getNodeProps,
					level,
					handleSelect,
					handleExpand,
				}) => {
					return (
						<div
							{...getNodeProps({ onClick: handleExpand })}
							style={{ marginLeft: 40 * (level - 1) }}
							className="tree-element-wrapper"
						>
							{isBranch && <ArrowIcon isOpen={isExpanded} />}
							<span
								className="tree-label"
								onClick={isBranch ? null : (e) => {
									handleCustomSelect(element)
								}}
							>
								{element.name}
							</span>
							<div className="d-flex flex-grow-1 justify-content-end">
								<Badge className="ms-2" style={{ marginRight: !isBranch ? 66.5 : 0 }}>{element.metadata.total}</Badge>
								{isBranch && (
									<Button
										className="ms-3 py-0 tree-button"
										onClick={(e) => {
											e.stopPropagation()
											handleCustomSelect(element)
										}}
										variant="secondary" size="sm"
									>
										<i className="fa fa-eye"></i> Ver
									</Button>
								)}
							</div>
						</div>
					)
				}}
			/>
		</div>
	)
}