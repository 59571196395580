import React from 'react'

// Este es el componente SortIcon que recibe la dirección del ordenamiento y la clase css como props
export default function SortIcon(props) {
	const { direction, className } = props

	if (direction === 'asc') {
		return <i className={`fa fa-caret-up ${className}`} />
	} else {
		return <i className={`fa fa-caret-down ${className}`} />
	}
}