import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Paginacion from 'components/Paginacion'
import axios from 'axios'
import { FETCH_DE_A_CUANTOS, TIPOS_TAREAS } from 'config/tareas'
import { toast } from 'react-toastify'
import './tareas.css'

import api from 'api/api'
import { DownloadButton } from 'components/DownloadButton'
import { getBaseUrl } from 'utils/general'

export default function ListaTareas(props) {
	const { active, filtros, handleVer, handleEditar, handleProcesar, handleEliminar, reglasNoEditable = [], conDropdown = true } = props
	const [fetchDeACuantos, setFetchDeACuantos] = useState(FETCH_DE_A_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [tareas, setTareas] = useState([])
	const [cancelToken, setCancelToken] = useState(null)
	const [loading, setLoading] = useState(true)
	const [cambioFiltros, setCambioFiltros] = useState(true)

	const tiposTareas = TIPOS_TAREAS
	const tipos = tiposTareas.map(t => t.value)

	if (!cancelToken) {
		const source = axios.CancelToken.source()
		setCancelToken(source)
	}

	useEffect(() => {
		return () => {

			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	useEffect(() => {
		if (active) {
			setCambioFiltros(true)
			fetchTareas(1, cancelToken)
		}
	}, [filtros])

	useEffect(() => {
		if (!loading) {
			fetchTareas(paginaAct, cancelToken)
		}
	}, [paginaAct])

	const fetchTareas = async (pagina, cancelToken) => {
		setLoading(true)
		try {
			const filtrosServer = {
				completada: filtros.completada
			}

			if (filtros.nombre[0].trim()) {
				filtrosServer.nombre = [filtros.nombre[0].trim()]
			}

			if (filtros.tipo) {
				filtrosServer.tipo = filtros.tipo
			}

			if (filtros.creadorId) {
				filtrosServer.creadorId = filtros.creadorId
			}

			if (filtros.asignadoId) {
				filtrosServer.asignadoId = filtros.asignadoId
			}

			const { datos: respuesta, error } = await api.post('u/ver-tareas', {
				cuantos: fetchDeACuantos,
				desde: pagina === 1 ? 0 : (pagina - 1) * fetchDeACuantos,
				filtros: filtrosServer
			}, { cancelToken: cancelToken.token })
			if (!error) {
				setPaginaAct(pagina)
				setTareas(respuesta.tareas)
				setNumResultados(respuesta.numResultados)
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.log('fetchStock error', error)
			}
		} finally {
			setLoading(false)
			setCambioFiltros(false)
		}
	}

	const isEditable = (tarea) => {
		let editable = true

		reglasNoEditable.forEach(regla => {
			if (tarea.tipo === regla.tipo && regla.condicion(tarea)) {
				editable = false
			}
		})

		return editable
	}

	return (
		<div className="lista-tareas">
			<Row>
				<Col>
					<small>Total resultados: {cambioFiltros ? (<span className="ms-2"><LoadingSpinner size='sm' /></span>) : (<strong>{numResultados}</strong>)}</small>
				</Col>
			</Row>
			{/* TABLE */}
			<Row>
				<Col>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>id</th>
								<th>Nombre</th>
								<th>Tipo</th>
								<th># Animales</th>
								<th>Fecha</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{
								loading ? (
									<tr>
										<td colSpan="6">
											<LoadingSpinner />
										</td>
									</tr>
								) : (
									tareas.length ? (
										tareas.map((tarea, i) => {
											return (
												<tr key={`tarea-${tarea.id}`} className={tarea.sinPlanificacion ? 'fst-italic' : null}>
													<td>{tarea.id}</td>
													<td>{tarea.nombre || '** EVENTO **'}</td>
													<td>{tarea.tipo}</td>
													<td>{`${tarea.animalesProcesados || 0}/${tarea.animalesDeseados || 0}`}</td>
													<td>{tarea.fechaParaCuando?.substr(0, 10) || '-'}</td>
													<td className="">
														{conDropdown ? (
															<DropdownButton
																as={ButtonGroup}
																title="OPCIONES"
																id="bg-nested-dropdown"
																className="fst-normal"
															>
																{handleVer && <Dropdown.Item
																	onClick={() => handleVer(tarea.id, tarea.tipo)}
																	eventKey="ver"
																>
																	Ver
																</Dropdown.Item>}
																{handleProcesar && <Dropdown.Item
																	onClick={() => handleProcesar(tarea.id, tarea.tipo)}
																	eventKey="procesar"
																>
																	Procesar
																</Dropdown.Item>}
																{handleEditar && <Dropdown.Item
																	disabled={!isEditable(tarea)}
																	onClick={() =>
																		handleEditar(tarea.id, tarea.tipo)
																	}
																	eventKey="editar"
																>
																	Editar
																</Dropdown.Item>}
																{handleEliminar && <Dropdown.Item
																	onClick={() => handleEliminar(tarea)}
																	eventKey="eliminar"
																>
																	Eliminar
																</Dropdown.Item>}
															</DropdownButton>
														) : (
															handleVer ? (
																<>
																	<Button
																		onClick={() => handleVer(tarea.id, tarea.tipo)}
																	>
																		Ver
																	</Button>
																	{
																		tipos.includes(tarea.tipo) ? (
																			<DownloadButton
																				url={getBaseUrl() + `u/tareas/${tarea.id}/descargar-xlsx`}
																				whileDownloading={<i className="fa fa-spinner fa-spin"></i>}
																				variant="success"
																				className="ms-2"
																				title="Descargar Excel"
																				disabled={!tipos.includes(tarea.tipo)}
																			>
																				<i className="fa fa-file-excel"></i>
																			</DownloadButton>
																		) : null
																	}
																</>
															) : null
														)}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="6">
												No se encontraron tareas.
											</td>
										</tr>
									)
								)
							}
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginacion
						pagina={paginaAct}
						numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
						setPagina={setPaginaAct}
					/>
				</Col>
			</Row>
		</div>
	)
}