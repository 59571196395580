import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import BaseDataInputs from '../BaseDataInputs'
import { ModalSeleccionAnimales } from 'components/SeleccionAnimales'
import { ModalSeleccionEditableList } from 'components/EditableList'


export default function Sanitacion(props) {
	const { config, tarea, onClose, onGuardar } = props

	const listaInicializada = tarea ? {
		id: tarea.listas[0].id,
		nombre: tarea.listas[0].nombre,
		tipo: tarea.listas[0].tipo,
		animales: tarea.listas[0].animales
	} : {
		nombre: 'Animales',
		tipo: 'sanitacion',
		animales: []
	}

	const [data, setData] = useState({
		id: tarea?.id,
		nombre: tarea?.nombre ?? '',
		fechaParaCuando: tarea?.fechaParaCuando ?? '',
		asignadoId: tarea?.asignadoId ?? '',
		observacion: tarea?.observacion ?? '',
		lista: listaInicializada,
	})
	const [seleccionPopupData, setSeleccionPopupData] = useState({
		mostrar: false, // si mostrar o no el popup
		lista: null, // la lista de animales
	})
	const [listaConfigMedicamentos, setListaConfigMedicamentos] = useState(tarea ? tarea.sanitacion.configuracionMedicamentos.map(e => ({ id: e.id, value: e.nombre })) : [])
	const [showSeleccionConfigMedicamentos, setShowSeleccionConfigMedicamentos] = useState(false)

	const listaCompletada = data.lista.animales?.length || false

	const yaSeleccionadosIds = data.lista.animales?.map((animal) => animal.id)

	const initialBaseInputData = tarea ? { nombre: tarea.nombre, fechaParaCuando: tarea.fechaParaCuando, asignadoId: tarea.asignadoId, observacion: tarea.observacion } : null
	const saveDisabled = data.nombre.trim() === '' || data.fechaParaCuando === '' || !listaCompletada || listaConfigMedicamentos.filter((s) => s.value !== '').length === 0

	const saveBaseInputsData = (baseData) => {
		setData(prev => ({
			...prev,
			...baseData
		}))
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'sanitacion',
			nombre: data.nombre,
			fechaParaCuando: data.fechaParaCuando,
			asignadoId: data.asignadoId,
			observacion: data.observacion,
			listas: [
				{
					nombre: data.lista.nombre,
					tipo: 'sanitacion',
					cantidadAnimales: data.lista.animales.length,
					animalesIds: data.lista.animales.map(a => parseInt(a.id))
				}
			],
			sanitacion: {
				configuracionMedicamentos: listaConfigMedicamentos.filter((s) => s.value !== '').map(elem => isNaN(elem.id) && elem.id.startsWith('temp') ? { nombre: elem.value.trim() } : { id: elem.id, nombre: elem.value })
			}
		}

		if (tarea) {
			serverData.id = tarea.id
			serverData.listas[0].id = tarea.listas[0].id

			const yaExistentesIds = tarea.listas[0].animales.map((animal) => parseInt(animal.id))
			const seleccionadosIds = data.lista.animales.map((animal) => parseInt(animal.id))

			const agregarIds = seleccionadosIds.filter((id) => !yaExistentesIds.includes(id))
			const eliminarIds = yaExistentesIds.filter((id) => !seleccionadosIds.includes(id))

			if (agregarIds.length > 0) {
				serverData.listas[0].agregarAnimalesIds = agregarIds
			}

			if (eliminarIds.length > 0) {
				serverData.listas[0].eliminarAnimalesIds = eliminarIds
			}

			const configuracionMedicamentosIds = listaConfigMedicamentos.map(e => e.id)
			const eliminarConfiguracionMedicamentosIds = tarea.sanitacion.configuracionMedicamentos.filter(e => !configuracionMedicamentosIds.includes(e.id)).map(e => e.id)
			if (eliminarConfiguracionMedicamentosIds.length > 0) {
				serverData.sanitacion.eliminarConfiguracionMedicamentosIds = eliminarConfiguracionMedicamentosIds
			}
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	/* Inicio manejo de popup de selección de animales */
	const handleShowSeleccionAnimales = () => {
		const listaCopy = { ...data.lista }

		const popupData = {
			mostrar: true,
			lista: listaCopy
		}

		setSeleccionPopupData(popupData)
	}

	const handleClosePopup = () => {
		setSeleccionPopupData({
			mostrar: false,
			lista: null,
		})
	}

	const confirmarSeleccion = (seleccionados) => {
		const animales = seleccionados.todos
		const lista = { ...data.lista }
		lista.animales = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})

		setData(prev => ({ ...prev, lista }))

		handleClosePopup()
	}
	/* Fin manejo de popup de selección de animales */

	const handleShowSeleccionConfigMedicamentos = () => {
		setShowSeleccionConfigMedicamentos(true)
	}

	const handleCloseSeleccionConfigMedicamentos = () => {
		setShowSeleccionConfigMedicamentos(false)
	}

	const handleConfirmarSeleccionConfigMedicamentos = (lista) => {
		setListaConfigMedicamentos(lista)
		setShowSeleccionConfigMedicamentos(false)
	}

	return (
		<Container>
			<BaseDataInputs
				initialData={initialBaseInputData}
				usuarios={config.usuarios}
				saveData={saveBaseInputsData}
			/>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col>
							<Table className="lista-seleccion-animales table-hover pt-0">
								<thead>
									<tr>
										<th>Cantidad</th>
										<th>Nombre</th>
										<th>Acciones</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="sanitacion-lista-cantidad">
											{data.lista.animales?.length || 0}
										</td>
										<td className="sanitacion-lista-categoria">
											Animales
										</td>
										<td className="acciones">
											<i className="fas fa-rectangle-list salida-lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionAnimales()} />
										</td>
										<td className="sanitacion-lista-completa">
											{data.lista.animales?.length > 0 ? <i className="fas fa-check-circle text-success" title="Lista completa" /> : ''}
										</td>
									</tr>
									<tr>
										<td className="sanitacion-lista-cantidad">
											{listaConfigMedicamentos.length}
										</td>
										<td className="sanitacion-lista-categoria">
											Medicamentos
										</td>
										<td className="acciones">
											<i className="fas fa-rectangle-list salida-lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionConfigMedicamentos()} />
										</td>
										<td className="sanitacion-lista-completa">
											{listaConfigMedicamentos.length > 0 ? <i className="fas fa-check-circle text-success" title="Lista completa" /> : ''}
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={saveDisabled}
							>
								{tarea ? 'Editar' : 'Crear'} tarea
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			{seleccionPopupData.mostrar && (
				<ModalSeleccionAnimales
					config={config}
					titulo={`Animales para la lista de ${data.lista.nombre}`}
					datosIniciales={{
						seleccionados: data.lista.animales,
						filtrosIniciales: data.lista.filtrosIniciales,
						noSeleccionablesIds: yaSeleccionadosIds
					}}
					onClose={handleClosePopup}
					onConfirmar={confirmarSeleccion}
				/>
			)}
			{showSeleccionConfigMedicamentos && (
				<ModalSeleccionEditableList
					listaOriginal={listaConfigMedicamentos}
					tituloModal={'Selección de medicamentos'}
					titulo={'Lista de medicamentos'}
					onClose={handleCloseSeleccionConfigMedicamentos}
					onConfirmar={handleConfirmarSeleccionConfigMedicamentos}
				/>
			)}
		</Container>
	)
}