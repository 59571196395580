import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'

import './Panel.css'

function PanelHeader(props) {
	const defaultBtnOnClick = () => console.log('click')
	const defaultBtnIconClass = 'fa-arrow-left'
	const defaultBtnText = 'Atrás'
	const defaultBtnSize = 'sm'
	const defaultBtnVariant = 'secondary'
	
	const { buttonProps, text } = props

	const showButton = buttonProps.show === undefined ? true : buttonProps.show
	return (
		<Offcanvas.Header closeButton className="panel-header d-flex align-items-center">
			<div className="d-flex align-items-center">
				{showButton && (
					<Button
						className="rounded-pill me-2"
						variant={buttonProps?.variant || defaultBtnVariant}
						size={buttonProps?.size || defaultBtnSize}
						onClick={buttonProps?.onClick || defaultBtnOnClick}
					>
							<i className={`fa me-1 ${buttonProps?.iconClass || defaultBtnIconClass}`}></i>
							{buttonProps?.text || defaultBtnText}
					</Button>
				)}
				{text}
			</div>
		</Offcanvas.Header>
	)
}

export default PanelHeader
