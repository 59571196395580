//useWizard
import React, { useState, useEffect } from 'react'
import { isFunction } from 'utils/general'

// A custom hook to handle the wizard state
export default function useWizard(initialData) {
	const [data, setData] = useState(initialData) // data es un array de objetos. Un objeto por cada paso
	const [step, setStep] = useState(0)
	const [completed, setCompleted] = useState([])

	// A function to update a step's data and mark the step as completed
	const updateData = (step, newData, isCompleted, goToNext = false, onFinish = null) => {
		const dataCopy = [...data]
		dataCopy[step] = newData
		setData(dataCopy)
		const completedCopy = [...completed]
		if (isCompleted && !completedCopy.includes(step)) {
			completedCopy.push(step)
			setCompleted(completedCopy)
		}

		// si se quiere ir al siguiente paso
		if (goToNext) {
			nextStep(onFinish, dataCopy)
		} else if (completedCopy.length === data.length) { // si ya se tiene el wizard terminado, entonces guardar directamente
			if (isFunction(onFinish)) {
				onFinish(dataCopy)
			}
		}
	}

	// Avanzar al siguiente paso o terminar
	const nextStep = (onFinish, completedData) => {
		if (step < data.length - 1) {
			setStep(step + 1)
		} else {
			if (isFunction(onFinish)) {
				onFinish(completedData)
			}
		}
	}

	// Avanzar al paso anterior
	const prevStep = () => {
		if (step > 0) {
			setStep(step - 1)
		}
	}

	// Ir directamente a un paso específico
	const goToStep = (index) => {
		if (index >= 0 && index < data.length) {
			setStep(index)
		}
	}

	// Verificar si el paso ya fue completado
	const isCompleted = (index) => {
		return completed.includes(index)
	}

	// Retorna el primer paso que no haya sido completado
	const getFirstNotCompleted = () => {
		let x = 0
		for (let i = 0; i <= data.length; i++) {
			if (!completed.includes(i)) {
				x = i
				break
			}
		}

		return x
	}

	return {
		data,
		step,
		updateData,
		nextStep,
		prevStep,
		goToStep,
		isCompleted,
		setCompleted,
		getFirstNotCompleted,
	}
}
