import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import { toast } from 'react-toastify'
import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { TIPOS_TAREAS, TIPOS_EVENTOS } from 'config/tareas'

export default function MostrarAccionEliminar(props) {
	const { tarea, onClose, onDelete } = props
	const [loading, setLoading] = useState(true)
	const [cancelToken, setCancelToken] = useState(null)

	const handleClose = () => {
		onClose()
	}

	const handleDelete = () => {
		onDelete()
	}


	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar Propietario</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de eliminar la tarea <strong>{tarea.nombre}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={onClose}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={handleDelete}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}