import React, { useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Alert } from 'react-bootstrap'
import DefinirAnimales from './DefinirAnimales'
import DefinirPariciones from './DefinirPariciones'
import { replaceEmptyWithNull } from 'utils/general'
import { ScrollTop } from 'components/ScrollTop'


export default function Paricion(props) {
	const { config, onClose, onGuardar, containerRef } = props

	const [seleccionados, setSeleccionados] = useState([])
	const [mostrarPariciones, setMostrarPariciones] = useState(false)

	/*
	 * Array de elementos con los siguientes campos:
	 * id: animalId,
	 * fechaParaCuando,
	 * numHijos: 1,
	 * hijos: [
	 *   {
	 *     vivo,
	 *     sexo,
	 *     raza,
	 *     color,
	 *     peso,
	 *     identificarMasAdelante,
	 *     fechaIdentificacion,
	 *     numChip,
	 *     numCaravana,
	 *     numTatuaje,
	 *     fechaNacimiento // misma que la fecha de arriba
	 *   }
	 * ]
	 */
	const [pariciones, setPariciones] = useState([])

	const datosInicialesHijo = {
		vivo: true,
		sexo: '',
		raza: '',
		color: '',
		peso: '',
		identificarMasAdelante: false,
		fechaIdentificacion: '',
		numChip: '',
		numCaravana: '',
		numTatuaje: '',
		fechaNacimiento: ''
	}

	const verSiCompletadoDatosParicionHijo = (hijo) => {
		let completadoHijo = false
		if (hijo.vivo === false) {
			completadoHijo = true
		} else if (hijo.vivo && hijo.sexo !== '') {
			completadoHijo = true
		}

		return completadoHijo
	}

	const verSiCompletadoDatosParicion = (paricion) => {
		if (!paricion.fechaParaCuando) {
			return false
		}

		// se completa cuando se especifica por cada hijo si nació vivo, y si nació vivo, su sexo
		let paricionCompletada = true

		paricion.hijos.forEach(hijo => {
			const completadoHijo = verSiCompletadoDatosParicionHijo(hijo)

			if (!completadoHijo) {
				paricionCompletada = false
			}
		})

		return paricionCompletada
	}

	const verSiCompleto = () => {
		let completo = true

		pariciones.forEach((paricion, i) => {
			if (!verSiCompletadoDatosParicion(paricion)) {
				completo = false
			}
		})

		return completo
	}

	const completo = mostrarPariciones && verSiCompleto()

	const handleDefinirPariciones = (animales) => {
		const animalesConCategoria = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})
		setSeleccionados(animalesConCategoria)

		setPariciones(animalesConCategoria.map(animal => ({
			animalId: animal.id,
			fechaParaCuando: '',
			numHijos: 1,
			indexHijos: 0,
			hijos: [{
				...datosInicialesHijo
			}]
		})))

		setMostrarPariciones(true)
	}

	const changeParicionHijoData = (valor, cual, indice) => {
		const paricionesCopy = [...pariciones]
		paricionesCopy[indice]['hijos'][paricionesCopy[indice].indexHijos][cual] = valor

		setPariciones(paricionesCopy)
	}

	const changeParicionData = (valor, cual, indice) => {
		const paricionesCopy = [...pariciones]

		console.log('valor', valor)
		console.log('cual', cual)
		console.log('indice', indice)

		if (cual === 'numHijos') {
			if (valor > paricionesCopy[indice].numHijos) {
				for (let j = paricionesCopy[indice].numHijos - 1; j < valor - 1; j++) {
					paricionesCopy[indice].hijos.push({
						...datosInicialesHijo
					})
				}
			} else {
				const diferencia = Number(paricionesCopy[indice].numHijos) - valor
				paricionesCopy[indice].hijos.splice(-diferencia, diferencia)
				if (paricionesCopy[indice].indexHijos > valor - 1) {
					paricionesCopy[indice].indexHijos = valor - 1
				}
			}
		}

		paricionesCopy[indice][cual] = valor

		setPariciones(paricionesCopy)
	}

	const handleDefinirParicionesAtras = () => {
		setMostrarPariciones(false)
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'paricion',
			sinPlanificacion: true,
			/*
			listas: [
				{
					nombre: 'animales',
					tipo: 'paricion',
					cantidadAnimales: pariciones.length,
					marcarProcesados: pariciones.map((paricion) => replaceEmptyWithNull(paricion))
				}
			]*/
			pariciones: pariciones.map((paricion) => replaceEmptyWithNull(paricion))
		}

		console.log('serverData', serverData)

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="card-bg-gray">
				<Card.Header as='h5'>Evento de Parición</Card.Header>
			</Card>
			{!mostrarPariciones && (
				<Row>
					<Col>
						<Alert variant="info">Primero seleccionar los animales que hayan parido, luego proceder a la carga del evento parición de cada uno.</Alert>
					</Col>
				</Row>
			)}
			<Card className="mt-3">
				<Card.Body>
					{mostrarPariciones ? (
						<DefinirPariciones
							animales={seleccionados}
							pariciones={pariciones}
							changeParicionData={changeParicionData}
							changeParicionHijoData={changeParicionHijoData}
							verSiCompletadoDatosParicion={verSiCompletadoDatosParicion}
							verSiCompletadoDatosParicionHijo={verSiCompletadoDatosParicionHijo}
							onAtras={handleDefinirParicionesAtras}
						/>
					) : (
						<DefinirAnimales
							config={config}
							animalesSeleccionados={seleccionados}
							onGuardar={handleDefinirPariciones}
						/>
					)}
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={!completo}
							>
								Guardar
							</Button>
						</Col>
					</Row>
					<ScrollTop containerRef={containerRef} showFrom={1000} top={210} />
				</Card.Body>
			</Card>

		</Container>
	)
}