import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import api from 'api/api'
import { ConfigContext } from 'contexts/config'
import { MultiSelectCustom } from 'components/MultiSelectCustom'



export default function TestComponent(props) {
	const { data: config, loading: loadingConfig, error } = useContext(ConfigContext)

	const [selectedPropietarios, setSelectedPropietarios] = useState([])

	console.log('selected', selectedPropietarios)

	const propietariosOptions = config.propietarios.concat([{value: 5, label: 'adsf adsf'}]).map((e) => ({
		value: e.id,
		label: `${e.nombre} ${e.apellido}`,
	}))

	return (
		<Container fluid id="test" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2 col-6">
					<MultiSelectCustom
						options={propietariosOptions}
						value={selectedPropietarios}
						onChange={setSelectedPropietarios}
					/>
				</Col>
			</Row>
		</Container>
	);
}
