import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { PanelHeader } from 'components/Panel'

export default function FiltrosPanel(props) {
	const { onClose, propietarios, establecimientos, filtros, setFiltros } = props

	const [soloIdentificados, setSoloIdentificados] = useState(filtros.soloIdentificados)
	const [propietariosIds, setPropietariosIds] = useState(filtros.propietarioId[1])
	const [establecimientosIds, setEstablecimientosIds] = useState(filtros.establecimientoId[1])

	const handleEstablecimientoChange = (establecimientoId) => {
		if (establecimientosIds.includes(establecimientoId)) {
			setEstablecimientosIds(prev => prev.filter(elem => elem !== establecimientoId))
		} else {
			setEstablecimientosIds(prev => [...prev, establecimientoId])
		}
	}

	const handlePropietarioChange = (propietarioId) => {
		if (propietariosIds.includes(propietarioId)) {
			setPropietariosIds(prev => prev.filter(elem => elem !== propietarioId))
		} else {
			setPropietariosIds(prev => [...prev, propietarioId])
		}
	}

	const applyFiltros = () => {
		const nuevosFiltros = {
			...filtros,
			soloIdentificados,
			propietarioId: ['in', propietariosIds],
			establecimientoId: ['in', establecimientosIds]
		}

		//console.log('nuevosFiltros', nuevosFiltros)
		setFiltros(nuevosFiltros)
		close()
	}

	const close = () => {
		onClose()
	}

	return (
		<Offcanvas show={true} onHide={close} placement="end" scroll="true" responsive="lg" style={{ maxWidth: 350 }}>
			<PanelHeader buttonProps={{onClick: close, show: false}} text="Filtros" />
			<Offcanvas.Body>
				<Form>
					<Row>
						<Col>
							<Form.Group controlId="check-identificados">
								<Form.Check
									type="checkbox"
									label="Solo animales identificados"
									checked={soloIdentificados}
									onChange={(e) => setSoloIdentificados(!soloIdentificados)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5>Propietario</h5>
							{
								propietarios.length > 0
									? (
										propietarios.map((propietario) => {
											return (
												<Form.Group className="ms-3 mb-3" key={propietario.id} controlId={`propietario-${propietario.id}`}>
													<Form.Check
														type="checkbox"
														label={`${propietario.nombre} ${propietario.apellido}`}
														onChange={() => handlePropietarioChange(propietario.id)}
														value={propietario.id}
														checked={propietariosIds.includes(propietario.id)}
														disabled={propietariosIds.includes(propietario.id) && propietariosIds.length === 1}
														title={propietariosIds.includes(propietario.id) && propietariosIds.length === 1 ? 'Se debe seleccionar al menos un propietario' : null}
													/>
												</Form.Group>
											)
										})
									)
									: null
							}
						</Col>
					</Row>
					<Row>
						<Col>
							<h5>Establecimientos</h5>
							{
								establecimientos.length > 0
									? (
										establecimientos.map((establecimiento) => {
											return (
												<Form.Group className="ms-3 mb-3" key={establecimiento.id} controlId={`establecimiento-${establecimiento.id}`}>
													<Form.Check
														type="checkbox"
														label={establecimiento.nombre}
														onChange={() => handleEstablecimientoChange(establecimiento.id)}
														value={establecimiento.id}
														checked={establecimientosIds.includes(establecimiento.id)}
														disabled={establecimientosIds.includes(establecimiento.id) && establecimientosIds.length === 1}
														title={establecimientosIds.includes(establecimiento.id) && establecimientosIds.length === 1 ? 'Se debe seleccionar al menos un establecimiento' : null}
													/>
												</Form.Group>
											)
										})
									)
									: null
							}
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Button
								variant="primary"
								onClick={applyFiltros}
							>
								Aplicar
							</Button>
						</Col>
					</Row>
				</Form>
			</Offcanvas.Body>
		</Offcanvas>
	)
}