import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { EditableList } from 'components/EditableList'

export default function ModalSeleccionEditableList(props) {
	const { listaOriginal, tituloModal = 'Selección', titulo = 'Lista', onClose, onConfirmar } = props

	const [lista, setLista] = useState([...listaOriginal])

	const handleClose = () => {
		onClose()
	}

	const handleConfirmar = () => {
		onConfirmar(lista.filter((e) => e.value.trim() !== ''))
	}

	return (
		<Modal
			show={true}
			onHide={() => handleClose()}
			size="xl"
			backdrop={'static'}
		>
			<Modal.Header closeButton>
				<h4><strong>{tituloModal}</strong></h4>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col>
						<EditableList
							titulo={titulo}
							lista={lista}
							setLista={setLista}
							colSizes={{ sm: 8 }}
							btnAgregarSize="sm"
						/>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => handleConfirmar()}>Aceptar</Button>
			</Modal.Footer>
		</Modal>
	)
}