import React from 'react'
import Container from 'react-bootstrap/Container'
import ServicioProcesarIATFoIA from './ServicioProcesarIATFoIA'
import ServicioProcesarMonta from './ServicioProcesarMonta'


export default function ServicioProcesar(props) {
	const { config, tarea, onClose, onProcesar, verFinalizarTarea, setCambiosData } = props

	return (
		<Container>
			{tarea.servicio.tipo === 'iatf' || tarea.servicio.tipo === 'ia' ? (
				<ServicioProcesarIATFoIA
					tarea={tarea}
					onClose={onClose}
					onProcesar={onProcesar}
					verFinalizarTarea={verFinalizarTarea}
					setCambiosData={setCambiosData}
				/>
			) : (
				tarea.servicio.tipo === 'monta-natural' ? (
					<ServicioProcesarMonta
						tarea={tarea}
						onClose={onClose}
						onProcesar={onProcesar}
					/>
				) : null
			)}
		</Container>
	)
}