import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import BaseDataInputs from '../BaseDataInputs'
import useAnimales from 'hooks/useAnimales'
import { SeleccionAnimales, MostrarListaAnimales } from 'components/SeleccionAnimales'
import { ScrollTop } from 'components/ScrollTop'


export default function DiagnosticoPrenez(props) {
	const { config, tarea, onClose, onGuardar, containerRef } = props

	const seleccionadosIniciales = tarea ? tarea.listas[0].animales : [];

	const { loading, error, cambiarCategoria, cambiarFiltro, animales, seleccionados, seleccionarAnimal,
		seleccionarMultiplesAnimales, deseleccionarAnimal, pagina, cambiarPagina, deACuantos, cambiarDeACuantos, numResultados } = useAnimales({seleccionados: seleccionadosIniciales})

	const [data, setData] = useState({
		id: tarea?.id,
		nombre: tarea?.nombre ?? '',
		fechaParaCuando: tarea?.fechaParaCuando ?? '',
		asignadoId: tarea?.asignadoId ?? '',
		observacion: tarea?.observacion ?? ''
	})

	const initialBaseInputData = tarea ? { nombre: tarea.nombre, fechaParaCuando: tarea.fechaParaCuando, asignadoId: tarea.asignadoId, observacion: tarea.observacion } : null
	const saveDisabled = data.nombre.trim() === '' || data.fechaParaCuando === '' || seleccionados.todos.length === 0

	const saveBaseInputsData = (baseData) => {
		setData(prev => ({
			...prev,
			...baseData
		}))
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'diagnostico-prenez',
			nombre: data.nombre,
			fechaParaCuando: data.fechaParaCuando,
			asignadoId: data.asignadoId,
			observacion: data.observacion,
			listas: [
				{
					nombre: 'animales',
					tipo: 'diagnostico-prenez',
					cantidadAnimales: seleccionados.todos.length,
					animalesIds: seleccionados.todos.map(a => parseInt(a.id))
				}
			]
		}

		if (tarea) {
			serverData.id = tarea.id
			serverData.listas[0].id = tarea.listas[0].id

			const yaExistentesIds = tarea.listas[0].animales.map((animal) => parseInt(animal.id))
			const seleccionadosIds = seleccionados.todos.map((animal) => parseInt(animal.id))
	
			const agregarIds = seleccionadosIds.filter((id) => !yaExistentesIds.includes(id))
			const eliminarIds = yaExistentesIds.filter((id) => !seleccionadosIds.includes(id))
	
			if (agregarIds.length > 0) {
				serverData.listas[0].agregarAnimalesIds = agregarIds
			}
	
			if (eliminarIds.length > 0) {
				serverData.listas[0].eliminarAnimalesIds = eliminarIds
			}
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<BaseDataInputs
				initialData={initialBaseInputData}
				usuarios={config.usuarios}
				saveData={saveBaseInputsData}
			/>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="6">
							<SeleccionAnimales
								config={config}
								loading={loading}
								animales={animales}
								cambiarFiltro={cambiarFiltro}
								cambiarCategoria={cambiarCategoria}
								seleccionados={seleccionados}
								seleccionarAnimal={seleccionarAnimal}
								seleccionarMultiplesAnimales={seleccionarMultiplesAnimales}
								pagina={pagina}
								deACuantos={deACuantos}
								cambiarDeACuantos={cambiarDeACuantos}
								cambiarPagina={cambiarPagina}
								numResultados={numResultados}
							/>
						</Col>
						<Col md="6">
							<MostrarListaAnimales seleccionados={seleccionados} deseleccionarAnimal={deseleccionarAnimal}/>
						</Col>
					</Row>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={saveDisabled}
							>
								{tarea ? 'Editar' : 'Crear'} tarea
							</Button>
						</Col>
					</Row>
					<ScrollTop containerRef={containerRef} showFrom={1000} top={330} />
				</Card.Body>
			</Card>
		</Container>
	)
}