import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import { flattenTree } from 'react-accessible-treeview'
import './stock.css'

import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import TreeViewWithArrows from 'components/TreeViewWithArrows/TreeViewWithArrows'
import FiltrosPanel from './FiltrosPanel'
import ListaAnimalesPanel from './ListaAnimalesPanel'
import { numberWithSeparator } from 'utils/numberWithSeparator'
import { DownloadButton } from 'components/DownloadButton'
import { getBaseUrl } from 'utils/general'

export default function Stock() {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [establecimientos, setEstablecimientos] = useState([])
	const [propietarios, setPropietarios] = useState([])
	const [filtros, setFiltros] = useState({
		initialized: false,
		soloIdentificados: false,
		propietarioId: [
			'in',
			[] // el id de los propietarios que haya en la cuenta
		],
		establecimientoId: [
			'in',
			[] // el id de los estableicmientos que haya en la cuenta
		]
	})
	const [stock, setStock] = useState(null)
	const [loadingStock, setLoadingStock] = useState(true)
	const [showFiltrosPanel, setShowFiltrosPanel] = useState(false)
	const [selectedListaAnimales, setSelectedListaAnimales] = useState(null)

	const isLoadingStock = !filtros.initialized || loadingStock || loading

	const refreshConfig = async (cancelToken) => {
		try {
			const { datos: respuesta } = await api.get('u/config-stock', {
				cancelToken: cancelToken.token,
			})

			setEstablecimientos(respuesta.establecimientos)
			setPropietarios(respuesta.propietarios)
			// setea los filtros y se activa el useEffect de refreshStock
			setFiltros({
				initialized: true,
				soloIdentificados: false,
				propietarioId: ['in', respuesta.propietarios.map(propietario => propietario.id)],
				establecimientoId: ['in', respuesta.establecimientos.map(establecimiento => establecimiento.id)]
			})
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Petición cancelada', error.message)
			} else {
				toast.error('Error de conexión')
				setError(error)
			}
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		refreshConfig(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	useEffect(() => {
		fetchStock()
	}, [filtros])

	const performUpdate = () => {
		setFiltros({ ...filtros })
	}

	const fetchStock = async () => {
		if (filtros.initialized) {
			setLoadingStock(true)
			try {
				const serverData = {
					soloIdentificados: filtros.soloIdentificados,
					establecimientoId: filtros.establecimientoId,
					propietarioId: filtros.propietarioId
				}
				const { datos: respuesta, error } = await api.post('u/ver-stock', serverData, { cancelToken: cancelToken.token })
				if (!error) {
					setStock(respuesta)
				}
			} catch (error) {
				if (!axios.isCancel(error)) {
					console.log('fetchStock error', error)
				}
			}
			setLoadingStock(false)
		}
	}

	function convertData(data) {
		// Create an empty array to store the converted nodes
		let result = {
			name: '',
			metadata: {
				total: data.total,
				filtros: {}
			},
			total: data.total,
			children: []
		}

		// Loop through the data and convert each node
		for (let node of data) {
			result.children.push(convertNode(node))
		}

		return flattenTree(result)
	}

	function convertNode(node) {
		let newNode = {
			name: node.nombre,
			metadata: {
				total: node.total,
				filtros: node.filtros
			},
			children: []
		}

		if (node.subdivisiones) {
			for (let child of node.subdivisiones) {
				newNode.children.push(convertNode(child))
			}
		}

		return newNode
	}

	const closeFiltrosPanel = () => {
		setShowFiltrosPanel(false)
	}

	const closeListaAnimalesPanel = () => {
		setSelectedListaAnimales(null)
	}

	const handleSelect = (node) => {
		const lista = { ...node.metadata }
		lista.filtros = { ...lista.filtros, ...filtros }
		delete lista.filtros.initialized
		setSelectedListaAnimales(lista)
	}

	let total = 0
	if (stock) {
		for (let clase of stock) {
			total += clase.total
		}

		total = numberWithSeparator('' + total)
	}

	const downloadData = JSON.stringify({
		soloIdentificados: filtros.soloIdentificados,
		establecimientoId: filtros.establecimientoId,
		propietarioId: filtros.propietarioId,
		orden: {
			campo: 'numCaravana',
			direccion: 'asc'
		}
	})


	// render
	return (
		<Container fluid id="stock" className="vh-100">
			{/* Header */}
			<Row>
				<Col className="mt-2 d-flex align-items-center">
					<h1>Stock</h1>
					<div>
						<Button className="ms-3" onClick={performUpdate} variant="primary" size="sm">
							<i className="fa fa-refresh"></i> Actualizar
						</Button>
					</div>
				</Col>
			</Row>
			<hr />
			{isLoadingStock ? (
				<Row>
					<Col>
						<LoadingSpinner />
					</Col>
				</Row>
			) : (
				<>
					<Row>
						<Col>
							<Button onClick={() => setShowFiltrosPanel(true)} className="float-end"><i className="fas fa-filter" title="Filtros"></i></Button>
							<TreeViewWithArrows data={convertData(stock, 0)} onSelect={handleSelect} />
							<h4 className="mt-4">Total: {total}</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<DownloadButton
								url={getBaseUrl() + `u/ver-stock/descargar-xlsx?filtros=${downloadData}`}
								whileDownloading={<i className="fa fa-spinner fa-spin"></i>}
								variant="success"
								title="Descargar Excel"
							>
								Descargar <i className="fa fa-file-excel"></i>
							</DownloadButton>
						</Col>
					</Row>
				</>
			)}
			{showFiltrosPanel && <FiltrosPanel
				onClose={closeFiltrosPanel}
				establecimientos={establecimientos}
				propietarios={propietarios}
				filtros={filtros}
				setFiltros={setFiltros}
			/>}
			{selectedListaAnimales && <ListaAnimalesPanel
				onClose={closeListaAnimalesPanel}
				categoria={selectedListaAnimales}
				propietarios={propietarios}
				establecimientos={establecimientos}
			/>}
		</Container >
	)
}
