import { useState } from 'react'
import Table from 'react-bootstrap/Table'
import SortIcon from './SortIcon'

import "./SortableTable.css"

// Este es el componente SortableTable que recibe los datos, las columnas y la función de cambio de orden como props
export default function SortableTable(props) {
	const { datos, columns, cambioOrden, ordenInicial, loading } = props

	const [sortedColumn, setSortedColumn] = useState(ordenInicial.campo)
	const [icons, setIcons] = useState(
		// inicializa el estado con un objeto que tiene el key de cada columna y el icono null
		columns.reduce((obj, col) => {
			obj[col.key] = col.sorted || null
			return obj
		}, {})
	)
	
	const handleSort = (key, direction) => {
		cambioOrden({ campo: key, direccion: direction })
		setSortedColumn(key)
	}

	const toggleDirection = (direction) => {
		return direction === 'asc' ? 'desc' : 'asc'
	}

	const handleMouseEnter = (key) => {
		const newIcons = {}
		newIcons[key] = key === sortedColumn ? icons[sortedColumn] : 'desc'
		for (let col of columns) {
			if (col.key !== key) {
				newIcons[col.key] = col.key === sortedColumn ? icons[sortedColumn] : null
			}
		}

		setIcons(newIcons)
	}

	const handleMouseLeave = (key) => {
		const newIcons = { ...icons }
		if (key !== sortedColumn) {
			newIcons[key] = null
		}

		setIcons(newIcons)
	}

	const handleClick = (key) => {
		const newIcons = {}
		const icon = icons[key]
		newIcons[key] = toggleDirection(icon)

		for (let col of columns) {
			if (col.key !== key) {
				newIcons[col.key] = null
			}
		}
		
		setIcons(newIcons)
		handleSort(key, newIcons[key])
	}

	return (
		<Table striped bordered hover>
			<thead>
				<tr>
					{columns.map((col) => {
						return (
							<th
								key={col.key}
								className="th-with-icon"
								onMouseEnter={() => handleMouseEnter(col.key)}
								onMouseLeave={() => handleMouseLeave(col.key)}
								onClick={() => handleClick(col.key)}
							>
								<SortIcon direction={icons[col.key]} className={icons[col.key] === null ? 'hidden' : ''} />
								{col.label}
							</th>
						)
					})}
				</tr>
			</thead>
			<tbody style={loading ? { opacity: 0.3 } : null}>
				{datos.map((row, index) => (
					<tr key={index}>
						{columns.map((col) => (
							<td key={col.key}>
								{col.dataFormatter ? col.dataFormatter(row[col.key]) : row[col.key]}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</Table>
	)
}
