import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import { getShortDisplayDate } from 'utils/date';

export default function CustomScatterChart(props) {
	const { data, title = '', fillColor = "#8884d8", TooltipComponent = null, width = 400, height = 400, xFormat = {}, yFormat = {}, ...rest } = props

	const defaultXFormat = {
		dataKey: 'x',
		type: 'number'
	}

	const defaultYFormat = {
		dataKey: 'y',
		type: 'number'
	}

	const xAxisFormat = { ...defaultXFormat, ...xFormat }
	const yAxisFormat = { ...defaultYFormat, ...yFormat }

	return (
		<ScatterChart width={width} height={height} {...rest}>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis
				{...xAxisFormat}
			/>
			<YAxis
				{...yAxisFormat}
			/>
			<Tooltip content={TooltipComponent ? <TooltipComponent data={data} /> : null} />
			<Legend verticalAlign="top" height={36} />
			<Scatter name={title} data={data} fill={fillColor} />
		</ScatterChart>
	)
}
