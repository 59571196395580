import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import SelectorAnimal from '../SelectorAnimal'
import AnimalSeleccionado from '../AnimalSeleccionado'
import ListaAnimalesDeTarea from '../ListaAnimalesDeTarea'
import TareaDiagnosticoPrenezProcesarMasDetalles from './DiagnosticoPrenezProcesarMasDetalles'
import { roundToTwo } from 'utils/general'


export default function DiagnosticoPrenezProcesar(props) {
	const { config, tarea, onClose, onProcesar, verFinalizarTarea, setCambiosData } = props

	const [nuevosProcesados, setNuevosProcesados] = useState([])
	const [noProcesadosIds, setNoProcesadosIds] = useState([])
	const [tipoIdentificacion, setTipoIdentificacion] = useState('numCaravana')
	const [numIdentificacion, setNumIdentificacion] = useState('')
	const [seleccionado, setSeleccionado] = useState(null)
	const [mostrarMensajeYaProcesado, setMostrarMensajeYaProcesado] = useState(false)
	const [mostrarMensajeAccionYaTomada, setMostrarMensajeAccionYaTomada] = useState(false)
	// Para mantener lo seleccionado entre animales
	const [datosSeleccionados, setDatosSeleccionados] = useState({
		fecha: tarea.fechaParaCuando,
		tipo: '',
		profesional: ''
	})
	const [showDefinirDatosAdicionales, setShowDefinirDatosAdicionales] = useState(false)

	const mantenerSeleccionKeys = Object.keys(datosSeleccionados)
	const sinCambios = nuevosProcesados.length === 0 && noProcesadosIds.length === 0

	const numProcesados = tarea.listas[0].cantidadAnimalesYaProcesados + nuevosProcesados.length - noProcesadosIds.length
	const numPrenadas = tarea.listas[0].animales.filter(animal => animal.prenada && !noProcesadosIds.includes(animal.id)).length + nuevosProcesados.filter(animal => animal.confirmado).length
	const porcentajePrenadas = roundToTwo(numPrenadas / numProcesados)

	useEffect(() => {
		if (!sinCambios) {
			let textoCambios = ''
			if (nuevosProcesados.length) {
				textoCambios += `${nuevosProcesados.length} ${(nuevosProcesados.length === 1 ? 'animal procesado' : 'animales procesados')}`
			}

			if (noProcesadosIds.length) {
				textoCambios += textoCambios ? ' y ' : ''
				textoCambios += `${noProcesadosIds.length} ${(noProcesadosIds.length === 1 ? 'dato anterior eliminado' : 'datos anteriores eliminados')}`
			}

			setCambiosData({
				nombre: tarea.nombre,
				texto: textoCambios
			})
		} else {
			setCambiosData(null)
		}
	}, [nuevosProcesados, noProcesadosIds])

	const handleSave = () => {
		const serverData = {
			id: tarea.id,
			listas: [
				{
					id: tarea.listas[0].id, // id de la lista
					marcarProcesados: nuevosProcesados,
					marcarNoProcesadosIds: noProcesadosIds
				},
			],
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleClose = () => {
		onClose()
	}

	const handleSeleccionarAnimal = (animal) => {
		console.log('animal', animal)
		const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === animal.id)
		if (yaProcesadoIndex !== -1) {
			animal.nuevosDatos = nuevosProcesados[yaProcesadoIndex]
		} else {
			animal.nuevosDatos = {
				id: animal.id,
				fecha: datosSeleccionados.fecha,
				tipo: datosSeleccionados.tipo,
				profesional: datosSeleccionados.profesional,
				vigente: true,
				servicioId: '',
				fechaParicionDesde: '',
				fechaParicionHasta: ''
			}
		}

		animal.nuevosDatos['datosCompletados'] = verSiCompletadoDatosAnimal(animal.nuevosDatos)

		setSeleccionado(animal)
	}

	const changeNumIdentificacion = (numId) => {
		setNumIdentificacion(numId)

		/*
		* Si el animal ya fue procesado y no está entre los a eliminar, entonces muestra un aviso de que ya fue procesado y no selecciona el animal.
		* Si el animal fue recién procesado o está marcado para eliminar, muestra un aviso de la acción ya tomada, pero selecciona el animal
		*/
		const animal = tarea.listas[0].animales.find((a) => a[tipoIdentificacion] === numId)
		if (animal) {
			if (animal.procesado) {
				setMostrarMensajeYaProcesado(true)
				setSeleccionado(null)
			} else {
				setMostrarMensajeYaProcesado(false)

				const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === animal.id)
				const noProcesadoIndex = noProcesadosIds.findIndex((a) => a === animal.id)
				if (yaProcesadoIndex !== -1 || noProcesadoIndex !== -1) {
					setMostrarMensajeAccionYaTomada(true)
				} else {
					setMostrarMensajeAccionYaTomada(false)
				}
				handleSeleccionarAnimal(animal)
			}
		} else {
			setSeleccionado(null)
			setMostrarMensajeAccionYaTomada(false)
			setMostrarMensajeYaProcesado(false)
		}
	}

	const handleChangeData = (valor, cual) => {
		const copia = { ...seleccionado }
		copia.nuevosDatos = { ...seleccionado.nuevosDatos }
		copia.nuevosDatos[cual] = valor

		if (mantenerSeleccionKeys.includes(cual)) {
			setDatosSeleccionados(prev => ({
				...prev,
				[cual]: valor
			}))
		}

		copia.nuevosDatos['datosCompletados'] = verSiCompletadoDatosAnimal(copia.nuevosDatos)

		setSeleccionado(copia)
	}

	const verSiCompletadoDatosAnimal = (datos) => {
		return datos.fecha !== '' && datos.tipo !== '' && datos.confirmado !== undefined
	}

	const deseleccionar = () => {
		setSeleccionado(null)
		setNumIdentificacion('')
		setMostrarMensajeAccionYaTomada(false)
		setMostrarMensajeYaProcesado(false)
	}

	const handleConfirmarAnimal = () => {
		const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === seleccionado.id)

		const procesados = [...nuevosProcesados]
		const datosDeProcesado = seleccionado.nuevosDatos
		if (!datosDeProcesado.servicioId) {
			delete datosDeProcesado.servicioId
		}
		if (yaProcesadoIndex !== -1) {
			procesados[yaProcesadoIndex] = datosDeProcesado
		} else {
			procesados.push(datosDeProcesado)
		}

		setNuevosProcesados(procesados)
		deseleccionar()
	}

	const handleEliminarProcesadoDeAnimal = (animal) => {
		setNoProcesadosIds([...noProcesadosIds, animal.id])
		deseleccionar()
	}

	const handleCancelarNuevoProcesado = (animal) => {
		const yaProcesadoIndex = nuevosProcesados.findIndex((a) => a.id === animal.id)
		const procesadosCopy = [...nuevosProcesados.slice(0, yaProcesadoIndex), ...nuevosProcesados.slice(yaProcesadoIndex + 1)]
		setNuevosProcesados(procesadosCopy)
		deseleccionar()
	}

	const handleCancelarEliminarProcesadoDeAnimal = (animal) => {
		const noProcesadoIndex = noProcesadosIds.findIndex((a) => a === animal.id)
		const noProcesadosCopy = [...noProcesadosIds.slice(0, noProcesadoIndex), ...noProcesadosIds.slice(noProcesadoIndex + 1)]
		setNoProcesadosIds(noProcesadosCopy)
		deseleccionar()
	}

	const handleVerFinalizarTarea = () => {
		verFinalizarTarea(tarea)
	}

	return (
		<Container>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="ms-2">
							<h5>{tarea.nombre}</h5>
						</Col>
						<Col className="me-2 text-end">
							<Button
								onClick={handleVerFinalizarTarea}
								disabled={!sinCambios}
							>
								Finalizar Tarea
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-1">
				<Card.Body>
					<Row>
						<Col>
							<div><strong>Total procesados:</strong> {`${numProcesados} / ${tarea.listas[0].cantidadAnimales}`}</div>
							<div><strong>Total preñadas:</strong> {`${numPrenadas} / ${numProcesados}`}</div>
							<div><strong>Porcentaje preñadas:</strong> {`${(porcentajePrenadas ? porcentajePrenadas + '%' : '-')}`}</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-1">
				<Card.Body>
					<Row>
						<Col md="7">
							<ListaAnimalesDeTarea
								animales={tarea.listas[0].animales}
								recienProcesados={nuevosProcesados}
								recienEliminadoProcesadosIds={noProcesadosIds}
								onSeleccionar={handleSeleccionarAnimal}
								onEliminarProcesado={handleEliminarProcesadoDeAnimal}
								onCancelarNuevoProcesado={handleCancelarNuevoProcesado}
								onCancelarEliminarProcesado={handleCancelarEliminarProcesadoDeAnimal}
							/>
						</Col>
						<Col md="5">
							<div className="sticky-top">
								<SelectorAnimal
									tipoIdentificacion={tipoIdentificacion}
									setTipoIdentificacion={setTipoIdentificacion}
									numIdentificacion={numIdentificacion}
									changeNumIdentificacion={changeNumIdentificacion}
								/>
								<AnimalSeleccionado
									key={seleccionado?.id}
									animal={seleccionado}
									onChangeData={handleChangeData}
									onConfirmar={handleConfirmarAnimal}
									yaProcesado={mostrarMensajeYaProcesado}
									accionYaTomada={mostrarMensajeAccionYaTomada}
								>
									<Row>
										<Col>
											<Form.Label className="mt-2 mb-0">Tipo</Form.Label>
											<Form.Group className="d-flex align-items-center">
												{['Palpación', 'Ecografía'].map((tipoDiagnostico, i) => (
													<Form.Check
														key={`tipo-${i}`}
														className="ms-2"
														inline
														label={tipoDiagnostico}
														name="diagnostico-tipo"
														type="radio"
														id={`check-tipo-${i}`}
														checked={tipoDiagnostico === seleccionado?.nuevosDatos.tipo}
														onChange={(e) => handleChangeData(tipoDiagnostico, 'tipo')}
													/>
												))}
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Form.Group className="col mb-2" controlId="diagnostico-prenez-profesional">
											<Form.Label className="mb-1">Profesional</Form.Label>
											<Form.Control
												type="text"
												size="sm"
												value={seleccionado?.nuevosDatos.profesional || ''}
												onChange={(e) => handleChangeData(e.target.value, 'profesional')}
											/>
										</Form.Group>
									</Row>
									<Row>
										<Form.Label className="mb-0">¿Preñada?</Form.Label>
										<Form.Group className="col d-flex align-items-center">
											<Form.Check
												className="ms-2"
												inline
												label="Sí"
												name="diagnostico-confirmado"
												type="radio"
												id={`check-confirmado-si`}
												checked={seleccionado?.nuevosDatos.confirmado === true}
												onChange={(e) => handleChangeData(true, 'confirmado')}
											/>
											<Form.Check
												className="ms-2"
												inline
												label="No"
												name="diagnostico-confirmado"
												type="radio"
												id={`check-confirmado-no`}
												checked={seleccionado?.nuevosDatos.confirmado === false}
												onChange={(e) => handleChangeData(false, 'confirmado')}
											/>
										</Form.Group>
									</Row>
									<Row>
										{seleccionado?.nuevosDatos.confirmado && (
											<TareaDiagnosticoPrenezProcesarMasDetalles
												key={seleccionado.id}
												animal={seleccionado}
												guardarCambios={handleChangeData}
												show={showDefinirDatosAdicionales}
												setShow={setShowDefinirDatosAdicionales}
											/>
										)}
									</Row>
								</AnimalSeleccionado>
							</div>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={sinCambios}
							>
								Procesar tarea
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		</Container>
	)
}