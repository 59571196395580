import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import WizardStepFooter from 'components/Wizard/WizardStepFooter'
import { TIPOS_ADQUISICIONES, TIPOS_DOCUMENTOS } from 'config/movimientoAnimales'

export default function AdquisicionDestinoVendedor(props) {
	const { config, data: originalData = {}, isCompleted: isOriginallyCompleted, updateData, step, numSteps, prevStep, enableSave, disableFinish } = props

	const tiposAdquisiciones = TIPOS_ADQUISICIONES
	const tiposDocumentos = TIPOS_DOCUMENTOS

	const [data, setData] = useState({ ...originalData })

	const opcionesFiltroPropietario = [
		...config.propietarios.map(propietario => ({
			label: `${propietario.nombre} ${propietario.apellido}`,
			value: propietario.id
		}))
	]

	const opcionesFiltroEstablecimiento = [
		...config.establecimientos.map(establecimiento => ({
			label: establecimiento.nombre,
			value: establecimiento.id
		}))
	]

	const verSiHayCambios = () => {
		const cambios = !(
			data.tipo === originalData.tipo
			&& Number(data.propietarioDestinoId) === Number(originalData.propietarioDestinoId)
			&& Number(data.establecimientoDestinoId) === Number(originalData.establecimientoDestinoId)
			&& data.propietarioOrigenNombre === originalData.propietarioOrigenNombre
			&& data.propietarioOrigenTipoDocumento === originalData.propietarioOrigenTipoDocumento
			&& data.propietarioOrigenDocumento === originalData.propietarioOrigenDocumento
			&& data.propietarioOrigenNacionalidad === originalData.propietarioOrigenNacionalidad
			&& data.establecimientoOrigenNombre === originalData.establecimientoOrigenNombre
			&& data.establecimientoOrigenCodigo === originalData.establecimientoOrigenCodigo
		)

		return cambios
	}

	const verSiCompleto = () => {
		const completo = data.propietarioOrigenNombre.trim() !== '' && data.propietarioOrigenTipoDocumento.trim() !== '' && data.propietarioOrigenDocumento.trim() !== ''
			&& data.propietarioOrigenNacionalidad.trim() !== '' && data.establecimientoOrigenNombre.trim() !== '' && data.establecimientoOrigenCodigo.trim() !== ''

		return completo
	}

	const hayModificaciones = verSiHayCambios()
	const isCompleted = verSiCompleto()

	const handleChange = (valor, cual) => {
		setData(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleFooterAction = (direction, goToNext = false) => {
		if (direction === 'back') {
			prevStep()
		} else {
			updateData(data, isCompleted, goToNext)
		}
	}

	return (
		<Card>
			<Card.Body>
				<Row>
					<Form.Group controlId="adquisicion-tipo" className="col-sm-6 mb-1">
						<Form.Label className="mb-1">Tipo</Form.Label>
						<Form.Select
							size="sm"
							value={data.tipo}
							onChange={(e) => handleChange(e.target.value, 'tipo')}
						>
							{tiposAdquisiciones.map((opcion, i) => {
								return (
									<option value={opcion.value} key={`adquisicion-tipo-${opcion.value}`}>
										{opcion.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
				</Row>
				<h4 className="mt-2 mb-0"><strong>Destino</strong></h4>
				<Row>
					<Form.Group controlId="adquisicion-propietarioDestinoId" className="col-sm-6 mb-1">
						<Form.Label className="mb-1">Propietario</Form.Label>
						<Form.Select
							size="sm"
							value={data.propietarioDestinoId}
							onChange={(e) => handleChange(e.target.value, 'propietarioDestinoId')}
						>
							{opcionesFiltroPropietario.map((opcion, i) => {
								return (
									<option value={opcion.value} key={`adquisicion-propietario-${opcion.value}`}>
										{opcion.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
					<Form.Group controlId="adquisicion-establecimientoDestinoId" className="col mb-1">
						<Form.Label className="mb-1">Establecimiento</Form.Label>
						<Form.Select
							size="sm"
							value={data.establecimientoDestinoId}
							onChange={(e) => handleChange(e.target.value, 'establecimientoDestinoId')}
						>
							{opcionesFiltroEstablecimiento.map((opcion, i) => {
								return (
									<option
										key={`adquisicion-establecimiento-${opcion.value}`}
										value={opcion.value}
									>
										{opcion.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
				</Row>
				<h4 className="mt-2 mb-0"><strong>Vendedor</strong></h4>
				<Row>
					<Form.Group className="col mb-1" controlId="adquisicion-propietarioOrigenNombre">
						<Form.Label className="mb-1">Nombre</Form.Label>
						<Form.Control
							value={data.propietarioOrigenNombre}
							onChange={(e) => handleChange(e.target.value, 'propietarioOrigenNombre')}
							type="text"
							size="sm"
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="col mb-1" controlId="adquisicion-propietarioOrigenTipoDocumento">
						<Form.Label className="mb-1">Tipo de Documento</Form.Label>
						<Form.Select
							value={data.propietarioOrigenTipoDocumento}
							onChange={(e) => handleChange(e.target.value, 'propietarioOrigenTipoDocumento')}
							size="sm"
						>
							{tiposDocumentos.map((e, i) => {
								return (
									<option value={e.value} key={`selectPropietarioOrigenTipoDocumento-${e.value}`}>
										{e.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="col mb-1" controlId="adquisicion-propietarioOrigenDocumento">
						<Form.Label className="mb-1">Núm. documento</Form.Label>
						<Form.Control
							value={data.propietarioOrigenDocumento}
							onChange={(e) => handleChange(e.target.value, 'propietarioOrigenDocumento')}
							type="text"
							size="sm"
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="col mb-1" controlId="adquisicion-propietarioOrigenNacionalidad">
						<Form.Label className="mb-1">Nacionalidad</Form.Label>
						<Form.Control
							value={data.propietarioOrigenNacionalidad}
							onChange={(e) => handleChange(e.target.value, 'propietarioOrigenNacionalidad')}
							type="text"
							size="sm"
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="col mb-1" controlId="adquisicion-establecimientoNombre">
						<Form.Label className="mb-1">Establecimiento nombre</Form.Label>
						<Form.Control
							value={data.establecimientoOrigenNombre}
							onChange={(e) => handleChange(e.target.value, 'establecimientoOrigenNombre')}
							type="text"
							size="sm"
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="col mb-1" controlId="adquisicion-establecimientoCodigo">
						<Form.Label className="mb-1">Establecimiento código</Form.Label>
						<Form.Control
							value={data.establecimientoOrigenCodigo}
							onChange={(e) => handleChange(e.target.value, 'establecimientoOrigenCodigo')}
							type="text"
							size="sm"
						/>
					</Form.Group>
				</Row>
			</Card.Body>
			<WizardStepFooter
				step={step}
				numSteps={numSteps}
				handleAction={handleFooterAction}
				alreadyCompleted={isOriginallyCompleted}
				completed={isCompleted}
				modified={hayModificaciones}
				enableSave={enableSave}
				disableFinish={disableFinish}
			/>
		</Card>
	)
}