import React from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { DEFAULT_SELECTED_COLS, DEFAULT_SELECTED_ORDER_FIELD } from 'config/seleccionAnimales'

import './seleccion-animales.css'
import { compararNumeros } from 'utils/comparePropertyAsNumber'

export default function MostrarListaAnimales(props) {
	const { seleccionados, deseleccionarAnimal, seleccionarExactamente = 0, mostrarAgregados = false, cols = DEFAULT_SELECTED_COLS, orderField = DEFAULT_SELECTED_ORDER_FIELD } = props

	const seleccionadosOrdenados = orderField ? seleccionados.todos.sort(compararNumeros('numCaravana')) : seleccionados.todos

	return (
		<div className="lista-seleccionados">
			<Card className="mt-3">
				<Card.Header as='h5'>Animales seleccionados</Card.Header>
				<Card.Body>
					{seleccionados.todos.length ? (
						<>
							<small>{seleccionados.todos.length}{seleccionarExactamente ? `/${seleccionarExactamente}` : ''} {seleccionados.length === 1 ? 'animal seleccionado' : 'animales seleccionados'}.</small>
							<Table responsive striped bordered hover size="sm">
								<thead>
									<tr>
										{cols.map((col) => (
											<th key={col.key}>{col.label}</th>
										))}
										<th></th>
									</tr>
								</thead>
								<tbody>
									{seleccionadosOrdenados.map((animal) => (
										<tr key={animal.id} className={mostrarAgregados && seleccionados.agregadosIds.includes(animal.id) ? 'animal-agregado' : ''}>
											{cols.map((col) => (
												<td key={col.key}>{animal[col.key]}</td>
											))}
											<td className="text-center">
												<Button size="sm"
													onClick={(e) => deseleccionarAnimal(animal)}
													variant="danger"
												>
													<i className="fa-solid fa-minus"></i>
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</>
					) : (
						<Alert variant="info">No hay animales seleccionados</Alert>
					)}

				</Card.Body>
			</Card>
		</div>
	)
}
