import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Datetime from "react-datetime"
import { handleChangeFecha } from 'utils/date'
import { ButtonWithTooltip } from 'components/ButtonWithTooltip'
import { DetallesAnimalTable } from 'components/DetallesAnimalTable'
import { REGLAS_CAMBIO_CATEGORIA } from 'config/cambiosCategorias'

export default function DefinirCambiosCategorias(props) {
	const { animales, datos, changeData, verSiCompletado, onAtras, opcionesCategorias } = props

	const [numAct, setNumAct] = useState(0)

	useEffect(() => {
		if (numAct > 0 && !datos[numAct].fecha) {
			changeData(datos[numAct - 1].fecha, 'fecha', numAct)
		}
	}, [numAct])

	const siguienteDisabledText = verSiCompletado(datos[numAct]) ? '' : 'Se debe completar para pasar al siguiente animal'
	const categoriasDisponibles = opcionesCategorias.filter(opcion => REGLAS_CAMBIO_CATEGORIA[`${animales[numAct].categoriaId},${animales[numAct].sexo}`].includes(opcion.id))

	const handleAtras = () => {
		onAtras()
	}

	return (
		<>
			<Row>
				<Col className="text-start">
					<Button
						variant="primary"
						className="ms-2"
						onClick={handleAtras}
					>
						<i className="fa fa-caret-left"></i> Atrás
					</Button>
				</Col>
			</Row>
			<Row>
				<Col sm="6" lg="4">
					<Row>
						<Col>
							<Card.Title className="mt-3">Animal seleccionado <strong>{numAct + 1}</strong>/{datos.length}</Card.Title>
							<DetallesAnimalTable animal={animales[numAct]} />
						</Col>
					</Row>
					{animales.length > 1 && (
						<Row className="mb-3">
							<Col className="d-flex justify-content-around">
								<Button
									variant="success"
									onClick={() => setNumAct(prev => prev - 1)}
									disabled={numAct === 0}
								>
									<i className="fa fa-caret-left"></i> ANTERIOR
								</Button>
								<ButtonWithTooltip
									tooltipText={siguienteDisabledText}
									variant="success"
									onClick={() => setNumAct(prev => prev + 1)}
									disabled={numAct === datos.length - 1 || siguienteDisabledText !== ''}
								>
									SIGUIENTE <i className="fa fa-caret-right"></i>
								</ButtonWithTooltip>
							</Col>
						</Row>
					)}
				</Col>
				<Col sm="6" lg="8">
					<div className="border p-3">
						<Row>
							<Col>
								<Form.Group controlId="txtFecha">
									<Form.Label className="mb-1">Fecha cambio</Form.Label>
									<Datetime
										key={`datetime-fecha-${numAct}${datos[numAct].fecha}`}
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										closeOnSelect={true}
										strictParsing={false}
										inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtFecha', className: 'form-control form-control-sm' }}
										value={datos[numAct].fecha}
										onChange={(date) => handleChangeFecha(date, changeData, 'fecha', numAct)}
									/>
								</Form.Group>
								<Form.Group controlId="categoriasAnimalesSelect-categoriaId">
									<Form.Label className="mb-1">Categoría</Form.Label>
									<Form.Select
										size="sm"
										value={datos[numAct].categoriaId}
										onChange={(e) => changeData(e.target.value, 'categoriaId', numAct)}
									>
										{categoriasDisponibles.map((opcion) => (
											<option
												value={opcion.id}
												key={`categoriasAnimalesSelect-categoria-${opcion.id}`}
											>
												{opcion.nombre}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</>
	)
}