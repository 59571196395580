import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Datetime from 'react-datetime'
import { DIAS_GESTACION_VACA } from "config/generales"
import { handleChangeFecha, addDays, getDaysDifference, getMonthName } from 'utils/date'
import { InfoIcon } from 'components/InfoIcon'

export default function TareaDiagnosticoPrenezProcesarMasDetalles(props) {
	const { animal, guardarCambios, show, setShow } = props

	console.log('animal', animal)

	const handleChange = (value, cual) => {
		guardarCambios(value, cual)
	}

	const getServicioJSX = (servicio) => {
		let label = ''
		switch (servicio.tipo) {
			case 'iatf':
			case 'ia':
				label += servicio.fechaInicio.substring(0, 10)
				break
			case 'monta-natural':
				label += servicio.fechaInicio.substring(0, 10) + ' al ' + servicio.fechaFin.substring(0, 10)
				break
			default:

		}

		return (
			<Form.Group key={`diagnostico-prenez-servicio-${servicio.id}`} controlId={`diagnostico-prenez-servicio-${servicio.id}`}>
				<Form.Check
					type='radio'
					inline
					value={servicio.id}
					checked={animal.nuevosDatos.servicioId === servicio.id}
					onChange={e => handleChange(servicio.id, 'servicioId')}
				/>
				<Form.Label style={{ lineHeight: '1.2' }}>
					{servicio.tipo} | <span>{label}</span>
				</Form.Label>
			</Form.Group>
		)
	}

	const getTableBodyJSX = () => {
		const ahora = new Date()
		let primerDia = new Date(ahora.getFullYear(), ahora.getMonth(), 1)
		let diaReferencia = addDays(-DIAS_GESTACION_VACA)
		let refParaNombre = primerDia

		// calcula el número de días que debería tener el feto para nacer en los siguientes 9 meses (incluyendo el actual)
		const meses = []
		for (let i = 0; i < 9; i++) {
			const ultimoDia = new Date(primerDia.getFullYear(), primerDia.getMonth() + 1, 0)

			meses.push({
				nombre: getMonthName(refParaNombre.getMonth(), false) + '-' + refParaNombre.getFullYear(),
				desde: getDaysDifference(diaReferencia, primerDia),
				hasta: getDaysDifference(diaReferencia, ultimoDia),
				tamano: (i < 3) ? 'GRANDE' : (i < 6) ? 'MEDIA' : 'CHICA'
			})

			refParaNombre = new Date(refParaNombre.getFullYear(), refParaNombre.getMonth() + 1, 1)
			primerDia = new Date(primerDia.getFullYear(), primerDia.getMonth() - 1, 1)
		}

		return meses.map(mes => (
			<tr key={`mes-${mes.nombre}`}>
				<td>{mes.nombre}</td>
				<td>{mes.desde}</td>
				<td>{mes.hasta}</td>
				<td>{mes.tamano}</td>
			</tr>
		))
	}

	const getJSX = () => {
		return (
			<div id="diagnostico-prenez-extra">
				{animal.serviciosVigentes.length > 0 && (
					<div id="diagnostico-prenez-servicios">
						<strong>¿A qué servicio corresponde la preñez?</strong>
						{animal.serviciosVigentes.map((servicio) =>
							getServicioJSX(servicio)
						)}
						<Form.Group controlId={`diagnostico-prenez-servicio-otro`}>
							<Form.Check
								type='radio'
								inline
								value=''
								checked={animal.nuevosDatos.servicioId === ''}
								onChange={e => handleChange('', 'servicioId')}
							/>
							<Form.Label style={{ lineHeight: '1.2' }}>
								Otro
							</Form.Label>
						</Form.Group>
					</div>
				)}
				<div>
					<strong>¿Rango de fecha probable de parición?</strong>
					<Form.Group controlId="fecha-paricion-desde">
						<Form.Label>Desde</Form.Label>
						<Datetime
							className="mb-1"
							dateFormat="YYYY-MM"
							timeFormat={false}
							value={animal.nuevosDatos.fechaParicionDesde.substr(0, 7)}
							inputProps={{ placeholder: 'YYYY-mm', id: 'fecha' }}
							closeOnSelect={true}
							onChange={(date) => handleChangeFecha(date, handleChange, 'fechaParicionDesde')}
						/>
					</Form.Group>
					<Form.Group controlId="fecha-paricion-hasta">
						<Form.Label>Hasta</Form.Label>
						<Datetime
							className="mb-1"
							dateFormat="YYYY-MM"
							timeFormat={false}
							initialValue={animal.nuevosDatos.fechaParicionDesde.substr(0, 7)}
							inputProps={{ placeholder: 'YYYY-mm', id: 'fecha' }}
							closeOnSelect={true}
							onChange={(date) => handleChangeFecha(date, handleChange, 'fechaParicionHasta')}
						/>
					</Form.Group>
				</div>
				<div className="text-end mt-3">
					<small>
						Tabla de referencia <InfoIcon>
							<Table size="sm" bordered hover>
								<thead>
									<tr>
										<th>Mes de parto</th>
										<th colSpan="3">Tamaño de feto</th>
									</tr>
								</thead>
								<tbody>
									{getTableBodyJSX()}
								</tbody>
							</Table>
						</InfoIcon>
					</small>
				</div>
			</div>
		)
	}

	return (
		<Col>
			<Form.Group controlId="check-extra">
				<Form.Check
					type="checkbox"
					label="Definir detalles adicionales"
					checked={show}
					onChange={(e) => setShow(e.target.checked)}
				/>
			</Form.Group>
			{show && getJSX()}
		</Col>
	)
}
