import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import { getShortDisplayDate } from 'utils/date'

export default function CambioCategoriaVer(props) {
	const { config, tarea } = props

	const [listaI, setListaI] = useState(0)

	const handleSelect = (cual) => {
		if (cual === listaI) {
			setListaI(null)
		} else {
			setListaI(cual)
		}
	}

	const totalesCategorias = {}
	tarea.cambios.forEach(cambio => {
		const key = `${cambio.categoriaAnteriorNombre} -> ${cambio.categoriaNombre}`
		if (!totalesCategorias[key]) {
			totalesCategorias[key] = 0
		}

		totalesCategorias[key]++
	})

	// Convertir el objeto a un array de entradas para mapearlo en la tabla
	const totalesArray = Object.entries(totalesCategorias)

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total cambios de categoría</th>
										<td>{tarea.listas[0].animales.length}</td>
									</tr>
									{totalesArray.map(([categorias, total]) => {
										
										return (
											<tr key={categorias}>
												
												<td>{categorias}</td>
												<td>{total}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card.Title className="mt-3">Eventos</Card.Title>
			<Accordion
				activeKey={listaI}
				onSelect={handleSelect}
			>
				{tarea.cambios.map((cambio, i) => {
					const propietario = config.propietarios.find(propietario => propietario.id === cambio.animal.propietarioId)
					const establecimiento = config.establecimientos.find(establecimiento => establecimiento.id === cambio.animal.establecimientoId)
					const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(cambio.animal.categoriaId))

					return (
						<Accordion.Item key={`lista-${i}`} eventKey={i}>
							<Accordion.Header>
								<strong>Fecha:</strong>&nbsp;{getShortDisplayDate(cambio.fecha)}
							</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col lg={6}>
										<Table bordered hover>
											<tbody>
												<tr>
													<th># Caravana</th>
													<td>{cambio.animal.numCaravana ?? '-'}</td>
												</tr>
												<tr>
													<th># Chip</th>
													<td>{cambio.animal.numChip ?? '-'}</td>
												</tr>
												<tr>
													<th># Tatuaje</th>
													<td>{cambio.animal.numTatuaje ?? '-'}</td>
												</tr>
												<tr>
													<th>Categoría</th>
													<td>{cambio.categoriaNombre}</td>
												</tr>
												<tr>
													<th>Sexo</th>
													<td>{cambio.animal.sexo}</td>
												</tr>
												<tr>
													<th>Propietario</th>
													<td>{`${propietario.nombre} ${propietario.apellido}`}</td>
												</tr>
												<tr>
													<th>Establecimiento</th>
													<td>{establecimiento.nombre}</td>
												</tr>
												<tr className="table-info">
													<th>Categoría anterior</th>
													<td>{cambio.categoriaAnteriorNombre}</td>
												</tr>
											</tbody>
										</Table>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
					)
				})}
			</Accordion>
		</Container >
	)
}