import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import WizardStepFooter from 'components/Wizard/WizardStepFooter'
import { TIPOS_SALIDAS, TIPOS_DOCUMENTOS } from 'config/movimientoAnimales'

export default function SalidaOrigenDestino(props) {
	const { config, data: originalData = {}, isCompleted: isOriginallyCompleted, updateData, step, numSteps, prevStep, enableSave, disableFinish } = props

	const tiposSalidas = TIPOS_SALIDAS
	const tiposDocumentos = TIPOS_DOCUMENTOS

	const [data, setData] = useState({ ...originalData })

	useEffect(() => {
		if (data.dentroDeCuenta) {
			inicializarDestino()
		}
	}, [data.dentroDeCuenta])

	useEffect(() => {
		if (data.dentroDeCuenta) {
			ajustarDestino()
		}
	}, [data.propietarioOrigenId, data.establecimientoOrigenId, data.propietarioDestinoId])

	const inicializarDestino = () => {
		let propietarioDestinoId = Number(data.propietarioOrigenId)
		let establecimientoDestinoId = Number(config.establecimientos[0].id)

		if (config.establecimientos.length > 1) {
			if (establecimientoDestinoId === Number(data.establecimientoOrigenId)) {
				establecimientoDestinoId = Number(config.establecimientos[1].id)
			}
		} else {
			if (Number(data.propietarioOrigenId) === propietarioDestinoId) {
				propietarioDestinoId = Number(config.propietarios[0].id)
				if (propietarioDestinoId === Number(data.propietarioOrigenId)) {
					propietarioDestinoId = Number(config.propietarios[1].id)
				}
			}
		}

		setData(prev => ({
			...prev,
			propietarioDestinoId,
			establecimientoDestinoId
		}))
	}

	// setea los selects de propietario y establecimiento destino al primero disponible teniendo en cuenta los de origen
	const ajustarDestino = () => {
		// Si son iguales, trata de mantener el propietario y poner el primer establecimiento posible
		if (Number(data.propietarioOrigenId) === Number(data.propietarioDestinoId) && Number(data.establecimientoOrigenId) === Number(data.establecimientoDestinoId)) {
			let propietarioDestinoId = Number(data.propietarioDestinoId)
			let establecimientoDestinoId = Number(config.establecimientos[0].id)

			if (config.establecimientos.length > 1) {
				if (establecimientoDestinoId === Number(data.establecimientoOrigenId)) {
					establecimientoDestinoId = Number(config.establecimientos[1].id)
				}
			} else {
				if (Number(data.propietarioOrigenId) === propietarioDestinoId) {
					propietarioDestinoId = Number(config.propietarios[0].id)
					if (propietarioDestinoId === Number(data.propietarioOrigenId)) {
						propietarioDestinoId = Number(config.propietarios[1].id)
					}
				}
			}

			setData(prev => ({
				...prev,
				propietarioDestinoId,
				establecimientoDestinoId
			}))
		}
	}

	const opcionesFiltroPropietario = [
		...config.propietarios.map(propietario => ({
			label: `${propietario.nombre} ${propietario.apellido}`,
			value: propietario.id
		}))
	]

	const opcionesFiltroEstablecimiento = [
		...config.establecimientos.map(establecimiento => ({
			label: establecimiento.nombre,
			value: establecimiento.id
		}))
	]

	const verSiHayCambios = () => {
		let cambios = false
		if (data.dentroDeCuenta) {
			cambios = !(
				data.tipo === originalData.tipo
				&& data.dentroDeCuenta === originalData.dentroDeCuenta
				&& Number(data.propietarioOrigenId) === Number(originalData.propietarioOrigenId)
				&& Number(data.establecimientoOrigenId) === Number(originalData.establecimientoOrigenId)
				&& Number(data.propietarioDestinoId) === Number(originalData.propietarioDestinoId)
				&& Number(data.establecimientoDestinoId) === Number(originalData.establecimientoDestinoId)
			)
		} else {
			cambios = !(
				data.tipo === originalData.tipo
				&& data.dentroDeCuenta === originalData.dentroDeCuenta
				&& Number(data.propietarioOrigenId) === Number(originalData.propietarioOrigenId)
				&& Number(data.establecimientoOrigenId) === Number(originalData.establecimientoOrigenId)
				&& data.propietarioDestinoNombre === originalData.propietarioDestinoNombre
				&& data.propietarioDestinoTipoDocumento === originalData.propietarioDestinoTipoDocumento
				&& data.propietarioDestinoDocumento === originalData.propietarioDestinoDocumento
				&& data.propietarioDestinoNacionalidad === originalData.propietarioDestinoNacionalidad
				&& data.establecimientoDestinoNombre === originalData.establecimientoDestinoNombre
				&& data.establecimientoDestinoCodigo === originalData.establecimientoDestinoCodigo
			)
		}

		return cambios
	}

	const verSiCompleto = () => {
		let completo = false
		if (data.dentroDeCuenta) {
			completo = data.propietarioDestinoId !== '' && data.establecimientoDestinoId !== ''
		} else {
			completo = data.propietarioDestinoNombre.trim() !== '' && data.propietarioDestinoTipoDocumento.trim() !== '' && data.propietarioDestinoDocumento.trim() !== ''
				&& data.propietarioDestinoNacionalidad.trim() !== '' && data.establecimientoDestinoNombre.trim() !== '' && data.establecimientoDestinoCodigo.trim() !== ''
		}

		return completo
	}

	const hayModificaciones = verSiHayCambios()
	const isCompleted = verSiCompleto()

	const handleChange = (valor, cual) => {
		setData(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleFooterAction = (direction, goToNext = false) => {
		if (direction === 'back') {
			prevStep()
		} else {
			updateData(data, isCompleted, goToNext)
		}
	}

	return (
		<Card>
			<Card.Body>
				<Row>
					<Form.Group controlId="salida-tipo" className="col-sm-6 mb-1">
						<Form.Label className="mb-1">Tipo</Form.Label>
						<Form.Select
							size="sm"
							value={data.tipo}
							onChange={(e) => handleChange(e.target.value, 'tipo')}
						>
							{tiposSalidas.map((opcion, i) => {
								return (
									<option value={opcion.value} key={`salida-tipo-${opcion.value}`}>
										{opcion.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
				</Row>
				<h4 className="mt-2 mb-0"><strong>Origen</strong></h4>
				<Row>
					<Form.Group controlId="salida-propietarioOrigenId" className="col-sm-6 mb-1">
						<Form.Label className="mb-1">Propietario</Form.Label>
						<Form.Select
							size="sm"
							value={data.propietarioOrigenId}
							onChange={(e) => handleChange(e.target.value, 'propietarioOrigenId')}
						>
							{opcionesFiltroPropietario.map((opcion, i) => {
								return (
									<option value={opcion.value} key={`salida-propietario-${opcion.value}`}>
										{opcion.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
					<Form.Group controlId="salida-establecimientoOrigenId" className="col mb-1">
						<Form.Label className="mb-1">Establecimiento</Form.Label>
						<Form.Select
							size="sm"
							value={data.establecimientoOrigenId}
							onChange={(e) => handleChange(e.target.value, 'establecimientoOrigenId')}
						>
							{opcionesFiltroEstablecimiento.map((opcion, i) => {
								return (
									<option
										key={`salida-establecimiento-${opcion.value}`}
										value={opcion.value}
									>
										{opcion.label}
									</option>
								)
							})}
						</Form.Select>
					</Form.Group>
				</Row>
				<h4 className="mt-2 mb-0"><strong>Destino</strong></h4>
				<div className="d-flex mb-3" style={{ gap: 20 }}>
					<Form.Group>
						<Form.Check
							id="check-dentro-cuenta"
							type='radio'
							inline
							checked={data.dentroDeCuenta}
							onChange={e => handleChange(true, 'dentroDeCuenta')}
							disabled={config.propietarios.length === 1 && config.establecimientos.length === 1}
							className="me-2"
						/>
						<Form.Label htmlFor="check-dentro-cuenta" className="mb-0">
							Dentro de cuenta
						</Form.Label>
					</Form.Group>
					<Form.Group>
						<Form.Check
							id="check-fuera-cuenta"
							type='radio'
							inline
							checked={!data.dentroDeCuenta}
							onChange={e => handleChange(false, 'dentroDeCuenta')}
							className="me-2"
						/>
						<Form.Label htmlFor="check-fuera-cuenta" className="mb-0">
							Fuera de cuenta
						</Form.Label>
					</Form.Group>
				</div>
				{data.dentroDeCuenta ? (
					<>
						<Row>
							<Form.Group controlId="salida-propietarioDestinoId" className="col-sm-6 mb-1">
								<Form.Label className="mb-1">Propietario destino</Form.Label>
								<Form.Select
									size="sm"
									value={data.propietarioDestinoId}
									onChange={(e) => handleChange(e.target.value, 'propietarioDestinoId')}
								>
									{opcionesFiltroPropietario.map((opcion, i) => {
										return (
											<option
												key={`salida-propietarioDestino-${opcion.value}`}
												value={opcion.value}
												disabled={config.establecimientos.length === 1 && Number(data.propietarioOrigenId) === Number(opcion.value)}
											>
												{opcion.label}
											</option>
										)
									})}

								</Form.Select>
							</Form.Group>
							<Form.Group controlId="salida-establecimientoDestinoId" className="col-sm-6 mb-1">
								<Form.Label className="mb-1">Establecimiento destino</Form.Label>
								<Form.Select
									size="sm"
									value={data.establecimientoDestinoId}
									onChange={(e) => handleChange(e.target.value, 'establecimientoDestinoId')}
								>
									{opcionesFiltroEstablecimiento.map((opcion, i) => {
										return (
											<option
												key={`salida-establecimientoDestino-${opcion.value}`}
												value={opcion.value}
												disabled={Number(data.propietarioOrigenId) === Number(data.propietarioDestinoId) && Number(data.establecimientoOrigenId) === Number(opcion.value)}
											>
												{opcion.label}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
						</Row>
					</>
				) : (
					<>
						<Row>
							<Form.Group className="col mb-1" controlId="salida-propietarioDestinoNombre">
								<Form.Label className="mb-1">Nombre</Form.Label>
								<Form.Control
									value={data.propietarioDestinoNombre}
									onChange={(e) => handleChange(e.target.value, 'propietarioDestinoNombre')}
									type="text"
									size="sm"
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="col mb-1" controlId="salida-propietarioDestinoTipoDocumento">
								<Form.Label className="mb-1">Tipo de Documento</Form.Label>
								<Form.Select
									value={data.propietarioDestinoTipoDocumento}
									onChange={(e) => handleChange(e.target.value, 'propietarioDestinoTipoDocumento')}
									size="sm"
								>
									{tiposDocumentos.map((e, i) => {
										return (
											<option value={e.value} key={`selectPropietarioDestinoTipoDocumento-${e.value}`}>
												{e.label}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="col mb-1" controlId="salida-propietarioDestinoDocumento">
								<Form.Label className="mb-1">Núm. documento</Form.Label>
								<Form.Control
									value={data.propietarioDestinoDocumento}
									onChange={(e) => handleChange(e.target.value, 'propietarioDestinoDocumento')}
									type="text"
									size="sm"
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="col mb-1" controlId="salida-propietarioDestinoNacionalidad">
								<Form.Label className="mb-1">Nacionalidad</Form.Label>
								<Form.Control
									value={data.propietarioDestinoNacionalidad}
									onChange={(e) => handleChange(e.target.value, 'propietarioDestinoNacionalidad')}
									type="text"
									size="sm"
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="col mb-1" controlId="salida-establecimientoNombre">
								<Form.Label className="mb-1">Establecimiento nombre</Form.Label>
								<Form.Control
									value={data.establecimientoDestinoNombre}
									onChange={(e) => handleChange(e.target.value, 'establecimientoDestinoNombre')}
									type="text"
									size="sm"
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="col mb-1" controlId="salida-establecimientoCodigo">
								<Form.Label className="mb-1">Establecimiento código</Form.Label>
								<Form.Control
									value={data.establecimientoDestinoCodigo}
									onChange={(e) => handleChange(e.target.value, 'establecimientoDestinoCodigo')}
									type="text"
									size="sm"
								/>
							</Form.Group>
						</Row>
					</>
				)}
			</Card.Body>
			<WizardStepFooter
				step={step}
				numSteps={numSteps}
				handleAction={handleFooterAction}
				alreadyCompleted={isOriginallyCompleted}
				completed={isCompleted}
				modified={hayModificaciones}
				enableSave={enableSave}
				disableFinish={disableFinish}
			/>
		</Card>
	)
}