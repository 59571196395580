import React from 'react'
import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify'
import { encode } from 'js-base64'

import api from 'api/api'
import { saveLoginData } from 'utils/localstorage'

import logo from 'assets/logo2.png'
import sideImg from 'assets/login-side.jpeg'

const Login = (props) => {
	const { history } = props
	const [loading, setLoading] = useState(false)
	const [ruc, setRuc] = useState('')
	const [usuario, setUsuario] = useState('')
	const [contrasena, setContrasena] = useState('')

	const handleLogin = async (e) => {
		e.preventDefault()

		const headers = {
			auth: {
				username: usuario,
				password: contrasena
			}
		}

		setLoading(true)
		try {
			const { datos, msg } = await api.post('p/login', { ruc }, headers)
			const loginData = {
				usuario: {
					user: usuario,
					nombre: datos.usuario.nombre,
					apellido: datos.usuario.apellido,
					habilitado: datos.usuario.habilitado,
				},
				cuentaId: datos.usuario.cuentaId
			}
			saveLoginData(loginData, encode(`${usuario}:${contrasena}`))
			props.setLoggedIn(true)
			toast.info(msg)
			history.push('/inicio');
		} catch (error) {
			console.log(error)
			setLoading(false)
		}
	}

	return (
		<section className="vh-100" style={{ backgroundColor: '#826865', minHeight: 620 }}>
			<Container className="py-5 h-100 w-100">
				<Row className="d-flex justify-content-center align-items-center h-100 no-gutters">
					<Col md={10} xl={9}>
						<Card className="rounded">
							<Row className="g-0">
								<Col className="d-none d-md-block" md={7} lg={5}>
									<Card.Img
										variant="top"
										src={sideImg}
										alt="Login"
										className="fluid"
									/>
								</Col>
								<Col md={5} lg={7} className="d-flex align-items-center">
									<Card.Body className="p-4 p-sm-0 pt-sm-3 px-sm-4 pt-xl-4 px-xl-5 text-black">
										<Form onSubmit={handleLogin}>
											<div className="d-flex justify-content-center mb-4">
												<img alt="Vakappy" src={logo} width="120" />
											</div>

											<h5 className="mb-2 d-none d-xl-block">Accede a tu cuenta</h5>
											<Form.Group controlId="usuario">
												<Form.Label>RUC</Form.Label>
												<Form.Control
													type="text"
													value={ruc}
													onChange={(event) => setRuc(event.target.value)}
													required
													disabled={loading}
												/>
											</Form.Group>
											<Form.Group controlId="usuario">
												<Form.Label>Usuario</Form.Label>
												<Form.Control
													type="text"
													autoComplete="off"
													value={usuario}
													onChange={(event) => setUsuario(event.target.value)}
													required
													disabled={loading}
												/>
											</Form.Group>
											<Form.Group className="mb-3" controlId="password">
												<Form.Label>Contraseña</Form.Label>
												<Form.Control
													type="password"
													value={contrasena}
													onChange={(event) => setContrasena(event.target.value)}
													required
													disabled={loading}
												/>
											</Form.Group>

											<div className="d-grid gap-2">
												<Button className="pt-1 mb-4" type="submit" variant="primary" size="lg" disabled={loading}>
													{loading ? (
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
														/>
													) : 'Acceder'}
												</Button>
											</div>
											{/*
											<div className="d-grid">
												<a className="small text-muted" href="#!">¿Olvidaste tu contraseña?</a>
												<p className="pb-lg-1 mb-xl-0" style={{color: '#393f81'}}>
													¿No tienes una cuenta? <a href="#!" style={{color: '#393f81'}}>Regístrate acá</a>
												</p>
											</div>
											*/}
										</Form>
									</Card.Body>
								</Col>
							</Row>
							{/*
							<Row className="d-flex justify-content-center h-100 no-gutters ps-4 pb-4">
								<Col>
								<a href="#!" className="small text-muted me-4">Términos de uso.</a>
								<a href="#!" className="small text-muted">Políticas de privacidad</a>
								</Col>
							</Row>
							*/}
						</Card>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default Login