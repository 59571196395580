import { CustomScatterChart } from 'components/Charts'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import { differenceInDays, parseISO } from 'date-fns';
import { getShortDisplayDate } from 'utils/date';
import { getNextMultipleOfN } from 'utils/general';

export default function FichaPesajesTabContent(props) {
	const { pesoInicial, pesajes } = props

	const formattedData = pesajes.map((pesaje) => ({
		x: pesaje.fecha.substr(0, 10),
		y: pesaje.peso,
	}))

	const minYValue = Math.min(...formattedData.map(d => d.y))
	const maxYValue = Math.max(...formattedData.map(d => d.y))
	const yDomain = [minYValue < 100 ? minYValue : 100, maxYValue > 500 ? getNextMultipleOfN(maxYValue, 50) : 500]

	const lastIndex = formattedData.length - 1
	const lastWeight = formattedData[lastIndex]?.y
	const lastDate = formattedData[lastIndex]?.x
	const firstWeight = formattedData[0]?.y
	const totalGain = lastWeight ? `${lastWeight - firstWeight} kg` : '-'

	let lastGDP = '-'
	if (formattedData.length > 1) {
		const secondLastWeight = formattedData[lastIndex - 1].y
		const secondLastDate = parseISO(formattedData[lastIndex - 1].x)
		const lastDateParsed = parseISO(lastDate)
		const daysDifference = differenceInDays(lastDateParsed, secondLastDate)

		lastGDP = `${((lastWeight - secondLastWeight) / daysDifference).toFixed(2)}kg/día`
	}

	const CustomTooltip = ({ active, payload, data }) => {
		if (active && payload && payload.length) {
			const currentData = payload[0].payload
			const currentIndex = data.findIndex(item => item.x === currentData.x)

			const currentWeight = currentData.y
			let totalGain = '-'
			let dailyGain = '-'
			let accumulatedDailyGain = 0

			if (currentIndex > 0) {
				const previousWeight = data[currentIndex - 1].y
				const previousDate = parseISO(data[currentIndex - 1].x)
				const currentDate = parseISO(currentData.x)
				const daysDifference = differenceInDays(currentDate, previousDate)

				totalGain = `${currentWeight - previousWeight}kg`
				dailyGain = `${((currentWeight - previousWeight) / daysDifference).toFixed(2)}kg`

				if (currentIndex > 1) {
					const firstWeight = data[0].y
					const firstDate = parseISO(data[0].x)
					const totalDaysDifference = differenceInDays(currentDate, firstDate)

					accumulatedDailyGain = `${((currentWeight - firstWeight) / totalDaysDifference).toFixed(2)}kg/día`
				}
			}

			return (
				<div className="ficha-pesaje-tooltip">
					<div className="label">{getShortDisplayDate(currentData.x)}</div>
					<div className="intro">
						{`Peso: ${currentWeight}kg`}<br />
						{`Ganancia acumulada: ${accumulatedDailyGain || '-'}`}
					</div>
					<div className="sublabel">Con respecto al pesaje anterior</div>
					<div className="intro">
						{`Peso ganado: ${totalGain}`}<br />
						{`Ganancia diaria: ${dailyGain}`}<br />
					</div>
				</div>
			)
		}

		return null
	}

	return (
		<div className="tab-content-wrapper">
			<div className="col-12 col-sm-6">
				<Table size="sm">
					<tbody>
						<tr>
							<th>Peso inicial</th>
							<td>{pesoInicial ? `${pesoInicial} kg` : '-'}</td>
						</tr>
						<tr>
							<th>Último peso</th>
							<td>{lastWeight ? `${lastWeight} kg` : '-'}</td>
						</tr>
						<tr>
							<th>Fecha último pesaje</th>
							<td>{lastDate ? getShortDisplayDate(lastDate) : '-'}</td>
						</tr>
						<tr>
							<th>Última GDP</th>
							<td>{lastGDP}</td>
						</tr>
						<tr>
							<th>Ganancia total</th>
							<td>{totalGain}</td>
						</tr>
					</tbody>
				</Table>
			</div>
			<h2>Historial pesajes</h2>
			{pesajes.length ? (
				<div className="mb-5">
					<CustomScatterChart
						data={formattedData}
						TooltipComponent={CustomTooltip}
						xFormat={{
							tickFormatter: (date) => getShortDisplayDate(date),
							interval: 0,
							angle: -45,
							dy: 40,
							dx: -20,
							type: 'category'
						}}
						yFormat={{
							unit: 'kg',
							domain: yDomain
						}}
						margin={{ top: 20, right: 20, bottom: 80, left: 0 }}
						title="Pesajes"
					/>
				</div>
			) : (
				<Alert variant="info" className="mt-3">No hay pesajes del animal</Alert>
			)}
		</div>
	)
}