import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { IngresoPreexistenteVer } from './IngresoPreexistente'
import { PesajeVer } from './Pesaje'
import { SalidaVer } from './Salida'
import { ServicioVer } from './Servicio'
import { DiagnosticoPrenezVer } from './DiagnosticoPrenez'
import { AdquisicionVer } from './Adquisicion'
import { IdentificacionVer } from './Identificacion'
import { ParicionVer } from './Paricion'
import { MortandadVer } from './Mortandad'
import { ConsumoVer } from './Consumo'
import { CambioCategoriaVer } from './CambioCategoria'
import { SanitacionVer } from './Sanitacion'

export default function MostrarAccionVer(props) {
	const { config, tipo, tareaId, onClose } = props
	const [cancelToken, setCancelToken] = useState(null)
	const [tarea, setTarea] = useState(null)

	const handleClose = () => {
		onClose()
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		fetchTarea(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	const fetchTarea = async (cancelToken) => {
		try {
			const { datos: respuesta } = await api.get('u/tareas/' + tareaId, {
				cancelToken: cancelToken.token,
			})

			setTarea(respuesta)
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Petición cancelada', error.message)
			} else {
				toast.error('Error de conexión')
			}
		}
	}

	let contenido = ''
	switch (tarea?.tipo) {
		case 'carga-preexistente':
			contenido = <IngresoPreexistenteVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'pesaje':
			contenido = <PesajeVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'salida':
			contenido = <SalidaVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'servicio':
			contenido = <ServicioVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'diagnostico-prenez':
			contenido = <DiagnosticoPrenezVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'adquisicion':
			contenido = <AdquisicionVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'identificacion':
			contenido = <IdentificacionVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'sanitacion':
			contenido = <SanitacionVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'paricion':
			contenido = <ParicionVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'mortandad':
			contenido = <MortandadVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'consumo':
			contenido = <ConsumoVer config={config} tarea={tarea} onClose={handleClose} />
			break
		case 'cambio-categoria':
			contenido = <CambioCategoriaVer config={config} tarea={tarea} onClose={handleClose} />
			break
		default:
			contenido = <LoadingSpinner />
	}


	return (
		<Modal show={true} fullscreen onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					<Button
						className="rounded-pill me-2"
						variant="secondary"
						size="sm"
						onClick={handleClose}
					>
						<i className={`fa me-1 fa-arrow-left`}></i>
						Atrás
					</Button>
					Ver {tipo}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="bg-light rounded">
				{contenido}
			</Modal.Body>
			{/*
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={handleSave}>
						Guardar
					</Button>
				</Modal.Footer>
				*/}
		</Modal>
	)
}