import react, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { TIPOS_IDENTIFICACION } from 'config/generales'

export default function IngresoRango(props) {
	const { saveDisabled, cantidadAnimales, onClose, onSave } = props

	const [conIdentificacion, setConIdentificacion] = useState(true)
	const [data, setData] = useState({
		tipoIdentificacion: 'caravana',
		numInicial: '',
		carimbo: '',
		raza: '',
		color: ''
	})
	const tiposIdentificacion = TIPOS_IDENTIFICACION

	const handleChange = (valor, cual) => {
		setData(prev => ({
			...data,
			[cual]: valor
		}))
	}

	const handleClose = () => {
		onClose()
	}

	const handleSave = () => {
		const animalesData = {
			...data,
			conIdentificacion
		}
		
		onSave(animalesData, 'rango')
	}

	return (
		!isNaN(cantidadAnimales) && cantidadAnimales > 0 ? (
			<>
				<Row className="pt-3">
					<Col>
						<Form.Group className="mb-3" controlId="preexistente-crear-rango-identificador">
							<Form.Check
								type="checkbox"
								label="Con identificador"
								checked={conIdentificacion}
								onChange={(e) => setConIdentificacion(e.target.checked)}
							/>
						</Form.Group>
					</Col>
				</Row>
				{conIdentificacion ? (
					<Row>
						<Col sm="6" lg="4">
							<Form.Group className="mb-3" controlId="preexistente-crear-rango-tipoIdentificador">
								<Form.Label className="mb-1">Tipo de identificador</Form.Label>
								<Form.Select
									size="sm"
									onChange={(e) => handleChange(e.target.value, 'tipoIdentificacion')}
									value={data.tipoIdentificacion}
								>
									{tiposIdentificacion.map((item, index) => {
										return (
											<option value={item.toLowerCase()} key={`tipo-identificacion-${index}`}>
												{item}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group className="mb-3" controlId="preexistente-crear-rango-numInicial">
								<Form.Label className="mb-1">Núm. Inicial</Form.Label>
								<Form.Control
									type="text"
									size="sm"
									value={data.numInicial}
									onChange={(e) => handleChange(e.target.value, 'numInicial')}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="preexistente-crear-rango-numFinal">
								<Form.Label className="mb-1">Núm. Final</Form.Label>
								<Form.Control
									type="text"
									size="sm"
									value={data.numInicial !== '' ? Number(parseInt(data.numInicial) + parseInt(cantidadAnimales) - 1) : '-'}
									disabled
								/>
							</Form.Group>
						</Col>
					</Row>
				) : null}
				<Row>
					<Col sm="6" lg="4">
						<Form.Group className="mt-4 mb-2" controlId="preexistente-crear-rango-carimbo">
							<Form.Label className="mb-1">Carimbo</Form.Label>
							<Form.Control
								size="sm"
								type="number"
								value={data.carimbo}
								onChange={(e) => handleChange(e.target.value, 'carimbo')}
								style={{maxWidth: 100}}
							/>
						</Form.Group>
						<Form.Group className="mb-2" controlId="preexistente-crear-rango-color">
							<Form.Label className="mb-1">Color</Form.Label>
							<Form.Control
								size="sm"
								value={data.color}
								onChange={(e) => handleChange(e.target.value, 'color')}
							/>
						</Form.Group>
						<Form.Group className="mb-2" controlId="preexistente-crear-rango-raza">
							<Form.Label className="mb-1">Raza</Form.Label>
							<Form.Control
								size="sm"
								value={data.raza}
								onChange={(e) => handleChange(e.target.value, 'raza')}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mt-4 mb-3">
					<Col className="d-flex justify-content-end">
						<Button
							variant="secondary"
							className="me-2"
							onClick={handleClose}
						>
							Cancelar
						</Button>
						<Button
							variant="primary"
							onClick={handleSave}
							disabled={saveDisabled || data.numInicial === ''}
						>
							Ingresar animales
						</Button>
					</Col>
				</Row>
			</>
		) : (
			<Alert variant="warning">Se debe especificar el número de animales</Alert>
		)
	)
}