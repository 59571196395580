export const compararNumeros = (campo) => {

	return function (a, b) {
		const valorA = parseInt(a[campo], 10)
		const valorB = parseInt(b[campo], 10)

		if (valorA < valorB) {
			return -1
		}

		if (valorA > valorB) {
			return 1
		}

		return 0
	}
}

