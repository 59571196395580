import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'
import BaseDataMostrar from '../BaseDataMostrar'

export default function ServicioVer(props) {
	const { config, tarea } = props

	const [listaI, setListaI] = useState(null)

	const handleSelect = (cual) => {
		if (cual === listaI) {
			setListaI(null)
		} else {
			setListaI(cual)
		}
	}

	const mostrarInseminado = tarea.servicio.tipo === 'iatf' || tarea.servicio.tipo === 'ia'
	const configuracionTorosMap = {}
	if (mostrarInseminado) {
		tarea.servicio.configuracionToros.forEach(toro => {
			configuracionTorosMap[toro.id] = toro.nombre
		})
	}
	

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			{tarea.comentarioEncargado && <><h5 className="mt-3 mb-1">Comentario encargado</h5><Alert variant="info" className="">{tarea.comentarioEncargado}</Alert></>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total procesados</th>
										<td>{`${tarea.listas[0].cantidadAnimalesYaProcesados} / ${tarea.listas[0].cantidadAnimales}`}</td>
									</tr>
									<tr>
										<th>Tipo</th>
										<td>{tarea.servicio.tipo}</td>
									</tr>
									<tr>
										<th>Estado</th>
										<td>{tarea.servicio.estado}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<h5 className="mt-3 mb-1">Toros</h5>
					{tarea.servicio.tipo === 'monta-natural' ? (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Núm. chip</th>
									<th>Núm. caravana</th>
									<th>Carimbo</th>
									<th>Raza</th>
								</tr>
							</thead>
							<tbody>
								{tarea.listas[1].animales.map((animal, i) => (
									<tr key={`lista-animal${i}`}>
										<td>{i + 1}</td>
										<td>{animal['numChip'] || '-'}</td>
										<td>{animal['numCaravana'] || '-'}</td>
										<td>{animal.carimbo}</td>
										<td>{animal.raza}</td>
									</tr>
								))}
							</tbody>
						</Table>
					) : (
						(tarea.servicio.tipo === 'iatf' || tarea.servicio.tipo === 'ia') ? (
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>#</th>
										<th>Nombre</th>
									</tr>
								</thead>
								<tbody>
									{tarea.servicio.configuracionToros.map((toro, i) => (
										<tr key={`lista-toro${i}`}>
											<td>{i + 1}</td>
											<td>{toro.nombre}</td>
										</tr>
									))}
								</tbody>
							</Table>
						) : null
					)}
				</Card.Body>
			</Card>
			<Accordion
				activeKey={listaI}
				onSelect={handleSelect}
			>
				<Accordion.Item eventKey={0}>
					<Accordion.Header>Hembras</Accordion.Header>
					<Accordion.Body>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th className='tarea-ver-animal-procesado'>Procesado</th>
									<th>Núm. chip</th>
									<th>Núm. caravana</th>
									<th>Carimbo</th>
									<th>Raza</th>
									{mostrarInseminado && (
										<th>Inseminado con</th>
									)}
								</tr>
							</thead>
							<tbody>
								{tarea.listas[0].animales.map((animal, i) => {
									let inseminadoText = '-'
									if (mostrarInseminado && animal.procesado) {
										// busca el servicio actual en el historial de servicios del animal
										const servicio = animal.historialServicios.find(s => s.servicioId === tarea.servicio.id)
										// muestra el nombre del toro
										inseminadoText = servicio.toros.nombre
									}

									return (
										<tr key={`lista-animal${i}`}>
											<td>{i + 1}</td>
											<td className="text-center">{animal.procesado ? <i className="fa-solid fa-circle-check fa text-success"></i> : '-'}</td>
											<td>{animal['numChip'] || '-'}</td>
											<td>{animal['numCaravana'] || '-'}</td>
											<td>{animal.carimbo}</td>
											<td>{animal.raza}</td>
											{mostrarInseminado && (
												<td>{inseminadoText}</td>
											)}
										</tr>
									)
								})}
							</tbody>
						</Table>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Container >
	)
}