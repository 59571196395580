import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import ListaAnimalesDeTarea from '../ListaAnimalesDeTarea'
import Alert from 'react-bootstrap/Alert'
import Datetime from 'react-datetime'
import { getShortDisplayDate, handleChangeFecha } from 'utils/date'


export default function ServicioProcesarMonta(props) {
	const { tarea, onClose, onProcesar } = props

	const [data, setData] = useState({
		fechaInicio: tarea.servicio.fechaInicio || '',
		fechaFin: tarea.servicio.fechaFin || ''
	})

	// A la hora de procesar, el servicio puede estar pendiente o en-curso
	let alertText = `El servicio de monta natural todavía no fue iniciado. Indicar las fechas de inicio y fin para iniciar.`
	let mostrarFechaInicio = true
	let btnText = 'Iniciar servicio'
	let mostrarDeshacerInicio = false

	if (tarea.servicio.estado === 'en-curso') {
		alertText = `El servicio de monta natural está iniciado y marcado para concluir el día ${getShortDisplayDate(tarea.servicio.fechaFin.substr(0, 10))}. Confirmar o modificar la fecha fin para finalizar.`
		mostrarFechaInicio = false
		btnText = 'Finalizar servicio'
		mostrarDeshacerInicio = true
	}

	const handleChange = (valor, cual) => {
		const nuevosDatos = {
			...data,
			[cual]: valor
		}

		setData(prev => nuevosDatos)
	}

	const handleDeshacerInicio = () => {
		const serverData = {
			id: tarea.id,
			servicio: {
				estado: 'pendiente',
				fechaInicio: null,
				fechaFin: null
			}
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleSave = () => {
		const serverData = {
			id: tarea.id,
			servicio: {
				fechaFin: data.fechaFin
			}
		}

		if (tarea.servicio.estado === 'pendiente') {
			serverData.servicio['fechaInicio'] = data.fechaInicio
			serverData.servicio['estado'] = 'en-curso'
		} else {
			serverData.servicio['estado'] = 'finalizado'
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="ms-2">
							<h5>{tarea.nombre}</h5>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="7">
							<ListaAnimalesDeTarea
								titulo={'Toros'}
								animales={tarea.listas[1].animales}
								sinSeleccionar={true}
							/>
							<ListaAnimalesDeTarea
								titulo={'Hembras'}
								animales={tarea.listas[0].animales}
								sinSeleccionar={true}
							/>
						</Col>
						<Col md="5">
							<Alert variant="info">{alertText}</Alert>
							{mostrarDeshacerInicio && (
								<Button
									variant="danger"
									size="sm"
									className="me-2"
									onClick={handleDeshacerInicio}
								>
									<i className="fa fa-undo"></i> Deshacer inicio
								</Button>
							)}
							{mostrarFechaInicio && (
								<Form.Group controlId="procesar-fechaInicio" >
									<Form.Label className="mb-1">Fecha inicio</Form.Label>
									<Datetime
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										closeOnSelect={true}
										strictParsing={false}
										inputProps={{
											placeholder: "YYYY-MM-DD",
											id: 'procesar-fechaInicio',
											className: 'form-control form-control-sm'
										}}
										value={data.fechaInicio}
										onChange={(date) => handleChangeFecha(date, handleChange, 'fechaInicio')}
									/>
								</Form.Group>
							)}
							<Form.Group controlId="procesar-fechaFin" >
								<Form.Label className="mb-1">Fecha fin</Form.Label>
								<Datetime
									dateFormat="YYYY-MM-DD"
									timeFormat={false}
									closeOnSelect={true}
									strictParsing={false}
									inputProps={{
										placeholder: "YYYY-MM-DD",
										id: 'procesar-fechaFin',
										className: `form-control form-control-sm ${tarea.servicio.estado === 'en-curso' && tarea.servicio.fechaFin.substr(0, 10) !== data.fechaFin.substr(0, 10) ? ' campo-modificado' : ''}`
									}}
									value={data.fechaFin}
									onChange={(date) => handleChangeFecha(date, handleChange, 'fechaFin')}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={data.fechaInicio === '' || data.fechaFin === ''}
							>
								{btnText}
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		</Container >
	)
}