import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button'
import Modal from "react-bootstrap/Modal"
import { QrReader } from 'react-qr-reader';
import { isFunction } from 'utils/general';

import './QRScan.css'

// Utiliza la librería: https://github.com/react-qr-reader/react-qr-reader/
function QRScan(props) {
	const { show, hide, save, parseFn = null, showResultsJSX = null } = props
	const delay = 300
	const [status, setStatus] = useState('read')
	const [result, setResult] = useState(null)
	const [error, setError] = useState(false)

	useEffect(() => {
		if (show) {
			setStatus('read')
		}
	}, [show])

	const resetState = (complete = false) => {
		if (complete) {
			setError(false)
		}
		setResult(null)
	}

	const handleHide = () => {
		resetState(true)
		hide()
	}

	const handleResult = (data, error) => {
		if (data) {
			try {
				if (isFunction(parseFn)) {
					data = parseFn(data)
				}
				setResult(data);
				setStatus('idle')
			} catch (err) {
				setStatus('idle')
				setError(true)
			}
		}
	}

	const handleError = err => {
		console.error(err);
	}

	const handleSave = () => {
		save(result)
		resetState(true)
		hide()
	}

	return (
		<Modal
			show={show}
			onHide={handleHide}
			size="md"
		>
			<Modal.Header closeButton>
				Escanear código QR
			</Modal.Header>
			<Modal.Body>
				{status === 'read' && !result && (
					<QrReader
						delay={delay}
						onError={handleError}
						onResult={handleResult}
						constraints={{ facingMode: 'user' }}
					/>
				)}
				{status === 'idle' && result && (
					isFunction(showResultsJSX) ? showResultsJSX(result) 
					: (
						<div>{result.text}</div>
					)
				)}
				{error && (
					<div id="qr-formato-invalido">
						<Alert>
							Formato no soportado
						</Alert>
						<Button onClick={() => {setError(false); setStatus('read')}}>Reintentar</Button>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleHide}>Cancelar</Button>
				<Button onClick={handleSave} disabled={!result}>Utilizar</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default QRScan