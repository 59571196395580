import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Button from 'react-bootstrap/Button'

export default function ButtonWithTooltip(props) {
	const { children, disabled = false, tooltipText, tooltipProps = { placement: 'auto', delay: { show: 250, hide: 400 } }, ...otherProps } = props

	const renderTooltip = (props) => (
		<Tooltip className="info-tooltip" {...props}>
			{tooltipText}
		</Tooltip>
	)

	return (
		<OverlayTrigger
			overlay={renderTooltip}
			trigger={disabled && tooltipText ? ['hover', 'focus'] : null}
			{...tooltipProps}
		>
			<span>
				<Button disabled={disabled} {...otherProps}>
					{children}
				</Button>
			</span>
		</OverlayTrigger>
	)
}
