export const LOCALSTORAGE_KEY = 'vakappy'
export const DEFAULT_TIPO_CUENTA_ID = 2
export const DEFAULT_FETCH_CUANTOS = 10

export const LISTA_MESES = [
	"Todos",
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
]