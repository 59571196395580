import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import BaseDataMostrar from '../BaseDataMostrar'
import { allKeysNull, getTextFromBoolean } from 'utils/general'
import { getShortDisplayDate } from 'utils/date'
import toroImg from 'assets/toro.png'

export default function ParicionVer(props) {
	const { config, tarea } = props

	let totalHijos = 0
	let totalVivos = 0
	let totalMuertos = 0
	tarea.pariciones.forEach(paricion => {
		totalHijos += paricion.numHijos
		paricion.hijos.forEach(hijo => {
			if (hijo.vivo) {
				totalVivos++
			} else {
				totalMuertos++
			}
		})
	})

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total pariciones</th>
										<td>{tarea.pariciones.length}</td>
									</tr>
									<tr>
										<th>Total terneros</th>
										<td>{totalHijos}</td>
									</tr>
									<tr>
										<th>Nacidos vivos</th>
										<td>{totalVivos}</td>
									</tr>
									<tr>
										<th>Nacidos muertos</th>
										<td>{totalMuertos}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card.Title className="mt-3">Eventos</Card.Title>
			{tarea.pariciones.map((paricion, i) => {
				const propietario = config.propietarios.find(propietario => propietario.id === paricion.animal.propietarioId)
				const establecimiento = config.establecimientos.find(establecimiento => establecimiento.id === paricion.animal.establecimientoId)
				const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(paricion.animal.categoriaId))

				let torosTxt = 'Sin datos toro papá'
				if (paricion.toros?.length) {
					if (paricion.toros.length === 1) {
						torosTxt = 'Toro papá'
					} else {
						torosTxt = 'Toros papás'
					}


				}

				return (
					<Card key={`paricion-${i}`} className="mb-3">
						<Card.Body>
							<Row>
								<Col className="mb-2">
									<strong>Fecha de parición:</strong> {getShortDisplayDate(paricion.fecha)}
								</Col>
							</Row>
							<Row>
								<Col>
									<Table size="sm">
										<thead>
											<tr>
												<th>Vivo</th>
												<th>Sexo</th>
												<th>Raza</th>
												<th>Color</th>
												<th>Peso</th>
												<th>¿Identificado?</th>
												<th>numCaravana</th>
												<th>numChip</th>
												<th>numTatuaje</th>
											</tr>
										</thead>
										<tbody>
											{paricion.hijos.map((hijo, j) => {
												const identificado = !allKeysNull(hijo.identificadores)

												return (
													<tr key={`hijo-${j}`}>
														<td>{getTextFromBoolean(hijo.vivo)}</td>
														<td>{hijo.vivo ? hijo.sexo : '-'}</td>
														<td>{hijo.vivo ? hijo.raza : '-'}</td>
														<td>{hijo.vivo ? hijo.color : '-'}</td>
														<td>{hijo.vivo ? hijo.peso : '-'}</td>
														<td>{hijo.vivo ? getTextFromBoolean(identificado) : '-'}</td>
														<td>{identificado ? hijo.identificadores.numCaravana ?? '-' : '-'}</td>
														<td>{identificado ? hijo.identificadores.numChip ?? '-' : '-'}</td>
														<td>{identificado ? hijo.identificadores.numTatuaje ?? '-' : '-'}</td>
													</tr>
												)
											})}
										</tbody>
									</Table>

								</Col>
							</Row>
							<Row>
								<Col>
									<strong>Mamá</strong>
								</Col>
							</Row>
							<Row className="px-2 mb-3">
								<Col className="px-3 border">
									<Table style={{ width: 250 }}>
										<tbody>
											<tr>
												<th># Caravana</th>
												<td>{paricion.animal.numCaravana ?? '-'}</td>
											</tr>
											<tr>
												<th># Chip</th>
												<td>{paricion.animal.numChip ?? '-'}</td>
											</tr>
											<tr>
												<th># Caravana</th>
												<td>{paricion.animal.numTatuaje ?? '-'}</td>
											</tr>
										</tbody>
									</Table>
									<table className="w-100">
										<thead>
											<tr>
												<th>Propietario</th>
												<th>Establecimiento</th>
												<th>Categoría</th>
												<th>Sexo</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{`${propietario.nombre} ${propietario.apellido}`}</td>
												<td>{establecimiento.nombre}</td>
												<td>{categoria.nombre}</td>
												<td>{paricion.animal.sexo}</td>
											</tr>
										</tbody>
									</table>
								</Col>
							</Row>
							<Row>
								<Col>
									<strong>{torosTxt}</strong>
								</Col>
							</Row>
							<Row className="mt-1 mb-3">
								{paricion.toros && paricion.toros.map((toro, j) => {
									const esAnimal = toro.numChip || toro.numCaravana || toro.numTatuaje || toro.fechaNacimiento || toro.raza
									return (
										<Col key={`toro-${j}`} sm={12} md={4} lg={3}>
											<Card className="text-center">
												<Card.Body>
													<Card.Img
														variant="top"
														src={toroImg}
														alt="toro"
														style={{ maxWidth: 80 }}
													/>
													{esAnimal ? (
														<Table size="sm" className="text-start mt-1">
															<tbody>
																<tr>
																	<th># Caravana</th>
																	<td>{toro.numCaravana ?? '-'}</td>
																</tr>
																<tr>
																	<th># Chip</th>
																	<td>{toro.numChip ?? '-'}</td>
																</tr>
																<tr>
																	<th># Tatuaje</th>
																	<td>{toro.numTatuaje ?? '-'}</td>
																</tr>
															</tbody>
														</Table>
													) : (
														<Card.Title>
															{toro.nombre}
														</Card.Title>
													)}
												</Card.Body>
											</Card>
										</Col>
									)
								})}
							</Row>
						</Card.Body>
					</Card>
				)
			})}
			{/*
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="6">
							<Card.Title>Total animales: <strong>{tarea.identificacion.animales.length}</strong></Card.Title>
							<Table>
								<thead>
									<tr>
										<th>#</th>
										<th>Núm. chip</th>
										<th>Núm. caravana</th>
										<th>Núm. tatuaje</th>
										<th>Carimbo</th>
										<th>Raza</th>
										<th>Sexo</th>
									</tr>
								</thead>
								<tbody>
									{tarea.identificacion.animales.map((animal, i) => {
										return (
											<tr key={animal.id}>
												<td>{i + 1}</td>
												<td>{animal['numChip'] || '-'}</td>
												<td>{animal['numCaravana'] || '-'}</td>
												<td>{animal['numTatuaje'] || '-'}</td>
												<td>{animal.carimbo || '-'}</td>
												<td>{animal.raza || '-'}</td>
												<td>{animal.sexo}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		*/}
		</Container >
	)
}