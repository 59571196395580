import React, { useState, useEffect, useCallback, useRef } from 'react'
import './ScrollTop.css'

export default function(props) {
	const { top = 0, showFrom = 0, containerRef } = props
	const iconRef = useRef(null)

	const toggleShow = useCallback(() => {
		// Check if the containerRef prop is not null
		if (containerRef) {
			// Get the y position of the window or the container element
			const containerY = containerRef === window ? (window.scrollY || window.pageYOffset) : containerRef.current.offsetTop + containerRef.current.scrollTop
			if (containerY > showFrom) {
				iconRef.current.classList.remove('hidden');
			} else {
				iconRef.current.classList.add('hidden');
			}
		}
	}, [containerRef])

	const scrollToTop = useCallback(() => {
		containerRef.current.scrollTo({
			top,
			behavior: 'smooth'
		})
	}, [top])

	useEffect(() => {
		if (containerRef) {
			containerRef.current.addEventListener('scroll', toggleShow)

			return () => {
				if (containerRef?.current) {
					containerRef.current.removeEventListener('scroll', toggleShow)
				}
			}
		} else {
			console.log('not doing anything, containerRef is null')
		}
	}, [toggleShow, containerRef])

	return (
		<div ref={iconRef} className="scrollTop-container hidden" onClick={scrollToTop} aria-label="Scroll to top">
			<i className="fa fa-arrow-up"></i>
		</div>
	)
}