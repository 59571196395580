import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormLabel  from 'react-bootstrap/FormLabel'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

export default function EditModal(props) {
	const { loteOriginal, onCancel, onSave } = props

	const [lote, setLote] = useState({...loteOriginal})

	const hayCambios = loteOriginal.nombre !== lote.nombre

	const handleChange = (valor, cual) => {
		setLote(prev => ({
			...lote,
			[cual]: valor
		}))
	}

	const handleCancel = () => {
		onCancel()
	}

	const handleSave = () => {
		onSave(lote)
	}

	return (
		<Modal show={true} onHide={handleCancel}>
			<Modal.Header closeButton>
				<Modal.Title>Cambiar nombre</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="lote-nombre">
								<FormLabel>Nombre</FormLabel>
								<Form.Control
									value={lote.nombre}
									onChange={(e) => handleChange(e.target.value, 'nombre')}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Form>
				<Alert className="mt-2" variant="info">Los nombre de lotes deben ser únicos. En caso de utilizar nombres genéricos, se recomienda poner como prefijo el año y/o mes para poder diferenciar mejor.</Alert>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleCancel}>
					Cancelar
				</Button>
				<Button
					variant="danger"
					onClick={handleSave}
					disabled={!hayCambios}
				>
					Editar
				</Button>
			</Modal.Footer>
		</Modal>
	)

}