import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

export default function WizardStepFooter(props) {
	const { step, numSteps, handleAction, alreadyCompleted, completed, modified, enableSave, disableFinish } = props

	return (

		<Card.Footer className="py-3">
			<div className="d-flex justify-content-between">
				<Button
					variant="secondary"
					onClick={() => handleAction('back')}
					disabled={step === 0}
				>
					Anterior
				</Button>
				{alreadyCompleted ? (
					<Button
						variant="primary"
						onClick={() => handleAction('next')}
						disabled={!enableSave && (!modified || !completed)}
					>
						Guardar
					</Button>
				) : (
					step < numSteps - 1 ? (
						<Button
							variant="primary"
							onClick={() => handleAction('next', true)}
							disabled={!completed}
						>
							Siguiente
						</Button>
					) : (
						<Button
							variant="success"
							onClick={() => handleAction('next', true)}
							disabled={!completed || disableFinish}
						>
							Finalizar
						</Button>
					)
				)}
			</div>
		</Card.Footer>
	)
}
