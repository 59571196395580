import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import WizardStepFooter from 'components/Wizard/WizardStepFooter'
import { FINALIDADES_COTA } from 'config/movimientoAnimales'
import { convertBytesToKB, getUpladsUrl } from 'utils/general'
import FileUpload from 'components/FileUpload/FileUpload'

export default function Documentacion(props) {
	const { config, data: originalData = {}, isCompleted: isOriginallyCompleted, updateData, step, numSteps, prevStep, enableSave, disableFinish } = props

	const finalidadesCota = FINALIDADES_COTA

	const [data, setData] = useState({ ...originalData })
	const [uploadPopupData, setUploadPopupData] = useState({
		mostrar: false, // si mostrar o no el popup
		original: '', // el seleccionado anterior que se haya guardado
		seleccionado: '', // el nuevo seleccionado
		texto: '', // el texto a mostrar
		key: '' // el key a utilizar
	})
	const [archivos, setArchivos] = useState([
		{
			original: originalData?.archivosOriginales.guia ?? null,
			seleccionado: '',
			texto: 'guía',
			key: 'guia'
		},
		{
			original: originalData?.archivosOriginales.cota ?? null,
			seleccionado: '',
			texto: 'cota',
			key: 'cota'
		},
		{
			original: originalData?.archivosOriginales.remision ?? null,
			seleccionado: '',
			texto: 'remisión',
			key: 'remision'
		}
	])

	const verSiHayCambios = () => {
		let cambios = !(
			data.finalidadCota === originalData.finalidadCota
			&& data.numeroGuia === originalData.numeroGuia
			&& data.numeroCota === originalData.numeroCota
			&& Number(data.guiaId) === Number(originalData.guiaId)
			&& Number(data.cotaId) === Number(originalData.cotaId)
			&& Number(data.remisionId) === Number(originalData.remisionId)
		)

		archivos.forEach(archivo => {
			if (archivo.seleccionado !== '') {
				if (archivo.seleccionado?.nombre !== archivo.original) {
					cambios = true
				}
			}
		})

		return cambios
	}

	const verSiCompleto = () => {
		const completo = data.numeroGuia.trim() !== '' && data.numeroCota.trim() !== ''


		return completo
	}

	const hayModificaciones = verSiHayCambios()
	const isCompleted = verSiCompleto()

	const handleChange = (valor, cual) => {
		setData(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleFooterAction = (direction, goToNext = false) => {
		if (direction === 'back') {
			prevStep()
		} else {
			data.archivos = []
			archivos.forEach(a => {
				if (a.seleccionado !== '') {
					// a.seleccionado puede ser instanceof File o null si se quiere eliminar un archivo ya existente
					data.archivos[a.key + 'Id'] = a.seleccionado
				}
			})
			updateData(data, isCompleted, goToNext)
		}
	}

	/* Inicio manejo de popup de archivos */
	const handleShowPopup = (cual) => {
		const popupData = {
			...cual,
			mostrar: true
		}

		setUploadPopupData(popupData)
	}

	const handleClosePopup = () => {
		setUploadPopupData({
			mostrar: false
		})
	}

	const selectFile = () => {
		const archivoIndex = archivos.findIndex(a => a.key === uploadPopupData.key)
		const copy = [...archivos]
		copy[archivoIndex].seleccionado = uploadPopupData.seleccionado

		setArchivos(copy)
		handleClosePopup()
	}

	const updateArchivo = (files) => {
		const archivo = { ...uploadPopupData, seleccionado: files[0] }
		setUploadPopupData(archivo)
	}

	const removeArchivo = (key) => {
		console.log('removing')
		const archivoIndex = archivos.findIndex(a => a.key === key)
		const copy = [...archivos]
		console.log(copy[archivoIndex])
		if (copy[archivoIndex].original !== null && copy[archivoIndex].seleccionado === '') {
			copy[archivoIndex].seleccionado = null
		} else {
			copy[archivoIndex].seleccionado = ''
		}
		
		setArchivos(copy)
	}

	const getArchivoJSX = (cual) => {
		let nombre = ''
		let tamano = 0
		let existente = false
		let showFileRemove = true
		if (cual.seleccionado !== '' && cual.seleccionado !== undefined) {
			if (cual.seleccionado === null) {
				nombre = '-'
				tamano = 0
				showFileRemove = false
			} else {
				nombre = cual.seleccionado.name
				tamano = convertBytesToKB(cual.seleccionado.size)
			}
		} else {
			if (cual.original !== null) {
				nombre = cual.original.url.split('/').pop()
				tamano = convertBytesToKB(cual.original.tamano)
				existente = true
			}
		}

		let nombreJSX = nombre
		if (cual.original?.url && cual.seleccionado === '') {
			nombreJSX = (
				<a href={getUpladsUrl() + cual.original.url} target='_blank'>
					{nombre}
				</a>
			)
		}

		return (
			<div className="upload-box">
				{nombre && (
					<div className={`file${!existente ? ' nuevo' : ''}`}>
						<span className="filename">
							{nombreJSX}
						</span>
						<aside>
							<span className="filesize">{tamano} kb</span>
							{showFileRemove && (
								<i
									className="file-remove fas fa-trash-alt ms-2 text-danger"
									title={`Eliminar archivo ${cual.seleccionado !== '' ? 'seleccionado' : 'original'}`}
									onClick={() => removeArchivo(cual.key)}
								/>
							)}
						</aside>
					</div>
				)}
				<Button className="my-1 w-100" onClick={() => handleShowPopup(cual)}><i className="fas fa-file-upload"></i> Elegir {cual.texto}</Button>
			</div>
		)
	}
	/* Fin manejo de popup de archivos */

	return (
		<>
			<Card>
				<Card.Body>
					<Row>
						<Form.Group controlId="salida-finalidad" className="col-sm-6 mb-1">
							<Form.Label className="mb-1">Finalidad</Form.Label>
							<Form.Select
								size="sm"
								value={data.finalidadCota}
								onChange={(e) => handleChange(e.target.value, 'finalidadCota')}
							>
								{finalidadesCota.map((opcion, i) => {
									return (
										<option value={opcion.value} key={`salida-finalidad-${opcion.value}`}>
											{opcion.label}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group className="col-sm-6 mb-1" controlId="salida-numeroGuia">
							<Form.Label className="mb-1">Número de guía</Form.Label>
							<Form.Control
								value={data.numeroGuia}
								onChange={(e) => handleChange(e.target.value, 'numeroGuia')}
								type="text"
								size="sm"
							/>
						</Form.Group>
						<Form.Group className="col-sm-6 mb-1" controlId="salida-numeroCota">
							<Form.Label className="mb-1">Número de cota</Form.Label>
							<Form.Control
								value={data.numeroCota}
								onChange={(e) => handleChange(e.target.value, 'numeroCota')}
								type="text"
								size="sm"
							/>
						</Form.Group>
					</Row>
					<h4 className="mt-2 mb-0"><strong>Subir documentos</strong></h4>
					<Row className="">
						{archivos.map((cual) => {
							return (
								<Col key={`archivo-${cual.key}`} className="col-12 col-sm-6 col-md-4">
									{getArchivoJSX(cual)}
								</Col>
							)
						})}
					</Row>
				</Card.Body>
				<WizardStepFooter
					step={step}
					numSteps={numSteps}
					handleAction={handleFooterAction}
					alreadyCompleted={isOriginallyCompleted}
					completed={isCompleted}
					modified={hayModificaciones}
					enableSave={enableSave}
					disableFinish={disableFinish}
				/>
			</Card>
			<Modal
				key={uploadPopupData.key}
				show={uploadPopupData.mostrar}
				onHide={() => handleClosePopup()}
				size="md"
			>
				<Modal.Header closeButton>
					Subir archivo
				</Modal.Header>
				<Modal.Body>
					<FileUpload
						alreadySelectedFiles={uploadPopupData.seleccionado ? {[uploadPopupData.seleccionado.name] : uploadPopupData.seleccionado} : {}}
						updateFiles={(files) => updateArchivo(files)}
						title={"Subir " + uploadPopupData.texto}
						accept=".pdf"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => selectFile()}>Aceptar</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}