import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

export default function CerrarConCambiosModal(props) {
	const { datos, onCancel, onConfirm } = props

	const handleCancel = () => {
		onCancel()
	}

	const handleConfirm = () => {
		onConfirm()
	}

	return (
		<Modal show={true} onHide={handleCancel}>
			<Modal.Header closeButton>
				<Modal.Title>Cerrar con cambios</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de cerrar la tarea <em>{`${datos.nombre}`}</em> con <strong>{datos.texto}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleCancel}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={handleConfirm}>
					Cerrar y descartar
				</Button>
			</Modal.Footer>
		</Modal>
	)

}