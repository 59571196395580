import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import axios from 'axios';
import { toast } from 'react-toastify'
import '../configuraciones.css'

import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Establecimiento from './Establecimiento'
import DeleteModal from './DeleteModal'

export default function Establecimientos() {
	const [establecimientos, setEstablecimientos] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [editingEstablecimiento, setEditingEstablecimiento] = useState(null)
	const [deletingEstablecimiento, setDeletingEstablecimiento] = useState(null)

	const refreshEstablecimientos = async (cancelToken) => {
		try {
			setLoading(true)
			const { datos } = await api.get('u/establecimientos', {
				cancelToken: cancelToken.token,
			})

			setEstablecimientos(
				datos.map((establecimiento) => ({
					id: establecimiento.id,
					nombre: establecimiento.nombre,
					codigo: establecimiento.codigo,
					partes: establecimiento.partes,
				}))
			)
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Petición cancelada', error.message)
			} else {
				toast.error('Error de conexión')
				setError(error)
			}
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		refreshEstablecimientos(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	const handleNew = () => {
		setEditingEstablecimiento({})
	}

	const handleEdit = (establecimiento) => {
		setEditingEstablecimiento(establecimiento)
	}

	const handleDelete = (establecimiento) => {
		setDeletingEstablecimiento(establecimiento)
	}

	const onCancelEdit = () => {
		setEditingEstablecimiento(null)
	}

	const onCancelDelete = () => {
		setDeletingEstablecimiento(null)
	}

	const onSave = async (datos) => {
		try {
			setLoading(true)
			if (datos.id) {
				const { datos: respuesta, msg } = await api.put('u/establecimientos/' + datos.id, datos)	
				toast.success(msg)
			} else {
				const { datos: respuesta, msg } = await api.post('u/establecimientos', datos)
				toast.success(msg)
			}
			refreshEstablecimientos(cancelToken)
		} catch (error) {
			toast.error('Error de conexión')
			setError(error)
		} finally {
			setLoading(false)
			onCancelEdit()
		}
	}

	const onDelete = async (establecimientoId) => {
		try {
			setLoading(true)
			const { msg } = await api.delete('u/establecimientos/' + establecimientoId)
			refreshEstablecimientos(cancelToken)
			toast.success(msg)
		} catch (error) {
			toast.error('Error de conexión')
			setError(error)
		} finally {
			setLoading(false)
			onCancelDelete()
		}
	}

	// render
	return (
		<>
			<Container fluid id="establecimientos" className="vh-100">
				{/* Header */}
				<Row>
					<Col className="mt-2">
						<h1>Establecimientos</h1>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button onClick={() => handleNew()} variant="primary">
							<i className="fa fa-plus"></i> Nuevo Establecimiento
						</Button>
					</Col>
				</Row>
				<Row>
					{loading ? (
						<Col>
							<LoadingSpinner />
						</Col>
					) : (
						<Col md={8} lg={6}>
							<Table className="mt-2" striped bordered hover>
								<thead>
									<tr className="text-secondary">
										<th>Id</th>
										<th>Nombre</th>
										<th>Código</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{establecimientos.map((establecimiento) => {
										return (
											<tr key={establecimiento.id}>
												<td>{establecimiento.id}</td>
												<td>{establecimiento.nombre}</td>
												<td>{establecimiento.codigo}</td>
												<td>
													<Button
														onClick={() => handleEdit(establecimiento)}
														className="me-2"
														variant="secondary"
														size="sm"
													>
														Editar
													</Button>
													<Button
														onClick={() => handleDelete(establecimiento)}
														variant="danger"
														size="sm"
													>
														Eliminar
													</Button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					)}
				</Row>
			</Container>
			{editingEstablecimiento ? (
				<Establecimiento key={editingEstablecimiento?.id || 0} establecimiento={editingEstablecimiento} onSave={onSave} onCancel={onCancelEdit} />
			) : null}
			{deletingEstablecimiento ? (
				<DeleteModal establecimiento={deletingEstablecimiento} onDelete={onDelete} onCancel={onCancelDelete} />
			) : null}
		</>
	)
}
