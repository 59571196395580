import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

export default function DeleteModal(props) {
	const { establecimiento, onCancel, onDelete } = props

	const handleCancel = () => {
		onCancel()
	}

	const handleDelete = () => {
		onDelete(establecimiento.id)
	}

	return (
		<Modal show={true} onHide={handleCancel}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar Establecimiento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de eliminar el establecimiento <strong>{`${establecimiento.nombre}`}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleCancel}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={handleDelete}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)

}