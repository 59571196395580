export const FETCH_DE_A_CUANTOS = 200
// Mapa de categoriaId => sexo. Ej.: 1 (vaca) => hembra
export const CATEGORIAS_CON_SEXO_FIJO = {
	1: 'hembra',
	2: 'hembra',
	3: 'macho',
	4: 'macho',
	5: 'macho',
	6: 'hembra',
	8: 'macho'
}