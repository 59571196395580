import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { getShortDisplayDate } from 'utils/date'

export default function FichaSanitacionesTabContent(props) {
	const { sanitaciones } = props

	return (
		<div className="tab-content-wrapper">
			<h2>Sanitaciones</h2>
			{sanitaciones.length ? (
				<div className="col-12">
					<Table size="sm">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Medicamento</th>
								<th>Dosis</th>
							</tr>
						</thead>
						<tbody>
							{
								sanitaciones.map((sanitacion, i) => {
									return <tr key={sanitacion.id}>
										<th>{getShortDisplayDate(sanitacion.fecha)}</th>
										<td><i className="fa fa-"></i> {sanitacion.medicamento}</td>
										<td>{sanitacion.dosis}</td>
									</tr>
								})
							}
						</tbody>
					</Table>
				</div>
			) : (
				<Alert variant="info" className="mt-3">No hay sanitaciones del animal</Alert>
			)}

		</div>
	)
}