import moment from 'moment';

export function getDayName(index) {
	var days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
	var dayName = days[index]

	return dayName
}

export function getMonthName(index) {
	var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
	var monthName = months[index];

	return monthName;
}

export function getDisplayDate(dateStr) {
	const date = new Date(dateStr.split('-').join('/'))
	const dayName = getDayName(date.getDay())

	let day = date.getDate()

	if (day < 10) {
		day = '0' + day
	}

	const str = `${dayName}, ${day} de ${getMonthName(date.getMonth())}`

	return str
}

export function getShortDisplayDate(dateStr) {
	const date = new Date(dateStr.split('-').join('/'))
	let day = date.getDate()

	if (day < 10) {
		day = '0' + day
	}

	const year = date.getFullYear()

	const str = `${day} de ${getMonthName(date.getMonth()).substring(0, 3)}, ${year}`

	return str
}

// Recibe un elemento del tipo Date y devuelve un string en formato 'YYYY-mm-dd'
export function getServerDate(date) {
	let day = date.getDate()

	if (day < 10) {
		day = '0' + day
	}

	const year = date.getFullYear()

	let month = date.getMonth() + 1
	if (month < 10) {
		month = '0' + month
	}

	const str = `${year}-${month}-${day}`

	return str
}

export function getMomentObject(date) {
	if (moment.isMoment(date)) {
		return date
	}

	if (date instanceof Date) {
		return moment(date)
	}

	if (typeof date === 'string') {
		return moment(date, 'YYYY-MM-DD')
	}

	// Si no es un tipo reconocido, devuelve null
	return null
}

// Convierte a string, tanto si es un moment object, como si es un date, como si es un string y los compara
export function hasEqualDateValue(date1, date2) {
	let date1Value = date1
	if (date1?._isAMomentObject) {
		date1Value = date1.format('yyyy-MM-DD')
	} else if (date1 instanceof Date) {
		date1Value = getServerDate(date1)
	}

	let date2Value = date2
	if (date2?._isAMomentObject) {
		date2Value = date2.format('yyyy-MM-DD')
	} else if (date2 instanceof Date) {
		date2Value = getServerDate(date2)
	}

	return date1Value === date2Value
}

export function getInicioAnho() {
	const hoy = new Date()
	return new Date(hoy.getFullYear(), 0, 1)
}

export function getAbbrDisplayDate(dateStr) {
	const date = new Date(dateStr.split('-').join('/'))
	let day = date.getDate()

	if (day < 10) {
		day = '0' + day
	}

	const year = date.getFullYear()

	const str = `${day}-${getMonthName(date.getMonth()).substring(0, 3).toLowerCase()}, ${year}`

	return str
}

export const getDaysDifference = (date1, date2) => {
	return Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24))
}

export const addDays = (numOfDays, date = new Date()) => {
	const dateCopy = new Date(date.getTime());

	dateCopy.setDate(dateCopy.getDate() + numOfDays);

	return dateCopy;
}


export const handleChangeFecha = (e, setFn, ...extraArguments) => {
	let selected = ''
	if (e?._isAMomentObject) {
		selected = e.format('yyyy-MM-DD')
	}

	setFn(selected, ...extraArguments)
}