import React, { useState, useEffect } from 'react'
import api from 'api/api'
import axios from 'axios'
import { ConfigContext } from './ConfigContext'

const ConfigProvider = ({ children }) => {
	const [data, setData] = useState({
		propietarios: [],
		establecimientos: [],
		lotes: [],
		categorias: [],
		usuarios: []
	})
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)

	const fetchData = async (source) => {
		setLoading(true)

		try {
			const { datos, msg } = await api.get('u/config-tareas', {
				cancelToken: source.token,
				//timeout: 5000, // For example, 5 seconds
			})
			setData(datos)
			setError(null)
		} catch (err) {
			if (axios.isCancel(err)) {
				setError("Request canceled")
			} else if (err.code === "ECONNABORTED") {
				setError("Request timed out")
			} else {
				setError(err.message)
			}
		}
		
		setLoading(false)
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		fetchData(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	const refresh = () => {
		fetchData(cancelToken)
	}

	return (
		<ConfigContext.Provider value={{ data, loading, error, refresh }}>
			{children}
		</ConfigContext.Provider>
	)
}

export { ConfigContext, ConfigProvider }
