import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import { ModalSeleccionAnimales } from 'components/SeleccionAnimales'
import { isEmpty } from 'utils/isEmpty'


export default function AdquisicionProcesar(props) {
	const { config, tarea, onClose, onProcesar, verFinalizarTarea, setCambiosData } = props

	const [showMortandad, setShowMortandad] = useState(false)
	const [data, setData] = useState({
		comentarioEncargado: '',
		listas: tarea.adquisicion.listas.map(l => ({ ...l, numMuertos: 0 }))
	})

	const handleChange = (valor, cual) => {
		const nuevosDatos = {
			...data,
			[cual]: valor
		}

		setData(prev => nuevosDatos)
	}

	const handleChangeMortandad = (valor, listaIndex) => {
		const listas = [...data.listas]
		listas[listaIndex]['numMuertos'] = valor

		setData(prev => ({ ...prev, listas }))
	}

	const handleSave = () => {
		const serverData = {
			id: tarea.id,
			adquisicion: {
				estado: 'procesado'
			},
			comentarioEncargado: data.comentarioEncargado || null,
		}

		if (showMortandad) {
			serverData.adquisicion.listas = data.listas.map(l => ({
				id: l.id,
				numMuertos: l.numMuertos
			}))
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="ms-2">
							<h5>{tarea.nombre}</h5>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="d-grid align-items-center justify-content-center pt-5 text-center">
							<h4>¿Los animales llegaron de acuerdo lo que dice el Documento Guía/Cota?</h4>
							<div>
								<Form.Check
									id="check-sinCambios"
									type='radio'
									inline
									label='Sí'
									checked={!showMortandad}
									onChange={e => setShowMortandad(false)}
									className="me-4"
								/>
								<Form.Check
									id="check-conCambios"
									type='radio'
									inline
									label="No, hubo mortandad"
									checked={showMortandad}
									onChange={e => setShowMortandad(true)}
								/>
							</div>
						</Col>
					</Row>
					<Row className="mt-2 mb-2">
						<Form.Group controlId="txtSalidaComentarioEncargado">
							<Form.Label className="mb-1">Observaciones</Form.Label>
							<Form.Control as="textarea" rows={3}
								value={data.comentarioEncargado}
								onChange={(e) => handleChange(e.target.value, 'comentarioEncargado')}
							/>
						</Form.Group>
					</Row>
					{showMortandad ? (
						<Row className="mt-3">
							<Col>
								<h4 className="mb-0 mt-3">Listas de animales</h4>
								<Table id="salida-procesar-animales-table" className="table-hover pt-0">
									<thead>
										<tr>
											<th>Tipo animales</th>
											<th>Cantidad</th>
											<th>Mortandad</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{data.listas.map((lista, i) => {
											const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(lista.categoriaId))

											let tipoAnimalesText = categoria.nombre

											if (categoria.id === 7 || categoria.id === 9) {
												tipoAnimalesText += ', ' + lista.sexo
											}

											let warningText = ''
											if (lista.numMuertos > lista.cantidad) {
												warningText = <i className="fas fa-triangle-exclamation text-warning" title="Hay más animales seleccionados de los especificados" />
											}
											<i className="fas fa-triangle-exclamation text-warning" title="La mortandad es mayor a la cantidad de animales esperados" />

											return (
												<tr key={`lista-${i}`}>
													<td className="adquisicion-tipoAnimales">
														{tipoAnimalesText}
													</td>
													<td className="adquisicion-cantidad">
														{lista.cantidad}
													</td>
													<td className="adquisicion-mortandad">
														<Form.Group className="col mb-1" controlId="salida-propietarioDestinoNombre">
															<Form.Control
																value={lista.numMuertos}
																onChange={(e) => handleChangeMortandad(e.target.value, i)}
																type="number"
																size="sm"
																min={0}
																max={lista.cantidad}
															/>
														</Form.Group>
													</td>
													<td>
														{warningText}
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Col>
						</Row>
					) : null}
				</Card.Body>
				<Card.Footer>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
							>
								Procesar tarea
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		</Container>
	)
}