import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { getShortDisplayDate } from 'utils/date'

export default function FichaMovimientosTabContent(props) {
	const { movimientos } = props

	const getTipoLabel = (tipo) => {
		let label = ''

		if (tipo === 'adquisicion') {
			label = 'Adquisición'
		} else {
			label = 'Carga preexistente'
		}

		return label
	}

	return (
		<div className="tab-content-wrapper">
			<h2>Movimientos</h2>
			<div className="col-12">
				<Table size="sm">
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Movimiento</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>{getShortDisplayDate(movimientos.entrada.fecha)}</th>
							<td><i className="fa fa-arrow-down"></i> [{getTipoLabel(movimientos.entrada.tipo)}] {movimientos.entrada.nombre}</td>
						</tr>
						{
							movimientos.lotes.map((lote, i) => {
								let desde = '-'
								if (i > 0) {
									desde = movimientos.lotes[i - 1]['nombre']
								}

								return <tr key={lote.id}>
									<th>{getShortDisplayDate(lote.fechaEntrada)}</th>
									<td><i className="fa fa-"></i> {lote.nombre}</td>
								</tr>
							})
						}
						{movimientos.salida.fecha ? (
							<tr>
								<th>{getShortDisplayDate(movimientos.salida.fecha)}</th>
								<td><i className="fa fa-arrow-right-to-bracket"></i> [Salida] {movimientos.salida.nombre}</td>
							</tr>
						) : null}
					</tbody>
				</Table>
			</div>
		</div>
	)
}