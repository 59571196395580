import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import BaseDataInputs from '../BaseDataInputs'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'
import EstadosExtra from './EstadosExtra'
import { InfoIcon } from 'components/InfoIcon'
import IngresoRango from './IngresoRango'
import IngresoIndividual from './IngresoIndividual'

export default function IngresoPreexistente(props) {
	const { config, ingresoPreexistente, onClose, onCrearTarea } = props

	const [data, setData] = useState({
		id: ingresoPreexistente?.id,
		nombre: ingresoPreexistente?.nombre ?? '',
		fechaParaCuando: ingresoPreexistente?.fechaParaCuando ?? '',
		asignadoId: ingresoPreexistente?.asignadoId ?? '',
		observacion: ingresoPreexistente?.observacion ?? '',
		propietarioId: ingresoPreexistente?.propietarioId ?? config.propietarios[0].id,
		establecimientoId: ingresoPreexistente?.establecimientoId ?? config.establecimientos[0].id,
		categoriaId: ingresoPreexistente?.categoriaId ?? '',
		sexo: ingresoPreexistente?.sexo ?? '',
		prenada: false,
		enServicio: false,
		enPosparto: false,
		alPie: false,
		destetePrecoz: false,
		cantidad: 0
	})
	const [resetDatosExtra, setResetDatosExtra] = useState(true)
	const [tipo, setTipo] = useState('rango')

	const saveDisabled = !data.cantidad || data.nombre.trim() === '' || data.fechaParaCuando === ''

	useEffect(() => {
		if (resetDatosExtra) {
			setResetDatosExtra(false)
		}
	}, [resetDatosExtra])

	const handleChange = (valor, cual) => {
		setData(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const saveBaseInputsData = (baseData) => {
		setData(prev => ({
			...prev,
			...baseData
		}))
	}

	const onSaveSelectCategoria = (categoriaData) => {
		setData(prev => ({
			...prev,
			...categoriaData
		}))

		setResetDatosExtra(true)
	}

	const onSaveEstadosExtra = (estadosExtra) => {
		setData(prev => ({
			...prev,
			...estadosExtra
		}))
	}

	const onSave = (animalesData, tipo) => {
		const serverData = {
			tipo: 'carga-preexistente',
			nombre: data.nombre,
			fechaParaCuando: data.fechaParaCuando,
			asignadoId: data.asignadoId,
			observacion: data.observacion,
			carga: {
				propietarioId: data.propietarioId,
				establecimientoId: data.establecimientoId,
				tipo, // rango o individual
				cantidad: data.cantidad,
				prenada: data.prenada,
				enServicio: data.enServicio,
				enPosparto: data.enPosparto,
				alPie: data.alPie,
				destetePrecoz: data.destetePrecoz,
				conIdentificacion: animalesData.conIdentificacion,
				tipoIdentificacion: animalesData.tipoIdentificacion,
				numInicial: animalesData.numInicial,
				carimbo: animalesData.carimbo || null,
				color: animalesData.color || null,
				raza: animalesData.raza || null,
				estado: 'procesado',
			},
		}

		if (tipo === 'individual') {
			serverData.carga.animales = animalesData.animales.map(animal => ({...animal, carimbo: animal.carimbo || null}))
		} else {
			serverData.carga.categoriaId = data.categoriaId
			serverData.carga.sexo = data.sexo || null
		}

		onCrearTarea(serverData)
		onClose()

	}

	return (
		<Container>
			<BaseDataInputs usuarios={config.usuarios} saveData={saveBaseInputsData} />
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Destino</Card.Title>
					<Row>
						<Col md="6">
							<Form.Group className="mb-3" controlId="preexistente-propietario">
								<Form.Label>Propietario</Form.Label>
								<Form.Select
									size='sm'
									value={data.propietarioId}
									onChange={(e) => handleChange(e.target.value, 'propietarioId')}
								>
									{config.propietarios.map((e) => {
										return (
											<option value={e.id} key={`propietario-${e.id}`}>
												{e.nombre}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="preexistente-establecimiento">
								<Form.Label>Establecimiento</Form.Label>
								<Form.Select
									size='sm'
									value={data.establecimientoId}
									onChange={(e) => handleChange(e.target.value, 'establecimientoId')}
								>
									{config.establecimientos.map((e) => {
										return (
											<option value={e.id} key={`establecimiento-${e.id}`}>
												{e.nombre}
											</option>
										)
									})}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					{tipo === 'rango' ? (
						<Row>
							<CategoriaAnimalesSelects
								opciones={config.categorias}
								initialCategoriaId={'1'}
								seleccionados={data}
								setSeleccionados={setData}
								onSave={onSaveSelectCategoria}
							/>
						</Row>
					): null}
					<Row>
						<Col md="2">
							<Form.Group controlId="preexistente-cantidad" className="mb-3">
								<Form.Label className="mb-1">Cantidad</Form.Label>
								<Form.Control
									size="sm"
									value={data.cantidad}
									onChange={(e) => handleChange(e.target.value, 'cantidad')}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card.Subtitle className="mb-2">
								Detalles adicionales <InfoIcon text="Dependiendo de la categoría del animal, se puede definir Información adicional." />
							</Card.Subtitle>
							<EstadosExtra reset={resetDatosExtra} categoriaId={data.categoriaId} onSave={onSaveEstadosExtra} />
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Tipo de Carga</Card.Title>
					<Row>
						<Col md="6">
							<Form.Check
								inline
								label="Rango"
								name="preexistente-tipo"
								type="radio"
								id="check-preexistente-tipo-rango"
								value="tipo-rango"
								checked={tipo === 'rango'}
								onChange={() =>
									setTipo('rango')
								}
							/>
							<Form.Check
								inline
								label="Individual"
								name="preexistente-tipo"
								type="radio"
								id="check-preexistente-tipo-individual"
								value="tipo-individual"
								checked={tipo === 'individual'}
								onChange={() =>
									setTipo('individual')
								}
							/>
						</Col>
					</Row>
					<hr />
					{tipo === 'rango' ? (
						<IngresoRango saveDisabled={saveDisabled} cantidadAnimales={data.cantidad} onClose={onClose} onSave={onSave} />
					) : (
						<IngresoIndividual saveDisabled={saveDisabled} cantidadAnimales={data.cantidad} onClose={onClose} onSave={onSave} categoriasDisponibles={config.categorias} />
					)}
				</Card.Body>
			</Card>
		</Container >
	)
}