import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Alert } from 'react-bootstrap'
import DefinirAnimales from './DefinirAnimales'
import DefinirCambiosCategorias from './DefinirCambiosCategorias'
import { ScrollTop } from 'components/ScrollTop'
import { REGLAS_CAMBIO_CATEGORIA } from 'config/cambiosCategorias'
import { toast } from 'react-toastify'


export default function CambioCategoria(props) {
	const { config, onClose, onGuardar, containerRef } = props

	const [seleccionados, setSeleccionados] = useState([])

	const [mostrarCambiosCategorias, setMostrarCambiosCategorias] = useState(false)
	/*
	 * Array de elementos con los siguientes campos:
	 * id: animalId,
	 * fecha,
	 * categoriaId
	 */
	const [cambiosCategorias, setCambiosCategorias] = useState([])

	const datosInicialesAnimal = {
		fecha: '',
		categoriaId: ''
	}

	const verSiCompleto = () => {
		let completo = true

		cambiosCategorias.forEach((cambio, i) => {
			if (!verSiCompletadoDatosCambioCategoria(cambio)) {
				completo = false
			}
		})

		return completo
	}

	const verSiCompletadoDatosCambioCategoria = (cambio) => {
		let completado = false
		if (cambio.fecha !== '' && cambio.categoriaId !== '') {
			completado = true
		}

		return completado
	}

	const completo = mostrarCambiosCategorias && verSiCompleto()

	const handleDefinirCambiosCategorias = (animales) => {
		let animalesPermitidos = true
		const animalesConCategoria = animales.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const key = `${a.categoriaId},${a.sexo}`;
			if (!REGLAS_CAMBIO_CATEGORIA[key]) {
				animalesPermitidos = false;
			}

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})

		if (!animalesPermitidos) {
			toast.warning('Favor seleccionar solamente animales cuya categoría se puede cambiar')
			return
		}

		setSeleccionados(animalesConCategoria)

		setCambiosCategorias(animalesConCategoria.map(animal => {
			const categoriasDisponibles = config.categorias.filter(opcion => REGLAS_CAMBIO_CATEGORIA[`${animal.categoriaId},${animal.sexo}`].includes(opcion.id))

			return {
				...datosInicialesAnimal,
				id: animal.id,
				categoriaId: categoriasDisponibles[0]?.id || ''
			}
		}))

		setMostrarCambiosCategorias(true)
	}

	const changeCambioCategoriaData = (valor, cual, indice) => {
		const cambiosCopy = [...cambiosCategorias]
		cambiosCopy[indice][cual] = valor

		setCambiosCategorias(cambiosCopy)
	}

	const handleDefinirCambiosCategoriasAtras = () => {
		setMostrarCambiosCategorias(false)
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'cambio-categoria',
			sinPlanificacion: true,
			listas: [
				{
					nombre: 'animales',
					tipo: 'cambio-categoria',
					cantidadAnimales: cambiosCategorias.length,
					animalesIds: cambiosCategorias.map(cambio => cambio.id),
					marcarProcesados: cambiosCategorias
				}
			]
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="card-bg-gray">
				<Card.Header as='h5'>Evento de Cambio de categoría</Card.Header>
			</Card>
			{!mostrarCambiosCategorias && (
				<Row>
					<Col>
						<Alert variant="info">Primero seleccionar los animales para cambio de categoría, luego proceder a la carga del evento de cambio de cada uno. Solo pueden incluirse animales cuya categoría puede ser cambiada.</Alert>
					</Col>
				</Row>
			)}
			<Card className="mt-3">
				<Card.Body>
					{mostrarCambiosCategorias ? (
						<DefinirCambiosCategorias
							animales={seleccionados}
							datos={cambiosCategorias}
							changeData={changeCambioCategoriaData}
							verSiCompletado={verSiCompletadoDatosCambioCategoria}
							onAtras={handleDefinirCambiosCategoriasAtras}
							opcionesCategorias={config.categorias}
						/>
					) : (
						<DefinirAnimales
							config={config}
							animalesSeleccionados={seleccionados}
							onGuardar={handleDefinirCambiosCategorias}
						/>
					)}
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={!completo}
							>
								Guardar
							</Button>
						</Col>
					</Row>
					<ScrollTop containerRef={containerRef} showFrom={1000} top={210} />
				</Card.Body>
			</Card>

		</Container>
	)
}