import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import './FichaAnimal.css'
import { calculateAge, calculateAgeFromCarimbo } from 'utils/calcularEdad'
import { FichaDocumentosTabContent, FichaMovimientosTabContent, FichaParicionesTabContent, FichaPesajesTabContent, FichaSanitacionesTabContent } from './'

export default function FichaAnimal(props) {
	const { animal, onSave, onCancel, propietarios, establecimientos } = props

	const [ficha, setFicha] = useState({
		numCaravana: animal.numCaravana ?? '-',
		numChip: animal.numChip ?? '-',
		numTatuaje: animal.numTatuaje ?? '-',
		fechaNacimiento: animal.fechaNacimiento ?? '-',
		carimbo: animal.carimbo,
		raza: animal.raza,
		color: animal.color,
		sexo: animal.sexo,
		lote: animal.lote || '-'
	})

	const [propietario, setPropietario] = useState(propietarios.filter(propietario => propietario.id === animal.propietarioId)[0])
	const [establecimiento, setEstablecimiento] = useState(establecimientos.filter(establecimiento => establecimiento.id === animal.establecimientoId)[0])
	const [tab, setTab] = useState('historial-pesajes');

	const edad = animal.fechaNacimiento ? calculateAge(animal.fechaNacimiento) : calculateAgeFromCarimbo(ficha.carimbo)

	const isValid = () => {
		let valid = false

		// validación

		if (!valid) {
			toast.warn(`Completar todos los campos antes de guardar.`)
		}

		return valid
	}

	const handleClose = () => {
		onCancel()
	}

	const handleChange = (valor, cual) => {
		setFicha(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleChangeSelectPropietario = (propietarioId) => {
		const propietario = propietarios.filter(propietario => propietario.id === parseInt(propietarioId))[0]
		setPropietario(prev => ({ ...propietario }))
	}

	const handleChangeSelectEstablecimiento = (establecimientoId) => {
		const establecimiento = establecimientos.filter(establecimiento => establecimiento.id === parseInt(establecimientoId))[0]
		setEstablecimiento(prev => ({ ...establecimiento }))
	}

	const handleSave = () => {
		if (isValid()) {
			const datos = {}

			onSave(datos)
		}
	}

	return (
		<div className="modal-body-wrapper">
			<div className="ficha-form-wrapper">
				<Form>
					<fieldset disabled>
						<legend>Identificación</legend>
						<Row>
							<Col>
								<Form.Group id="numCaravana">
									<Form.Label className="mb-1">Núm. Caravana</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.numCaravana}
										onChange={(e) => handleChange(e.target.value, 'numCaravana')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="numTatuaje">
									<Form.Label className="mb-1">Núm. Tatuaje</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.numTatuaje}
										onChange={(e) => handleChange(e.target.value, 'numTatuaje')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="numChip">
									<Form.Label className="mb-1">Núm. Chip</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.numChip}
										onChange={(e) => handleChange(e.target.value, 'numChip')}
									/>
								</Form.Group>
							</Col>
						</Row>
					</fieldset>
					<fieldset disabled>
						<legend>Datos</legend>
						<Row className="mb-3" >
							<Col>
								<Form.Group id="fechaNacimiento">
									<Form.Label className="mb-1">Fecha Nacimiento</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.fechaNacimiento}
										onChange={(e) => handleChange(e.target.value, 'fechaNacimiento')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="">
									<Form.Label className="mb-1">Carimbo</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.carimbo}
										onChange={(e) => handleChange(e.target.value, 'carimbo')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="edad">
									<Form.Label className="mb-1">Edad</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={edad}
										disabled
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-3" >
							<Col>
								<Form.Group id="raza">
									<Form.Label className="mb-1">Raza</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.raza}
										onChange={(e) => handleChange(e.target.value, 'raza')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="color">
									<Form.Label className="mb-1">Color</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.color}
										onChange={(e) => handleChange(e.target.value, 'color')}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="sexo">
									<Form.Label className="mb-1">Sexo</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.sexo}
										onChange={(e) => handleChange(e.target.value, 'sexo')}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-3" controlId="propietario">
									<Form.Label className="mb-1">Propietario</Form.Label>
									<Form.Select
										key={propietario.id}
										size='sm'
										value={propietario.id}
										onChange={(e) => handleChangeSelectPropietario(e.target.value)}
									>
										{propietarios.map(propietario => {
											return (
												<option key={propietario.id} value={propietario.id}>{propietario.nombre} {propietario.apellido}</option>
											)
										})}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-3" controlId="establecimiento">
									<Form.Label className="mb-1">Establecimiento</Form.Label>
									<Form.Select
										size='sm'
										value={establecimiento.id}
										onChange={(e) => handleChangeSelectEstablecimiento(e.target.value)}
									>
										{establecimientos.map(establecimiento => {
											return (
												<option key={establecimiento.id} value={establecimiento.id}>{establecimiento.nombre}</option>
											)
										})}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group id="lote">
									<Form.Label className="mb-1">Lote</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={ficha.lote}
										onChange={(e) => handleChange(e.target.value, 'lote')}
									/>
								</Form.Group>
							</Col>
						</Row>
					</fieldset>
				</Form>
			</div>
			<div className="ficha-nav-wrapper mt-3">
				<Tabs
					id="ficha-nav"
					activeKey={tab}
					onSelect={(t) => setTab(t)}
					className="mb-3"
				>
					<Tab eventKey="historial-pesajes" title="Historial pesajes">
						<FichaPesajesTabContent pesoInicial={animal.pesoInicial} pesajes={animal.pesajes} />
					</Tab>
					<Tab eventKey="historial-sanitaciones" title="Sanitaciones">
						<FichaSanitacionesTabContent sanitaciones={animal.sanitaciones} />
					</Tab>
					<Tab eventKey="historial-pariciones" title="Historial pariciones">
						<FichaParicionesTabContent pariciones={animal.pariciones} />
					</Tab>
					<Tab eventKey="historial-movimientos" title="Movimientos">
						<FichaMovimientosTabContent movimientos={animal.movimientos} />
					</Tab>
					<Tab eventKey="documentos" title="Documentos">
						<FichaDocumentosTabContent documentos={animal.documentos} />
					</Tab>
				</Tabs>

			</div>
		</div>
	)
}