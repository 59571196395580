import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import BaseDataMostrar from '../BaseDataMostrar'
import { getTextFromBoolean, roundToTwo } from 'utils/general'
import { getAbbrDisplayDate, getShortDisplayDate } from 'utils/date'

export default function DiagnosticoPrenezVer(props) {
	const { config, tarea } = props

	const numProcesados = tarea.listas[0].cantidadAnimalesYaProcesados
	const numPrenadas = tarea.listas[0].animales.filter(animal => animal.historialDiagnosticosPreneces.find(diagnostico => diagnostico.tareaId === tarea.id)?.confirmado).length
	const porcentajePrenadas = numProcesados ? roundToTwo(numPrenadas / numProcesados) : 0

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			{tarea.comentarioEncargado && <Alert variant="info" className="mt-3">{tarea.comentarioEncargado}</Alert>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total procesados</th>
										<td>{`${numProcesados} / ${tarea.listas[0].cantidadAnimales}`}</td>
									</tr>
									<tr>
										<th>Cantidad preñadas</th>
										<td>{numPrenadas}</td>
									</tr>
									<tr>
										<th>Porcentaje preñez</th>
										<td>{porcentajePrenadas}%</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col>
							<Card.Title>Cantidad de animales: <strong>{tarea.listas[0].cantidadAnimales}</strong></Card.Title>
							<Card.Subtitle>Cantidad de procesados: <strong>{tarea.listas[0].cantidadAnimalesYaProcesados}</strong></Card.Subtitle>
							<Table>
								<thead>
									<tr>
										<th>#</th>
										<th>Procesado</th>
										<th>Núm. chip</th>
										<th>Núm. caravana</th>
										<th>Carimbo</th>
										<th>Raza</th>
										<th className="text-center">Preñada</th>
										<th>Tipo</th>
										<th>Profesional</th>
										<th>Servicio asociado</th>
										<th>Fecha probable de parición</th>
									</tr>
								</thead>
								<tbody>
									{tarea.listas[0].animales.map((animal, i) => {
										const diagnostico = animal.historialDiagnosticosPreneces.find(diagnostico => diagnostico.tareaId === tarea.id)
										let textoFechaParicion = '-'
										if (diagnostico?.fechaParicionDesde && diagnostico?.fechaParicionHasta) {
											textoFechaParicion = `${getAbbrDisplayDate(diagnostico?.fechaParicionDesde.substr(0, 10))} a ${getAbbrDisplayDate(diagnostico?.fechaParicionHasta.substr(0, 10))}`
										}

										return (
											<tr key={animal.id}>
												<td>{i + 1}</td>
												<td className="text-center">{animal.procesado ? <i className="fa-solid fa-circle-check fa text-success"></i> : '-'}</td>
												<td>{animal['numChip'] || '-'}</td>
												<td>{animal['numCaravana'] || '-'}</td>
												<td>{animal.carimbo}</td>
												<td>{animal.raza}</td>
												<td className="text-center">{getTextFromBoolean(diagnostico?.confirmado)}</td>
												<td>{diagnostico?.tipo}</td>
												<td>{diagnostico?.profesional || '-'}</td>
												<td>{diagnostico?.servicio?.tipo || '-'}</td>
												<td>{textoFechaParicion}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container >
	)
}