import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { toast } from 'react-toastify'
import './clima.css'

import axios from 'axios'
import api from 'api/api'
import { getLastNYears } from 'utils/general'
import Acumulado from './Acumulado'
import Registros from './Registros'

export default function Clima() {
	const [configLoaded, setConfigLoaded] = useState(false)
	const [loading, setLoading] = useState(false)
	const [cancelToken, setCancelToken] = useState(null)
	const [establecimientos, setEstablecimientos] = useState([])
	const [tipos, setTipos] = useState([])
	const [clima, setClima] = useState()

	const [filtros, setFiltros] = useState({
		establecimientoId: -1,
		establecimientoParteId: -1,
		anho: new Date().getFullYear()
	})

	const establecimientosOptions = establecimientos.map((e) => ({
		value: e.id,
		label: e.nombre,
	}))

	if (!cancelToken) {
		const source = axios.CancelToken.source()
		setCancelToken(source)
	}

	useEffect(() => {
		if (!configLoaded) {
			getConfig()
		}
	}, [])

	useEffect(() => {
		if (configLoaded) {
			refreshClima(1, cancelToken)
		}
	}, [filtros])

	const getConfig = async () => {
		try {
			setLoading(true)
			const { datos: respuesta, error } = await api.get('u/config-clima', { cancelToken: cancelToken.token })

			if (!error) {
				setTipos(respuesta.tipos)
				setEstablecimientos(respuesta.establecimientos)
				setConfigLoaded(true)
				setFiltros((prev) => ({
					...prev,
					establecimientoId: respuesta.establecimientos[0].id
				}))
			}
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const refreshClima = async () => {
		try {
			setLoading(true)
			const enviar = { ... filtros }
			if (filtros.establecimientoParteId === -1) {
				delete enviar.establecimientoParteId
			}
			const { datos: respuesta, error } = await api.post('u/ver-clima', { filtros: enviar }, { cancelToken: cancelToken.token })

			if (!error) {
				setClima(respuesta)
			}
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const crearRegistro = async (data) => {
		const enviar = { ...data }

		try {
			const { msg } = await api.post('u/clima', enviar, { cancelToken: cancelToken.token })
			refreshClima()
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const editarRegistro = async (data) => {
		const enviar = { ...data }

		try {
			const { msg } = await api.put('u/clima/' + data.id, enviar, enviar, { cancelToken: cancelToken.token })
			refreshClima()
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const eliminarRegistro = async (id) => {
		const { msg } = await api.delete('u/clima/' + id)
		refreshClima()
		toast.success(msg)
	}

	const handleChangeFiltros = (valor, cual) => {
		setFiltros(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	// render
	return (
		<Container fluid id="clima" className="vh-100">
			<Row>
				<Col className="mt-2">
					<h1>Clima</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Row className="flex-row vw-100">
							<Col md={4} lg={3}>
								<Form.Group className="mb-3" controlId="filtro-establecimiento">
									<Form.Label>Establecimiento</Form.Label>
									<Form.Select onChange={(e) => handleChangeFiltros(e.target.value, 'establecimientoId')}>
										{establecimientos.map((e) => (
											<option key={`establecimiento-${e.id}`} value={e.id}>
												{e.nombre}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col md={4} lg={3}>
								<Form.Group className="mb-3" controlId="filtro-sector">
									<Form.Label>Sector</Form.Label>
									<Form.Select onChange={(e) => handleChangeFiltros(e.target.value, 'establecimientoParteId')}>
										{(filtros.establecimientoId !== -1) ? (
											establecimientos.filter((e) => e.id === parseInt(filtros.establecimientoId))[0].partes.map(
												(p) => (
													<option key={`establecimientoParte-${p.id}`} value={p.id}>
														{p.nombre}
													</option>
												)
											)) : (
											<option value={null}>-</option>
										)}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col md={4} lg={3}>
								<Form.Group className="mb-3" controlId="filtro-anho">
									<Form.Label>Año</Form.Label>
									<Form.Select onChange={(e) => handleChangeFiltros(e.target.value, 'anho')}>
										{getLastNYears(10).map((year) => <option key={`anho-${year}`} value={year}>{year}</option>)}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<Row>
				<Col>
					<Acumulado clima={clima} />
				</Col>
			</Row>
			<Row className="mt-3">
				<Col>
					{clima && establecimientos.length && (
						<Registros
							registros={clima.registros}
							anho={filtros.anho}
							establecimientos={establecimientos}
							establecimientoActId={filtros.establecimientoId}
							establecimientoParteActId={filtros.establecimientoParteId}
							crear={crearRegistro}
							editar={editarRegistro}
							eliminar={eliminarRegistro}
						/>
					)}
				</Col>
			</Row>
		</Container>
	)
}
