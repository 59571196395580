import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { TIPOS_TAREAS, TIPOS_EVENTOS } from 'config/tareas'
import { PesajeProcesar } from './Pesaje'
import FinalizarTareaModal from './FinalizarTareaModal'
import CerrarConCambiosModal from './CerrarConCambiosModal'
import { SalidaProcesar } from './Salida'
import { ServicioProcesar } from './Servicio'
import DiagnosticoPrenezProcesar from './DiagnosticoPrenez/DiagnosticoPrenezProcesar'
import { AdquisicionProcesar } from './Adquisicion'
import IdentificacionProcesar from './Identificacion/IdentificacionProcesar'
import { SanitacionProcesar } from './Sanitacion'


export default function MostrarAccionProcesar(props) {
	const { config, tareaId, tipo, onClose, onGuardar, onFinalizarTarea } = props
	const [cancelToken, setCancelToken] = useState(null)
	const [tarea, setTarea] = useState(null)
	const [mostrarFinalizarTarea, setMostrarFinalizarTarea] = useState(null)
	const [mostrarCerrarConCambios, setMostrarCerrarConCambios] = useState({
		mostrar: false,
		datos: null
	})

	const handleClose = (sinControlar = false) => {
		if (!sinControlar && mostrarCerrarConCambios.datos) {
			verCerrarConCambios()
		} else {
			onClose()
		}
	}

	const handleProcesar = (data) => {
		onGuardar(data)
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		fetchTarea(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	const fetchTarea = async (cancelToken) => {
		try {
			const { datos: respuesta } = await api.get('u/tareas/' + tareaId, {
				cancelToken: cancelToken.token,
			})

			setTarea(respuesta)
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Petición cancelada', error.message)
			} else {
				toast.error('Error de conexión')
			}
		}
	}

	const verFinalizarTarea = (tarea) => {
		setMostrarFinalizarTarea(tarea)
	}

	const ocultarFinalizarTarea = () => {
		setMostrarFinalizarTarea(null)
	}

	const confirmarFinalizarTarea = (id, comentarioEncargado) => {
		onFinalizarTarea(id, comentarioEncargado)
		ocultarFinalizarTarea()
		onClose()
	}

	/* Lo relacionado a cerrar con cambios */
	const setCambiosData = (datos) => {
		setMostrarCerrarConCambios({ ...mostrarCerrarConCambios, datos })
	}

	const verCerrarConCambios = () => {
		setMostrarCerrarConCambios({ ...mostrarCerrarConCambios, mostrar: true })
	}

	const ocultarCerrarConCambios = () => {
		setMostrarCerrarConCambios({ ...mostrarCerrarConCambios, mostrar: false })
	}

	const confirmarCerrarConCambiosTarea = () => {
		ocultarCerrarConCambios()
		onClose()
	}

	let contenido = ''
	switch (tarea?.tipo) {
		case 'pesaje':
			contenido = <PesajeProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		case 'salida':
			contenido = <SalidaProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				//verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		case 'servicio':
			contenido = <ServicioProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		case 'diagnostico-prenez':
			contenido = <DiagnosticoPrenezProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		case 'adquisicion':
			contenido = <AdquisicionProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		case 'identificacion':
			contenido = <IdentificacionProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		case 'sanitacion':
			contenido = <SanitacionProcesar
				config={config}
				tarea={tarea}
				onClose={handleClose}
				onProcesar={handleProcesar}
				verFinalizarTarea={verFinalizarTarea}
				setCambiosData={setCambiosData}
			/>
			break
		default:
			contenido = <LoadingSpinner />
	}


	return (
		<>
			<Modal show={true} fullscreen onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						<Button
							className="rounded-pill me-2"
							variant="secondary"
							size="sm"
							onClick={() => handleClose()}
						>
							<i className={`fa me-1 fa-arrow-left`}></i>
							Atrás
						</Button>
						Procesar {tipo}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bg-light rounded">
					{contenido}
				</Modal.Body>
				{/*
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={handleSave}>
						Guardar
					</Button>
				</Modal.Footer>
				*/}
			</Modal>
			{mostrarFinalizarTarea ? (
				<FinalizarTareaModal tarea={mostrarFinalizarTarea} onCancel={ocultarFinalizarTarea} onConfirm={confirmarFinalizarTarea} />
			) : null}
			{mostrarCerrarConCambios.mostrar ? (
				<CerrarConCambiosModal datos={mostrarCerrarConCambios.datos} onCancel={ocultarCerrarConCambios} onConfirm={confirmarCerrarConCambiosTarea} />
			) : null}
		</>
	)
}