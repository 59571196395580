import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { LoadingSpinner } from 'components/LoadingSpinner'
import ListaTareas from './ListaTareas'


export default function TareasPendientesTabContent(props) {
	const { active, loading, filtros, handleProcesar, handleEditar, handleEliminar, handleVer } = props

	// Las reglas a considerar para no permitir editar una tarea
	const reglasNoEditable = [
		{
			tipo: 'identificacion',
			condicion: (tarea) => tarea.tareaPapaId
		}
	]

	return (
		<div className="tab-content-wrapper">
			{loading ? (
				<Row className="mt-2">
					<Col>
						<LoadingSpinner />
					</Col>
				</Row>
			) : (
				<ListaTareas
					active={active}
					filtros={filtros}
					handleVer={handleVer}
					handleEditar={handleEditar}
					handleProcesar={handleProcesar}
					handleEliminar={handleEliminar}
					reglasNoEditable={reglasNoEditable}
				/>
			)}
		</div >
	)
}