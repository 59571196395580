import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { TIPOS_TAREAS, TIPOS_EVENTOS } from 'config/tareas'
import { Pesaje } from './Pesaje'
import { Salida } from './Salida'
import { Servicio } from './Servicio'
import { DiagnosticoPrenez } from './DiagnosticoPrenez'
import { Adquisicion } from './Adquisicion'
import { Identificacion } from './Identificacion'
import { Sanitacion } from './Sanitacion'

export default function MostrarAccionEditar(props) {
	const { config, tareaId, tipo, onClose, onGuardar } = props
	const [cancelToken, setCancelToken] = useState(null)
	const [tarea, setTarea] = useState(null)

	const handleClose = () => {
		onClose()
	}

	const handleEditar = (data) => {
		onGuardar(data)
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		fetchTarea(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	const fetchTarea = async (cancelToken) => {
		try {
			const { datos: respuesta } = await api.get('u/tareas/' + tareaId, {
				cancelToken: cancelToken.token,
			})

			setTarea(respuesta)
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Petición cancelada', error.message)
			} else {
				toast.error('Error de conexión')
			}
		}
	}

	let contenido = ''
	switch (tarea?.tipo) {
		case 'pesaje':
			contenido = <Pesaje config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		case 'salida':
			contenido = <Salida config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		case 'servicio':
			contenido = <Servicio config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		case 'diagnostico-prenez':
			contenido = <DiagnosticoPrenez config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		case 'adquisicion':
			contenido = <Adquisicion config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		case 'identificacion':
			contenido = <Identificacion config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		case 'sanitacion':
			contenido = <Sanitacion config={config} tarea={tarea} onClose={handleClose} onGuardar={handleEditar} />
			break
		default:
			contenido = <LoadingSpinner />
	}


	return (
		<Modal show={true} fullscreen onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					<Button
						className="rounded-pill me-2"
						variant="secondary"
						size="sm"
						onClick={handleClose}
					>
						<i className={`fa me-1 fa-arrow-left`}></i>
						Atrás
					</Button>
					Editar {tipo}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="bg-light rounded">
				{contenido}
			</Modal.Body>
		</Modal>
	)
}