import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import { ModalSeleccionAnimales } from 'components/SeleccionAnimales'
import { isEmpty } from 'utils/isEmpty'


export default function SalidaProcesar(props) {
	const { config, tarea, onClose, onProcesar, verFinalizarTarea, setCambiosData } = props

	const [showReemplazar, setShowReemplazar] = useState(false)
	const [data, setData] = useState({
		comentarioEncargado: '',
		listas: tarea.listas.map(l => {
			const copy = {
				...l,
				categoriaId: l.categoria.id,
				animales: [...l.animales]
			}

			return copy
		})
	})
	const [seleccionPopupData, setSeleccionPopupData] = useState({
		mostrar: false, // si mostrar o no el popup
		lista: null, // la lista activa
		index: -1 // el índice de la lista, utilizado para guardar eventuales cambios
	})

	const seleccionCategoria = config.categorias.find(categoria => Number(categoria.id) === Number(seleccionPopupData.lista?.categoriaId))

	const handleChange = (valor, cual) => {
		const nuevosDatos = {
			...data,
			[cual]: valor
		}

		setData(prev => nuevosDatos)
	}

	const handleSave = () => {
		const serverData = {
			id: tarea.id,
			salida: {
				estado: 'procesado'
			},
			comentarioEncargado: data.comentarioEncargado || null,
		}

		if (showReemplazar) {
			serverData.listas = []

			data.listas.forEach(lista => {
				const agregar = {}
				if (lista.agregadosIds?.length) {
					agregar.agregarAnimalesIdsAlProcesar = lista.agregadosIds
				}

				if (lista.eliminadosIds?.length) {
					agregar.eliminarAnimalesIdsAlProcesar = lista.eliminadosIds
				}

				if (!isEmpty(agregar)) {
					serverData.listas.push({
						id: lista.id,
						...agregar
					})
				}
			})
			
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleClose = () => {
		onClose()
	}

	/* Inicio manejo de popup de selección de animales */
	const handleShowSeleccionAnimales = (lista, i) => {
		const popupData = {
			mostrar: true,
			lista: { ...lista },
			index: i
		}

		setSeleccionPopupData(popupData)
	}

	const handleClosePopup = () => {
		setSeleccionPopupData({
			mostrar: false,
			lista: null,
			index: -1
		})
	}

	const confirmarSeleccion = (seleccionados) => {
		const lista = {
			...seleccionPopupData.lista,
			agregadosIds: seleccionados.agregadosIds,
			eliminadosIds: seleccionados.eliminadosIds
		}

		lista.animales = seleccionados.todos.map(a => {
			const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(a.categoriaId))

			const animal = { ...a }
			animal.categoria = categoria

			return animal
		})

		const listas = [...data.listas]
		listas[seleccionPopupData.index] = lista

		setData(prev => ({ ...prev, listas }))

		handleClosePopup()
	}
	/* Fin manejo de popup de selección de animales */

	return (
		<Container>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="ms-2">
							<h5>{tarea.nombre}</h5>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="d-grid align-items-center justify-content-center pt-5 text-center">
							<h4>¿Salida como prevista?</h4>
							<div>
								<Form.Check
									id="check-sinCambios"
									type='radio'
									inline
									label='Sí'
									checked={!showReemplazar}
									onChange={e => setShowReemplazar(false)}
									className="me-4"
								/>
								<Form.Check
									id="check-conCambios"
									type='radio'
									inline
									label="No, reemplazar algunos animales"
									checked={showReemplazar}
									onChange={e => setShowReemplazar(true)}
								/>
							</div>
						</Col>
					</Row>
					<Row className="mt-2 mb-2">
						<Form.Group controlId="txtSalidaComentarioEncargado">
							<Form.Label className="mb-1">Observaciones</Form.Label>
							<Form.Control as="textarea" rows={3}
								value={data.comentarioEncargado}
								onChange={(e) => handleChange(e.target.value, 'comentarioEncargado')}
							/>
						</Form.Group>
					</Row>
					{showReemplazar ? (
						<Row className="mt-3">
							<Col>
								<h4 className="mb-0 mt-3">Listas de animales</h4>
								<Table id="salida-procesar-animales-table" className="table-hover pt-0">
									<thead>
										<tr>
											<th>Cantidad</th>
											<th>Categoría</th>
											<th>Sexo</th>
											<th>Seleccionados</th>
											<th>Acciones</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{data.listas.map((lista, i) => {
											const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(lista.categoria.id))
											const cantidad = Number(lista.cantidad)

											let completaIcon = ''
											if (cantidad) {
												if (cantidad === lista.animales.length) {
													completaIcon = <i className="fas fa-check-circle text-success" title="Lista completa" />
												} else if (cantidad < lista.animales.length) {
													completaIcon = <i className="fas fa-triangle-exclamation text-warning" title="Hay más animales seleccionados de los especificados" />
												}
											}

											const numAgregados = lista.agregadosIds?.length || 0
											const numEliminados = lista.eliminadosIds?.length || 0

											let modificadosText = ''
											if (numAgregados) {
												modificadosText += numAgregados + (numAgregados > 1 ? ' agregados' : ' agregado')
											}
											if (numAgregados && numEliminados) {
												modificadosText += ' y '
											}
											if (numEliminados) {
												modificadosText += numEliminados + (numEliminados > 1 ? ' eliminados' : ' eliminado')
											}

											let warningJSX = ''
											if (numAgregados !== numEliminados) {
												warningJSX = <i className="fa fa-exclamation-triangle text-warning" title="La cantidad de animales seleccionados no coincide con los especificados originalmente"></i>
											}

											return (
												<tr key={`lista-${i}`}>
													<td className="salida-lista-cantidad">
														{lista.cantidadAnimales}
													</td>
													<td className="salida-lista-categoria">
														{categoria.nombre}
													</td>
													<td className="salida-lista-sexo">
														{lista.sexo}
													</td>
													<td className="salida-lista-completa">
														<span className="me-1">{lista.animales.length}</span>
														{completaIcon}
													</td>
													<td className="salida-lista-acciones">
														{/*<i className="fas fa-trash-alt salida-lista-eliminar" title="Eliminar lista" onClick={() => handleRemoveLista(i)} />*/}
														<i className="fas fa-rectangle-list salida-lista-elegir ms-2" title="Definir animales" onClick={() => handleShowSeleccionAnimales(lista, i)} />
													</td>
													<td className="text-muted">
														{warningJSX} <small>{modificadosText}</small>
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Col>
						</Row>
					) : null}
				</Card.Body>
				<Card.Footer>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
							>
								Procesar tarea
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
			{seleccionPopupData.mostrar && (
				<ModalSeleccionAnimales
					config={config}
					titulo={`Animales para la lista de ${seleccionCategoria.nombre}${seleccionPopupData.lista.sexo ? ', ' + seleccionPopupData.lista.sexo : ''}`}
					datosIniciales={{
						seleccionados: seleccionPopupData.lista.animales,
						agregadosIds: seleccionPopupData.lista.agregadosIds,
						eliminadosIds: seleccionPopupData.lista.eliminadosIds,
						filtrosIniciales: {
							categoriaId: seleccionPopupData.lista.categoriaId,
							sexo: seleccionPopupData.lista.sexo
						}
					}}
					mostrarAgregados={true}
					mostrarEliminados={true}
					seleccionarExactamente={seleccionPopupData.lista.cantidadAnimales}
					onClose={handleClosePopup}
					onConfirmar={confirmarSeleccion}
				/>
			)}
		</Container>
	)
}