import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Datetime from 'react-datetime'
import { handleChangeFecha } from 'utils/date'

export default function AnimalSeleccionado(props) {
	const { animal, onChangeData, onConfirmar, yaProcesado, accionYaTomada } = props

	const confirmarDisabled = !animal || !animal?.nuevosDatos.datosCompletados

	return (
		yaProcesado ? (
			<Alert variant="warning">Este animal ya fue procesado</Alert>
		) : (
			<>
				{accionYaTomada && animal?.id && <Alert variant="info">Se están editando datos ya cargados del animal.</Alert>}
				{animal?.id ? (
					<Card>
						<Card.Header>Seleccionado</Card.Header>
						<Card.Body>
							<Row className="mb-1">
								<Form.Group className={"col-12 col-sm-4"} controlId="animalSeleccionado-numCaravana">
									<Form.Label className="mb-1">Núm. Caravana</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animal.numCaravana || ''}
										disabled
									/>
								</Form.Group>
								<Form.Group className={"col-12 col-sm-4"} controlId="animalSeleccionado-numChip">
									<Form.Label className="mb-1">Núm. Chip</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animal.numChip || ''}
										disabled
									/>
								</Form.Group>
								<Form.Group className={"col-12 col-sm-4"} controlId="animalSeleccionado-numTatuaje">
									<Form.Label className="mb-1">Núm. Tatuaje</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animal.numTatuaje || ''}
										disabled
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className="col" controlId="animalSeleccionado-fechaProcesado">
									<Form.Label className="mb-1">Fecha procesado</Form.Label>
									<Datetime
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										closeOnSelect={true}
										strictParsing={false}
										inputProps={{ placeholder: "YYYY-MM-DD", id: 'animalSeleccionado-fechaProcesado', className: 'form-control form-control-sm' }}
										value={animal.nuevosDatos.fecha}
										onChange={(date) => handleChangeFecha(date, onChangeData, 'fecha')}
									/>
								</Form.Group>
							</Row>
							{props.children}
							<Row className="mt-3">
								<Col>
									<Button
										onClick={onConfirmar}
										variant="primary"
										style={{ width: '100%' }}
										disabled={confirmarDisabled}
									>
										Confirmar
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				) : (
					<Alert variant="info">No hay animal seleccionado</Alert>
				)}
			</>
		)
	)
}