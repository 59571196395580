import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'
import BaseDataMostrar from '../BaseDataMostrar'
import { convertBytesToKB, getUpladsUrl } from 'utils/general'

export default function SalidaVer(props) {
	const { config, tarea } = props

	const [listaI, setListaI] = useState(null)
	const [tab, setTab] = useState('origen-destino');

	let totalProcesados = 0
	let totalAgregados = 0
	let totalEliminados = 0
	tarea.listas.forEach(lista => {
		totalProcesados += lista.cantidadAnimalesYaProcesados
		lista.animales.forEach(animal => {
			if (animal.agregadoAlProcesar) {
				totalAgregados++
			}

			if (animal.eliminadoAlProcesar) {
				totalEliminados++
			}
		})
	})

	const propietarioOrigen = config.propietarios.find(propietario => propietario.id === tarea.salida.propietarioOrigenId)
	const establecimientoOrigen = config.establecimientos.find(establecimiento => establecimiento.id === tarea.salida.establecimientoOrigenId)

	let propietarioDestinoNombre = tarea.salida.propietarioDestinoNombre
	let propietarioDestinoMasInfo = `${tarea.salida.propietarioDestinoTipoDocumento} - ${tarea.salida.propietarioDestinoDocumento}`
	let establecimientoDestinoNombre = tarea.salida.establecimientoDestinoNombre
	let establecimientoDestinoCodigo = tarea.salida.establecimientoDestinoCodigo
	if (tarea.salida.dentroDeCuenta) {
		const propietarioDestino = config.propietarios.find(propietario => propietario.id === tarea.salida.propietarioDestinoId)
		const establecimientoDestino = config.establecimientos.find(establecimiento => establecimiento.id === tarea.salida.establecimientoDestinoId)

		propietarioDestinoNombre = `${propietarioDestino.nombre} ${propietarioDestino.apellido}`
		propietarioDestinoMasInfo = `ci - ${propietarioDestino.codigo}`
		establecimientoDestinoNombre = establecimientoDestino.nombre
		establecimientoDestinoCodigo = establecimientoDestino.codigo
	}

	const documentacion = [
		{
			texto: 'Guía',
			key: 'guia',
			archivo: tarea.salida['guia']
		},
		{
			texto: 'Cota',
			key: 'cota',
			archivo: tarea.salida['cota']
		},
		{
			texto: 'Remisión',
			key: 'remision',
			archivo: tarea.salida['remision']
		},
	]


	const handleSelect = (cual) => {
		if (cual === listaI) {
			setListaI(null)
		} else {
			setListaI(cual)
		}
	}

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			{tarea.comentarioEncargado && <><h5 className="mt-3 mb-1">Comentario encargado</h5><Alert variant="info" className="">{tarea.comentarioEncargado}</Alert></>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total listas</th>
										<td>{tarea.listas.length}</td>
									</tr>
									<tr>
										<th>Total procesados</th>
										<td>{totalProcesados}</td>
									</tr>
									<tr>
										<th>Total agregados al procesar</th>
										<td>{totalAgregados}</td>
									</tr>
									<tr>
										<th>Total eliminados al procesar</th>
										<td>{totalEliminados}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Accordion
				activeKey={listaI}
				onSelect={handleSelect}
			>
				{tarea.listas.map((lista, i) => {
					const seleccionCategoria = config.categorias.find(categoria => Number(categoria.id) === Number(lista.categoria.id))

					let modificada = false
					for (let a of lista.animales) {
						if (a.agregadoAlProcesar || a.eliminadoAlProcesar) {
							modificada = true
							break
						}
					}

					return (
						<Accordion.Item key={`lista-${i}`} eventKey={i}>
							<Accordion.Header className={modificada ? 'salida-lista-modificada' : ''}>
								{`${seleccionCategoria.nombre}${lista.sexo ? ', ' + lista.sexo : ''}`}
								{modificada && <i className="fa-solid fa-shuffle ms-2" title="Modificada al procesar"></i>}
							</Accordion.Header>
							<Accordion.Body>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Núm. chip</th>
											<th>Núm. caravana</th>
											<th>Carimbo</th>
											<th>Peso</th>
											<th>Raza</th>
											<th>Sexo</th>
											{modificada && <th className="text-center"><i className="fa-solid fa-shuffle" title="Modificada"></i></th>}
										</tr>
									</thead>
									<tbody>
										{/* This is where we map over the items of each list and render a table row for each item */}
										{lista.animales.map((animal, j) => (
											<tr key={`lista-${i}-animal${j}`}>
												<td>{j + 1}</td>
												<td>{animal['numChip'] || '-'}</td>
												<td>{animal['numCaravana'] || '-'}</td>
												<td>{animal.carimbo}</td>
												<td>{animal.peso || '-'}</td>
												<td>{animal.raza}</td>
												<td>{animal.sexo}</td>
												{modificada && (
													<td className="text-center">
														{animal.agregadoAlProcesar ? <i className="fa-solid fa-arrow-right text-success" title="Agregado al procesar"></i> : ''}
														{animal.eliminadoAlProcesar ? <i className="fa-solid fa-arrow-left text-danger" title="Eliminado al procesar"></i> : ''}
													</td>
												)}
											</tr>
										))}
									</tbody>
								</Table>
							</Accordion.Body>
						</Accordion.Item>
					)
				})}
			</Accordion>
			<Row>
				<Col>
					<Tabs
						activeKey={tab}
						onSelect={(t) => setTab(t)}
						className="mb-3"
					>
						<Tab eventKey="origen-destino" title="Origen y destino">
							<Row>
								<Col>
									<h4 className="mb-1">Origen</h4>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body>
											<Card.Title>Propietario</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{`${propietarioOrigen.nombre} ${propietarioOrigen.apellido}`}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>{propietarioOrigen.codigo}</div>
										</Card.Body>
									</Card>
								</Col>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body  >
											<Card.Title>Establecimiento</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{`${establecimientoOrigen.nombre}`}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>{establecimientoOrigen.codigo}</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<h4 className="mb-1">Destino {tarea.salida.dentroDeCuenta ? <small className="text-muted">(dentro de cuenta)</small> : ''}</h4>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body>
											<Card.Title>Propietario</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{propietarioDestinoNombre}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>
												{propietarioDestinoMasInfo}
											</div>
											{tarea.salida.dentroDeCuenta ? null : (
												<div style={{ color: '#444' }}>
													{`Nacionalidad ${tarea.salida.propietarioDestinoNacionalidad}`}
												</div>
											)}
										</Card.Body>
									</Card>
								</Col>
								<Col xs="12" sm="6" md="4">
									<Card style={{ height: '100%' }}>
										<Card.Body>
											<Card.Title>Establecimiento</Card.Title>
											<div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{establecimientoDestinoNombre}</div>
											<div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#555', marginTop: -5 }}>{establecimientoDestinoCodigo}</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="documentacion" title="Documentación">
							<Row>
								<Col xs="12" sm="6" md="4">
									<Table>
										<tbody>
											<tr>
												<th >Finalidad</th>
												<td>{tarea.salida.finalidadCota}</td>
											</tr>
											<tr>
												<th>Núm. Guía</th>
												<td>{tarea.salida.numeroGuia}</td>
											</tr>
											<tr>
												<th>Núm. Cota</th>
												<td>{tarea.salida.numeroCota}</td>
											</tr>
										</tbody>
									</Table>
								</Col>
							</Row>
							<Row className="gx-2">
								{documentacion.map((documento, i) => {

									let jsx = <div className="sin-archivo">Sin archivo</div>
									if (documento.archivo) {
										const nombre = documento.archivo.url.split('/').pop()

										jsx = <>
											<div className="movimiento-documento-nombre">
												<a href={getUpladsUrl() + documento.archivo.url} target='_blank'>
													<i
														className="fas fa-file ms-2 me-2"
														title={`Descargar archivo de ${documento.texto}`}
													/>{nombre}
												</a>
											</div>
											<div className="movimiento-documento-tamano">
												{convertBytesToKB(documento.archivo.tamano)} kb
											</div>
										</>
									}

									return (
										<Col key={`documento-${i}`} xs={12} sm={6} md={4}>
											<div className="movimiento-documento">
												{jsx}
												<div className="movimiento-documento-titulo">{documento.texto}</div>
											</div>
										</Col>
									)
								})}
							</Row>
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container >
	)
}