import React, { useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import useAnimales from 'hooks/useAnimales'
import { SeleccionAnimales, MostrarListaAnimales } from 'components/SeleccionAnimales'
import { ScrollTop } from 'components/ScrollTop'
import { DEFAULT_COLS, DEFAULT_SELECTED_COLS } from 'config/seleccionAnimales'

export default function ModalSeleccionAnimales(props) {
	const { config, titulo, datosIniciales, onClose, onConfirmar, seleccionarExactamente = 0, mostrarAgregados = false, 
		mostrarEliminados = false, cols = DEFAULT_COLS, selectedCols = DEFAULT_SELECTED_COLS } = props

	const { loading, error, cambiarCategoria, filtros, cambiarFiltro, animales, seleccionados, seleccionarAnimal, agregadosIds, eliminadosIds,
		seleccionarMultiplesAnimales, deseleccionarAnimal, pagina, cambiarPagina, deACuantos, cambiarDeACuantos, numResultados } = useAnimales(datosIniciales)

	const ref = useRef(null)

	const handleClose = () => {
		onClose()
	}

	const handleConfirmar = () => {
		onConfirmar(seleccionados, agregadosIds, eliminadosIds)
	}

	return (
		<Modal
			show={true}
			onHide={() => handleClose()}
			size="xl"
			scrollable
			backdrop={'static'}
		>
			<Modal.Header closeButton>
				<h4><strong>{titulo}</strong></h4>
			</Modal.Header>
			<Modal.Body ref={ref}>
				<Row>
					<Col md={7}>
						<SeleccionAnimales
							config={config}
							loading={loading}
							animales={animales}
							filtros={filtros}
							cambiarFiltro={cambiarFiltro}
							cambiarCategoria={cambiarCategoria}
							seleccionados={seleccionados}
							seleccionarAnimal={seleccionarAnimal}
							seleccionarMultiplesAnimales={seleccionarMultiplesAnimales}
							pagina={pagina}
							cambiarPagina={cambiarPagina}
							deACuantos={deACuantos}
							cambiarDeACuantos={cambiarDeACuantos}
							numResultados={numResultados}
							mostrarEliminados={mostrarEliminados}
							cols={cols}
						/>
					</Col>
					<Col md={5}>
						<MostrarListaAnimales
							seleccionados={seleccionados}
							deseleccionarAnimal={deseleccionarAnimal}
							mostrarAgregados={mostrarAgregados}
							seleccionarExactamente={seleccionarExactamente}
							cols={selectedCols}
						/>
					</Col>
				</Row>
				<ScrollTop containerRef={ref} showFrom={1000} top={20} />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => handleConfirmar()}>Aceptar</Button>
			</Modal.Footer>
		</Modal>
	)
}