export const FINALIDADES_COTA = [
	{
		label: 'Cría',
		value: 'cria'
	},
	{
		label: 'Engorde',
		value: 'engorde'
	},
	{
		label: 'Feria de Invernada',
		value: 'feria-de-invernada',
	},
	{
		label: 'Feria de Reproducción',
		value: 'feria-de-reproduccion'
	},
	{
		label: 'Feria de Consumo',
		value: 'feria-de-consumo'
	},
	{
		label: 'Faena',
		value: 'faena'
	},
	{
		label: 'Exposición',
		value: 'exposicion'
	}
]

export const TIPOS_DOCUMENTOS = [
	{
		label: 'Cédula de identidad',
		value: 'ci'
	},
	{
		label: 'Pasaporte',
		value: 'pasaporte'
	},
	{
		label: 'RUC',
		value: 'ruc'
	}
]

export const TIPOS_SALIDAS = [
	{
		label: 'Traslado',
		value: 'traslado'
	},
	{
		label: 'Venta',
		value: 'venta',
	},
	{
		label: 'Donación',
		value: 'donacion'
	}
]

export const TIPOS_ADQUISICIONES = [
	{
		label: 'Compra',
		value: 'compra',
	},
	{
		label: 'Traslado',
		value: 'traslado'
	},
]

export const CATEGORIAS_MAP = {
	'Vac': 1,
	'Vaq': 2,
	'Nov': 3,
	'Tor': 4,
	'DM': 5,
	'DH': 6,
	'Ter': 7,
	'Bue': 8,
	'Bub': 9
}

export const REVERSE_CATEGORIAS_MAP = {
	1: 'Vac',
	2: 'Vaq',
	3: 'Nov',
	4: 'Tor',
	5: 'DM',
	6: 'DH',
	7: 'Ter',
	8: 'Bue',
	9: 'Bub'
}

export const CATEGORIAS_SEXO_MAP = {
	1: 'hembra',
	2: 'hembra',
	3: 'macho',
	4: 'macho',
	5: 'macho',
	6: 'hembra',
	7: null,
	8: 'macho',
	9: null
}