import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Datetime from "react-datetime"

export default function BaseDataMostrar(props) {
	const { usuarios, data, vertical, numObservacionRows = false } = props

	const opcionesAsignado = [
		{id: null, label: '-'},
		...usuarios.map(usuario => ({
			id: usuario.id,
			label: `${usuario.nombre} ${usuario.apellido}`
		}))
	]

	return (
		<Card className="card-bg-gray">
			<Card.Header as='h5'>Asignación</Card.Header>
			<Card.Body>
				<Row className="tareas-base-inputs flex align-items-center justify-content-between my-2">
					<Col sm={vertical ? "12" : "4"}>
						<Form.Group controlId="txtTareaNombre" className="mb-3">
							<Form.Label className="mb-1">Nombre de la tarea</Form.Label>
							<Form.Control
								size="sm"
								value={data.nombre}
								disabled
							/>
						</Form.Group>
					</Col>
					<Col sm={vertical ? "12" : "4"}>
						<Form.Group controlId="txtTareaFechaParaCuando" className="mb-3">
							<Form.Label className="mb-1">Fecha a realizar</Form.Label>
							<Datetime
								dateFormat="YYYY-MM-DD"
								timeFormat={false}
								closeOnSelect={true}
								strictParsing={false}
								inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtTareaFechaParaCuando', className: 'form-control form-control-sm', disabled: true }}
								value={data.fechaParaCuando}
							/>
						</Form.Group>
					</Col>
					<Col sm={vertical ? "12" : "4"}>
						<Form.Group controlId="selTareaAsignadoId" className="mb-3">
							<Form.Label className="mb-1">Asignado a</Form.Label>
							<Form.Select
								size="sm"
								value={data.asignadoId}
								disabled
							>
								{opcionesAsignado.map((usuario, i) => {
									return (
										<option value={usuario.id} key={`selectAsignado-${usuario.id}`}>
											{usuario.label}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
					</Col>
					<Col sm="12">
						<Form.Group controlId="txtTareaObservacion" className="mb-0">
							<Form.Label>Observaciones</Form.Label>
							<Form.Control as="textarea" rows={numObservacionRows || 3}
								value={data.observacion}
								disabled
							/>
						</Form.Group>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}