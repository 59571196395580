import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import axios from 'axios';
import { toast } from 'react-toastify'
import { debounce } from 'utils/debounce'
import '../configuraciones.css'

import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Lote from './Lote'
import DeleteModal from './DeleteModal'
import { FETCH_DE_A_CUANTOS } from 'config/lotes'
import Paginacion from 'components/Paginacion'
import { ModalSeleccionAnimales } from 'components/SeleccionAnimales'
import { ConfigContext } from 'contexts/config/ConfigContext'
import EditModal from './EditModal'

export default function Lotes() {
	const { data: config, loading: loadingConfig, error: errorConfig } = useContext(ConfigContext)
	const [lotes, setLotes] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [fetchDeACuantos, setFetchDeACuantos] = useState(FETCH_DE_A_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [showNewLote, setShowNewLote] = useState(false)
	const [editingLote, setEditingLote] = useState(null)
	const [manejandoLote, setManejandoLote] = useState(null)
	const [deletingLote, setDeletingLote] = useState(null)
	const [busqueda, setBusqueda] = useState('')
	const [filtros, setFiltros] = useState({
		nombre: ['']
	})

	const selectedAnimalesCols = [
		{
			label: 'Id',
			key: 'id'
		},
		{
			label: '# Car',
			key: 'numCaravana'
		},
		{
			label: 'Categoría',
			key: 'categoria'
		},
		{
			label: 'Peso',
			key: 'peso'
		}
	]

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	useEffect(() => {
		if (cancelToken) {
			refreshLotes(1, cancelToken)
		}
	}, [cancelToken, filtros])

	useEffect(() => {
		if (!loading) {
			refreshLotes(paginaAct, cancelToken)
		}
	}, [paginaAct])

	const refreshLotes = async (pagina) => {
		setLoading(true)
		try {
			const serverData = {
				cuantos: fetchDeACuantos,
				desde: pagina === 1 ? 0 : (pagina - 1) * fetchDeACuantos,
				filtros
			}
			const { datos: respuesta, error } = await api.post('u/ver-lotes', serverData, { cancelToken: cancelToken.token })
			if (!error) {
				setPaginaAct(pagina)
				setLotes(respuesta.lotes)
				setNumResultados(respuesta.numResultados)

			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.log('fetchStock error', error)
			}
		}
		setLoading(false)
	}

	const handleChangeNombre = (nombre) => {
		setFiltros(prev => ({
			...prev,
			nombre: [nombre]
		}))
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleChangeNombre, 400),
		[]
	)

	useEffect(() => {
		if (busqueda !== filtros.nombre[0]) {
			debouceFiltroBusqueda(busqueda)
		}
	}, [busqueda])

	const handleNew = () => {
		setShowNewLote(true)
	}

	const handleCancelNewLote = () => {
		setShowNewLote(false)
	}

	const handleEdit = (lote) => {
		setEditingLote(lote)
	}

	const handleManejar = (lote, index) => {
		setManejandoLote({
			...lote,
			animales: lote.animales.map(animal => ({
				...animal,
				categoria: {
					id: animal.categoriaId,
					nombre: animal.categoria
				},
			})),
			lotesIndex: index
		})
	}

	const handleDelete = (lote) => {
		setDeletingLote(lote)
	}

	const onCancelEdit = () => {
		setEditingLote(null)
	}

	const onCancelDelete = () => {
		setDeletingLote(null)
	}

	const handleClosePopupManejar = () => {
		setManejandoLote(null)
	}

	const confirmarSeleccion = (seleccionados) => {
		const animales = seleccionados.todos

		// Arma las propiedades agregarAnimalesIds y eliminarAnimalesIds, según corresponda
		const animalesExistentesIds = lotes[manejandoLote.lotesIndex].animales.map(animal => animal.id)
		const animalesSeleccionadosIds = animales.map(animal => animal.id)
		const agregarAnimalesIds = animalesSeleccionadosIds.filter(animalId => !animalesExistentesIds.includes(animalId))
		const eliminarAnimalesIds = animalesExistentesIds.filter(animalId => !animalesSeleccionadosIds.includes(animalId))

		
		const lote = {
			...manejandoLote,
			agregarAnimalesIds,
			eliminarAnimalesIds
		}

		onSave(lote)
		handleClosePopupManejar(null)
	}


	const onSave = async (datos) => {
		try {
			setLoading(true)
			if (datos.id) {
				const { datos: respuesta, msg } = await api.put('u/lotes/' + datos.id, datos)
				toast.success(msg)
			} else {
				const { datos: respuesta, msg } = await api.post('u/lotes', datos)
				toast.success(msg)
			}
			refreshLotes(paginaAct, cancelToken)
		} catch (error) {
			toast.error('Error de conexión')
			setError(error)
		} finally {
			setLoading(false)
			onCancelEdit()
			handleClosePopupManejar()
		}
	}

	const onDelete = async (loteId) => {
		try {
			setLoading(true)
			const { msg } = await api.delete('u/lotes/' + loteId)
			refreshLotes(1, cancelToken)
			toast.success(msg)
		} catch (error) {
			toast.error('Error de conexión')
			setError(error)
		} finally {
			setLoading(false)
			onCancelDelete()
		}
	}

	// render
	return (
		<>
			<Container fluid id="lotes" className="vh-100">
				{/* Header */}
				<Row>
					<Col className="mt-2">
						<h1>Lotes</h1>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button onClick={() => handleNew()} variant="primary">
							<i className="fa fa-plus"></i> Nuevo Lote
						</Button>
					</Col>
				</Row>
				<Row>
					<Col className="mt-2">
						<Form>
							<Row id="searchbar" className="justify-content-between align-items-center">
								<Col md={5} lg={4}>
									<Form.Group controlId="txtBusqueda">
										<InputGroup>
											<Form.Control
												autoFocus
												value={busqueda}
												onChange={(e) => setBusqueda(e.target.value)}
												type="text"
												placeholder="Buscar"
												aria-label="Buscar"
											/>
											<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
										</InputGroup>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					{loading ? (
						<Col>
							<LoadingSpinner />
						</Col>
					) : (
						<Col md={8} lg={6}>
							<Table className="mt-2" striped bordered hover>
								<thead>
									<tr className="text-secondary">
										<th>Id</th>
										<th>Nombre</th>
										<th># Animales</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{lotes.map((lote, i) => {
										return (
											<tr key={lote.id}>
												<td>{lote.id}</td>
												<td>{lote.nombre}</td>
												<td>{lote.animales.length}</td>
												<td>
													<Button
														onClick={() => handleManejar(lote, i)}
														className="me-2"
														variant="primary"
														size="sm"
													>
														Manejar
													</Button>
													<Button
														onClick={() => handleEdit(lote)}
														className="me-2"
														variant="secondary"
														size="sm"
													>
														Cambiar nombre
													</Button>
													<Button
														onClick={() => handleDelete(lote)}
														variant="danger"
														size="sm"
													>
														Eliminar
													</Button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						<Paginacion
							pagina={paginaAct}
							numPaginas={Math.ceil(numResultados / fetchDeACuantos) || 1}
							setPagina={setPaginaAct}
						/>
					</Col>
				</Row>
			</Container>
			{showNewLote && !loadingConfig ? (
				<Lote
					config={config}
					onSave={onSave}
					onCancel={handleCancelNewLote}
					cols={selectedAnimalesCols}
				/>
			) : null}
			{manejandoLote && !loadingConfig ? (
				<ModalSeleccionAnimales
					key={manejandoLote?.id}
					config={config}
					titulo={`Animales para lote ${manejandoLote.nombre}`}
					datosIniciales={{
						seleccionados: manejandoLote.animales
					}}
					onClose={handleClosePopupManejar}
					onConfirmar={confirmarSeleccion}
					selectedCols={selectedAnimalesCols}
				/>
			) : null}
			{editingLote ? (
				<EditModal key={editingLote?.id || 0} loteOriginal={editingLote} onSave={onSave} onCancel={onCancelEdit} />
			) : null}
			{deletingLote ? (
				<DeleteModal lote={deletingLote} onDelete={onDelete} onCancel={onCancelDelete} />
			) : null}
		</>
	)
}
